import {
  Select,
  Space,
  Row,
  Col,
  Input,
  Switch,
  Divider,
  Form,
  Modal,
  Button,
} from "antd";
import { t } from "i18next";
import PropTypes from "prop-types";

const propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onChangeAggregationLevel: PropTypes.func,
  selectedAggregationLevel: PropTypes.string,
  onChangeBucketType: PropTypes.func,
  selectedBucketType: PropTypes.string,
  onChangeDateFormat: PropTypes.func,
  selectedDateFormat: PropTypes.string,
  dateFormatOptions: PropTypes.array,
  selectedIncludePreviousYear: PropTypes.bool,
  onChangeIncludePreviousYear: PropTypes.func,
  propertyNameField: PropTypes.string,
  setPropertyNameField: PropTypes.func,
  propertyAddressField: PropTypes.string,
  setPropertyAddressField: PropTypes.func,
  floorNameField: PropTypes.string,
  setFloorNameField: PropTypes.func,
  systemNameField: PropTypes.string,
  setSystemNameField: PropTypes.func,
  deviceNameField: PropTypes.string,
  setDeviceNameField: PropTypes.func,
  deviceSerialNumberField: PropTypes.string,
  setDeviceSerialNumberField: PropTypes.func,
  deviceLocationField: PropTypes.string,
  setDeviceLocationField: PropTypes.func,
  deviceTypeField: PropTypes.string,
  setDeviceTypeField: PropTypes.func,
};

const defaultProps = {
  open: false,
  onClose: () => {},
  onChangeAggregationLevel: () => {},
  selectedAggregationLevel: t("property_overview_page.device"),
  onChangeBucketType: () => {},
  selectedBucketType: t("events_filter.year"),
  onChangeDateFormat: () => {},
  selectedDateFormat: "",
  dateFormatOptions: [],
  selectedIncludePreviousYear: false,
  onChangeIncludePreviousYear: () => {},
  propertyNameField: t("filters_modal.property"),
  setPropertyNameField: () => {},
  propertyAddressField: t("property_overview_page.property_address"),
  setPropertyAddressField: () => {},
  floorNameField: t("filters_modal.floor"),
  setFloorNameField: () => {},
  systemNameField: t("filters_modal.system"),
  setSystemNameField: () => {},
  deviceNameField: t("filters_modal.device"),
  setDeviceNameField: () => {},
  deviceSerialNumberField: t("property_overview_page.device_sn"),
  setDeviceSerialNumberField: () => {},
  deviceLocationField: t("property_overview_page.device_location"),
  setDeviceLocationField: () => {},
  deviceTypeField: t("events_filter.device_type"),
  setDeviceTypeField: () => {},
};

function FilterReportDataModal({
  open,
  onClose,
  onChangeAggregationLevel,
  selectedAggregationLevel,
  onChangeBucketType,
  selectedBucketType,
  onChangeDateFormat,
  selectedDateFormat,
  dateFormatOptions,
  selectedIncludePreviousYear,
  onChangeIncludePreviousYear,
  propertyNameField,
  setPropertyNameField,
  propertyAddressField,
  setPropertyAddressField,
  floorNameField,
  setFloorNameField,
  systemNameField,
  setSystemNameField,
  deviceNameField,
  setDeviceNameField,
  deviceSerialNumberField,
  setDeviceSerialNumberField,
  deviceLocationField,
  setDeviceLocationField,
  deviceTypeField,
  setDeviceTypeField,
}) {
  const aggregationLevelOptions = [
    { value: "Device", label: t("filters_modal.device") },
    { value: "System", label: t("filters_modal.system") },
    { value: "Floor", label: t("filters_modal.floor") },
    { value: "Property", label: t("filters_modal.property") },
  ];

  const bucketTypeOptions = [
    { value: "year", label: t("filters_modal.year") },
    { value: "month", label: t("filters_modal.month") },
    { value: "day", label: t("filters_modal.day") },
  ];

  const filterSelect = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <Modal
      className="report-data-modal"
      maskClosable={false}
      open={open}
      footer={null}
      width={680}
      onCancel={onClose}
    >
      <h2 className="uppercase tracking-widest font-light">
        {t("filters_modal.configure_report_data")}
      </h2>
      <div>
        <Divider orientation="left" orientationMargin={0}>
          {t("filters_modal.choose_timeframe")}
        </Divider>
        <Space direction="horizontal">
          <Form.Item
            label={t("filters_modal.aggregation_label")}
            name="aggregationLevel"
            rules={[
              {
                required: true,
                message: t("filters_modal.aggregation_label_required"),
              },
            ]}
          >
            <Select
              dropdownStyle={{ minWidth: "130px" }}
              placeholder={t("filters_modal.aggregation_label")}
              onChange={onChangeAggregationLevel}
              filterOption={filterSelect}
              className="aggregation-level-selector"
              value={selectedAggregationLevel}
              options={aggregationLevelOptions}
            />
          </Form.Item>
          <Form.Item
            label={t("filters_modal.timeframe")}
            name="bucketType"
            rules={[
              {
                required: true,
                message: t("filters_modal.bucket_type_required"),
              },
            ]}
          >
            <Select
              dropdownStyle={{ minWidth: "130px" }}
              placeholder={t("filters_modal.timeframe")}
              onChange={onChangeBucketType}
              filterOption={filterSelect}
              className="bucket-type-selector"
              value={selectedBucketType}
              options={bucketTypeOptions}
            />
          </Form.Item>
          <Form.Item
            label={t("filters_modal.date_format")}
            name="dateFormat"
            rules={[
              {
                required: true,
                message: t("filters_modal.date_format_required"),
              },
            ]}
          >
            <Select
              dropdownStyle={{ minWidth: "28rem" }}
              placeholder={t("filters_modal.date_format")}
              onChange={onChangeDateFormat}
              filterOption={filterSelect}
              className="date-format-selector"
              value={selectedDateFormat}
              options={dateFormatOptions}
            />
          </Form.Item>
        </Space>
        <Space direction="horizontal">
          <Form.Item
            label={t("filters_modal.include_prev_year")}
            name="includePreviousYear"
            valuePropName="checked"
          >
            <Switch
              className="include-previous-year-switch"
              defaultChecked={selectedIncludePreviousYear}
              onChange={onChangeIncludePreviousYear}
            />
          </Form.Item>
        </Space>
        <Divider orientation="left" orientationMargin={0}>
          {t("filters_modal.add_custom_field")}
        </Divider>
        <Row gutter={8} className="mb-2">
          {(selectedAggregationLevel === "Device" ||
            selectedAggregationLevel === "System" ||
            selectedAggregationLevel === "Floor" ||
            selectedAggregationLevel === "Property") && (
            <>
              <Col className="gutter-row" span={6}>
                <Input
                  placeholder={t("filters_modal.property_name")}
                  allowClear
                  value={propertyNameField}
                  onChange={(e) => setPropertyNameField(e.target.value)}
                />
              </Col>
              <Col className="gutter-row" span={6}>
                <Input
                  placeholder={t("filters_modal.property_address")}
                  allowClear
                  value={propertyAddressField}
                  onChange={(e) => setPropertyAddressField(e.target.value)}
                />
              </Col>
            </>
          )}
          {(selectedAggregationLevel === "Device" ||
            selectedAggregationLevel === "System" ||
            selectedAggregationLevel === "Floor") && (
            <Col className="gutter-row" span={6}>
              <Input
                placeholder={t("filters_modal.floor_name")}
                allowClear
                value={floorNameField}
                onChange={(e) => setFloorNameField(e.target.value)}
              />
            </Col>
          )}
          {(selectedAggregationLevel === "Device" ||
            selectedAggregationLevel === "System") && (
            <Col className="gutter-row" span={6}>
              <Input
                placeholder={t("filters_modal.system_name")}
                allowClear
                value={systemNameField}
                onChange={(e) => setSystemNameField(e.target.value)}
              />
            </Col>
          )}
        </Row>
        <Row gutter={8} className="mb-4">
          {selectedAggregationLevel === "Device" && (
            <>
              <Col className="gutter-row" span={6}>
                <Input
                  placeholder={t("filters_modal.device_name")}
                  allowClear
                  value={deviceNameField}
                  onChange={(e) => setDeviceNameField(e.target.value)}
                />
              </Col>
              <Col className="gutter-row" span={6}>
                <Input
                  placeholder={t("filters_modal.device_serial_number")}
                  allowClear
                  value={deviceSerialNumberField}
                  onChange={(e) => setDeviceSerialNumberField(e.target.value)}
                />
              </Col>
              <Col className="gutter-row" span={6}>
                <Input
                  placeholder={t("filters_modal.device_location")}
                  allowClear
                  value={deviceLocationField}
                  onChange={(e) => setDeviceLocationField(e.target.value)}
                />
              </Col>
              <Col className="gutter-row" span={6}>
                <Input
                  placeholder={t("filters_modal.device_type")}
                  allowClear
                  value={deviceTypeField}
                  onChange={(e) => setDeviceTypeField(e.target.value)}
                />
              </Col>
            </>
          )}
        </Row>
      </div>
      <Button
        type="primary"
        size="large"
        className="flex ml-auto"
        onClick={() => onClose(true)}
      >
        {t("filters_modal.done")}
      </Button>
    </Modal>
  );
}

FilterReportDataModal.propTypes = propTypes;
FilterReportDataModal.defaultProps = defaultProps;

export default FilterReportDataModal;
