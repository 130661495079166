import { t } from "i18next";
import PropTypes from "prop-types";

import CustomTable from "../../CustomTable/CustomTable";
import EditFdsSchedule from "../EditFdsSchedule/EditFdsSchedule";

const propTypes = {
  data: PropTypes.array,
  status: PropTypes.string,
  pageSize: PropTypes.number,
  totalCount: PropTypes.number,
  onChange: PropTypes.func,
  refetchFdsSchedulesList: PropTypes.func,
};

const defaultProps = {
  data: [],
  status: "loading",
  pageSize: 10,
  totalCount: 0,
  onChange: () => {},
  refetchFdsSchedulesList: () => {},
};

const getActionInfo = (customFlowClosedata) => {
  // const alarmString = "Alarm";
  // const shutOffString = "ShutOff";

  switch (Number(customFlowClosedata)) {
    case 0:
      return t("flow_configuration.actions.shut_off");
    case 1:
      return t("flow_configuration.actions.shut_off");
    default:
      return "-";
  }
};

function FdsScheduleListTable({
  data,
  status,
  pageSize,
  totalCount,
  onChange,
  refetchFdsSchedulesList,
}) {
  const columns = [
    {
      title: t("filters_modal.property_name"),
      key: "PropertyName",
      render: (record) => {
        return (
          <span className="text-white text-opacity-80 font-light">
            {record?.PropertyName || "-"}
          </span>
        );
      },
      width: 200,
    },
    {
      title: t("filters_modal.floor"),
      key: "FloorName",
      render: (record) => {
        return (
          <span className="text-white text-opacity-80 font-light">
            {record?.FloorName || "-"}
          </span>
        );
      },
      width: 200,
    },
    {
      title: t("filters_modal.system"),
      key: "SystemName",
      render: (record) => {
        return (
          <span className="text-white text-opacity-80 font-light">
            {record?.SystemName || "-"}
          </span>
        );
      },
      width: 200,
    },
    {
      title: t("filters_modal.device_type"),
      key: "DeviceType",
      render: (record) => {
        return (
          <span className="text-white text-opacity-80 font-light">
            {record?.DeviceType
              ? t(`device_type_public_name.${record?.DeviceType}`)
              : "-"}
          </span>
        );
      },
      width: 200,
    },
    {
      title: t("filters_modal.device_serial_number"),
      key: "DeviceSerialNumber",
      render: (record) => {
        return (
          <span className="text-white text-opacity-80 font-light">
            {record?.DeviceSerialNumber || "-"}
          </span>
        );
      },
      width: 175,
    },
    {
      title: t("filters_modal.flow_rate"),
      key: "FlowRate",
      render: () => {
        return (
          <span className="text-white text-opacity-80 font-light">
            {t("filters_modal.h_m_l")}
          </span>
        );
      },
      width: 140,
    },
    {
      title: t("filters_modal.flow_duration"),
      key: "FlowDuration",
      render: (record) => {
        const lowDuration = record?.CustomFlow?.Data?.LowDuration || "-";
        const midDuration = record?.CustomFlow?.Data?.MedDuration || "-";
        const highDuration = record?.CustomFlow?.Data?.HighDuration || "-";
        return (
          <span className="text-white text-opacity-80 font-light">
            {`${highDuration} / ${midDuration} / ${lowDuration}`}
          </span>
        );
      },
      width: 150,
    },
    {
      title: t("filters_modal.action"),
      key: "Action",
      render: (record) => {
        const lowClose = record?.CustomFlow?.Data?.LowClose ?? "-";
        const midClose = record?.CustomFlow?.Data?.MedClose ?? "-";
        const highClose = record?.CustomFlow?.Data?.HighClose ?? "-";

        const lowCloseInfo = getActionInfo(lowClose);
        const midCloseInfo = getActionInfo(midClose);
        const highCloseInfo = getActionInfo(highClose);

        return (
          <span className="text-white text-opacity-80 font-light">
            {`${highCloseInfo} / ${midCloseInfo} / ${lowCloseInfo}`}
          </span>
        );
      },
      width: 250,
    },
    {
      title: t("filters_modal.edit"),
      key: "Edit",
      render: (record) => {
        return (
          <EditFdsSchedule
            fdsScheduleObject={record}
            refetchFdsSchedulesList={refetchFdsSchedulesList}
          />
        );
      },
      width: 70,
    },
  ];

  return (
    <CustomTable
      className={`${!pageSize ? "opacity-0" : "opacity:100"}`}
      data={data}
      status={status}
      pageSize={pageSize}
      noPagination={!(totalCount > pageSize) || status === "loading"}
      tableColumns={columns}
      totalCount={totalCount}
      onChange={onChange}
    />
  );
}

FdsScheduleListTable.propTypes = propTypes;
FdsScheduleListTable.defaultProps = defaultProps;

export default FdsScheduleListTable;
