import { RightOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

function EventsLogTableHeader() {
  const navigate = useNavigate();

  return (
    <div className="custom-table-header-content">
      <div className="custom-table-header-title-holder">
        <div className="custom-table-header-title">
          {t("dashboard_menu.event_log")}
        </div>
        <Divider type="vertical" />
        <span className="custom-table-header-subtitle">
          {t("dashboard_menu.all_properties")}
        </span>
      </div>
      <Button
        type="link"
        className="custom-table-header-action"
        onClick={() => navigate("/events")}
      >
        {t("dashboard_menu.view_all_events")} <RightOutlined />
      </Button>
    </div>
  );
}

export default EventsLogTableHeader;
