import { useEffect, useState } from "react";

import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import Search from "antd/es/input/Search";
import { t } from "i18next";
import { useSearchParams } from "react-router-dom";

import useUsersGetTotalAndUsedLicenses from "../../../api/hooks/useUsersGetTotalAndUsedLicenses";
import useUsersManagementGetUsers from "../../../api/hooks/useUsersManagementGetUsers";
import useWindowSize from "../../../hooks/useWindowSize";
import Loader from "../../Loader/Loader";
import UnsavedChangesWarning from "../../Shared/UnsavedChangesWarning";
import CreateUser from "./CreateUser";
import UsersListLicenses from "./UsersListLicenses/UsersListLicenses";
import UsersListTable from "./UsersListTable/UsersListTable";
import "./scss/UsersList.scss";

function UsersList() {
  const window = useWindowSize();

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);
  const [isAlertUnsavedChangesOpen, setAlertUnsavedChangesOpen] =
    useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [createFormReference, setFormReference] = useState();

  const pageSize = 10;
  // get search params from URl and format them as expected from the API
  const params = {};
  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  const { page } = params;
  const {
    data: users,
    status: usersStatus,
    refetch: getUsersForManagement,
  } = useUsersManagementGetUsers(
    {
      pageSize,
      startIndex: Number(page) - 1 >= 0 ? (Number(page) - 1) * pageSize : 0,
      search: debouncedSearchQuery,
    },
    {
      enabled: pageSize > 0,
      cacheTime: 0,
    },
  );

  const { data: licensesCount, refetch: getUsersTotalAndUsedLicenses } =
    useUsersGetTotalAndUsedLicenses({
      enabled: true,
      cacheTime: 0,
    });

  useEffect(() => {
    getUsersForManagement();
  }, [debouncedSearchQuery, page, getUsersForManagement]);

  const onPageChange = (pagination) => {
    setSearchParams({ ...params, page: pagination.current });
  };

  const onSearch = (value) => {
    setSearchQuery(value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  const openCreateNewUserModal = () => {
    setIsCreateUserModalOpen(true);
  };

  const handleOnCancelCreateModal = () => {
    if (isFormDirty) {
      setAlertUnsavedChangesOpen(true);
    } else {
      setIsCreateUserModalOpen(false);
      createFormReference.resetFields();
      getUsersForManagement();
      getUsersTotalAndUsedLicenses();
    }
  };

  return (
    <div className="content-wrapper overflow-y-auto">
      <div className="users-list flex h-full flex-col">
        <div className="flex items-center sm:justify-end justify-center">
          {window?.width > 992 && (
            <h1 className="page-title mb-0">{t("users.users_list")}</h1>
          )}

          <div className="ml-0 sm:ml-auto flex-col-reverse sm:flex-row flex sm:inline-flex items-center justify-center md:justify-between">
            <Search
              className="w-full mr-4 my-1"
              placeholder={t("users.search_users")}
              onSearch={onSearch}
              onChange={handleSearchChange}
              allowClear
              size="middle"
            />
            <Button
              size="middle"
              type="primary"
              className="ml-0 sm:ml-auto p-2"
              onClick={openCreateNewUserModal}
            >
              <PlusCircleOutlined />
              {t("users.create_new_user")}
            </Button>
          </div>
        </div>

        <div className="my-2">
          <UsersListLicenses licensesCount={licensesCount} />
        </div>

        {!pageSize && <Loader className="absolute-center" />}

        {(usersStatus === "loading" ||
          users?.TotalCount > 0 ||
          searchQuery) && (
          <>
            <UsersListTable
              data={users?.Result}
              status={usersStatus}
              totalCount={users?.TotalCount}
              onChange={onPageChange}
              refetchUsersForManagement={getUsersForManagement}
              refetchUsersLicensesCount={getUsersTotalAndUsedLicenses}
            />
            <Modal
              className="create-new-user-modal"
              maskClosable={false}
              footer={null}
              open={isCreateUserModalOpen}
              keyboard={false}
              onCancel={handleOnCancelCreateModal}
              destroyOnClose
              wrapClassName="custom-create-user-modal-content"
              width={660}
              bodyStyle={{
                height: 650,
                overflowY: "auto",
                marginTop: "1.5rem",
              }}
            >
              <CreateUser
                setIsFormDirty={setIsFormDirty}
                setFormReference={setFormReference}
                setIsCreateUserModalOpen={setIsCreateUserModalOpen}
                refetchUsersForManagement={getUsersForManagement}
                refetchUsersLicensesCount={getUsersTotalAndUsedLicenses}
              />
            </Modal>
            <UnsavedChangesWarning
              onSaveClick={() => {
                setAlertUnsavedChangesOpen(false);
                createFormReference.submit();
              }}
              onDontSaveClick={() => {
                setAlertUnsavedChangesOpen(false);
                setIsCreateUserModalOpen(false);
                setIsFormDirty(false);
                createFormReference.resetFields();
                getUsersForManagement();
                getUsersTotalAndUsedLicenses();
              }}
              onCancelClick={() => {
                setAlertUnsavedChangesOpen(false);
              }}
              isAlertUnsavedChangesOpen={isAlertUnsavedChangesOpen}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default UsersList;
