import { Table } from "antd";
import PropTypes from "prop-types";

import MonitoringTableColumns from "./MonitoringTableColumns";
import "./scss/MonitoringTable.scss";

function MonitoringTable({ eventsData, eventTypes }) {
  const tableColumns = MonitoringTableColumns(eventTypes);
  return (
    <div className="mt-4 ml-1">
      <Table
        size="small"
        className="overflow-x-auto"
        tableLayout="fixed"
        rowKey={(eventData) => eventData?.EventId}
        dataSource={eventsData}
        columns={tableColumns}
        pagination={false}
        rowClassName={() => "monitoring-row"}
      />
    </div>
  );
}
MonitoringTable.propTypes = {
  eventsData: PropTypes.array,
  eventTypes: PropTypes.array,
};
MonitoringTable.defaultProps = {
  eventsData: [],
  eventTypes: [],
};
export default MonitoringTable;
