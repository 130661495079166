function PropertyManagementIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="208"
      height="256"
      viewBox="0 0 208 256"
      fill="none"
    >
      <path
        fill="#757782"
        fillRule="evenodd"
        transform="translate(-105, -110)"
        d="M182.266,269.081h13.8V255.418h-13.8v13.663Zm0-19.923h13.8V235.494h-13.8v13.664Zm43.4-18.935h13.8V216.56h-13.8v13.663Zm0-34.023v13.663h13.8V196.2h-13.8Zm-30.929,13.663h13.8V196.2h-13.8v13.663Zm-14.971-28.1V219.94h-18.34v67.7h93.114V181.762H179.765Zm-11.854,99.461V226.361h42.512v25.272l-8.13,8.049L202.2,281.223H167.911Zm79.816-.446H209.014l0.078-18.289,19.324-19.134,19.311,19.122v18.3Zm-19.311-46.961-11.508,11.4V219.94H186.25V188.183h61.8v65.078Zm-7.34,40.706h13.813V260.845H221.076v13.677Z"
      />
    </svg>
  );
}

export default PropertyManagementIcon;
