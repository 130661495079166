const mapper = (destination, source) => {
  const dest = destination;
  dest.current = {
    id: source?.UserId,
    email: source?.Email,
    fullName: `${source.FirstName} ${source.LastName}`,
    phoneNumber: source?.PhoneNumber,
    userZones: source?.HomeAppUserZones.map((x) => {
      return {
        propertyId: x.PropertyId,
        propertyName: x.PropertyName,
        zoneId: x.ZoneId,
        zone: x.ZoneName,
        id: x.Id,
        userFullname: `${source.FirstName} ${source.LastName}`,
        phoneNumber: source?.PhoneNumber,
      };
    }),
    addUserZones: [],
  };
  return dest.current;
};

export default mapper;
