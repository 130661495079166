import { useState } from "react";

import { EditOutlined } from "@ant-design/icons";
import { Row, Col, Divider, Button, Modal } from "antd";
import { t } from "i18next";
import PropTypes from "prop-types";

import logo from "../../../../assets/img/property-overview-default-pic.png";
import useWindowSize from "../../../../hooks/useWindowSize";
import UnsavedChangesWarning from "../../../Shared/UnsavedChangesWarning";
import EditProperty from "./EditProperty";

function PropertyManagementCard({
  className,
  property,
  refetchPropertyBasics,
}) {
  const [isPropertyModalOpen, setIsPropertyModalOpen] = useState(false);
  const [isAlertUnsavedChangesOpen, setAlertUnsavedChangesOpen] =
    useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [editFormReference, setEditFormReference] = useState();
  const window = useWindowSize();

  const onEditButtonClick = () => {
    setIsPropertyModalOpen(true);
  };

  const onCancelButtonClick = () => {
    if (isFormDirty) {
      setAlertUnsavedChangesOpen(true);
    } else {
      setIsPropertyModalOpen(false);
      editFormReference.resetFields();
      refetchPropertyBasics();
    }
  };

  return (
    <div
      role="none"
      className={`property border-triple-blue h-full ${className}`}
    >
      <Row className="h-full">
        <Col xs={{ span: 24 }} lg={{ span: 6 }}>
          <div
            style={{
              backgroundImage: `url(${
                property.ImageUrl ? property.ImageUrl : logo
              })`,
              backgroundSize: property?.ImageUrl ? "cover" : "contain",
            }}
            className={`property-image h-44 ${
              window?.width < 991 ? "property-image--blured-bottom" : ""
            }`}
          >
            &nbsp;
          </div>
        </Col>

        <Col xs={{ span: 24 }} lg={{ span: 6 }}>
          <div className={`property-details ${className} `}>
            <h3 className="property-details-title">{property?.Name}</h3>
            <div className="property-details-address text-15 text-secondary-white">
              {property?.Address}
            </div>
          </div>
        </Col>

        <Col
          xs={{ span: 22, offset: 1 }}
          lg={{ span: 1 }}
          className="flex items-center justify-center lg:m-0"
        >
          <Divider
            type={window?.width > 992 ? "vertical" : "horizontal"}
            className="lg:h-4/5 m-0"
          />
        </Col>

        <Col
          xs={{ span: 24 }}
          lg={{ span: 11 }}
          className="flex justify-end items-center"
        >
          <Button
            type="primary"
            onClick={onEditButtonClick}
            size="large"
            icon={<EditOutlined />}
            className="mr-2 my-2"
          >
            {t("filters_modal.edit")}
          </Button>
        </Col>
      </Row>
      <Modal
        className="property-management-modal"
        maskClosable={false}
        footer={null}
        open={isPropertyModalOpen}
        keyboard={false}
        onCancel={onCancelButtonClick}
        destroyOnClose
        width={650}
        bodyStyle={{ height: 650, overflowY: "auto", marginTop: "1.5rem" }}
      >
        <EditProperty
          propertyId={property?.Id}
          setIsFormDirty={setIsFormDirty}
          setEditFormReference={setEditFormReference}
        />
      </Modal>
      <UnsavedChangesWarning
        onSaveClick={() => {
          setAlertUnsavedChangesOpen(false);
          editFormReference.submit();
        }}
        onDontSaveClick={() => {
          setAlertUnsavedChangesOpen(false);
          setIsPropertyModalOpen(false);
          setIsFormDirty(false);
          editFormReference.resetFields();
          refetchPropertyBasics();
        }}
        onCancelClick={() => {
          setAlertUnsavedChangesOpen(false);
        }}
        isAlertUnsavedChangesOpen={isAlertUnsavedChangesOpen}
      />
    </div>
  );
}

PropertyManagementCard.propTypes = {
  className: PropTypes.string,
  property: PropTypes.object,
  refetchPropertyBasics: PropTypes.func,
};

PropertyManagementCard.defaultProps = {
  className: "",
  property: {},
  refetchPropertyBasics: () => {},
};

export default PropertyManagementCard;
