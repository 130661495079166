import { t } from "i18next";

const DateConstants = {
  daysOfWeek: {
    0: t("days_abbr.Sun"),
    1: t("days_abbr.Mon"),
    2: t("days_abbr.Tue"),
    3: t("days_abbr.Wed"),
    4: t("days_abbr.Thu"),
    5: t("days_abbr.Fri"),
    6: t("days_abbr.Sat"),
  },
  fullyQualifiedDaysOfWeek: {
    0: t("days.Sunday"),
    1: t("days.Monday"),
    2: t("days.Tuesday"),
    3: t("days.Wednesday"),
    4: t("days.Thursday"),
    5: t("days.Friday"),
    6: t("days.Saturday"),
  },
  months: {
    0: t("months.January"),
    1: t("months.February"),
    2: t("months.March"),
    3: t("months.April"),
    4: t("months.May"),
    5: t("months.June"),
    6: t("months.July"),
    7: t("months.August"),
    8: t("months.September"),
    9: t("months.October"),
    10: t("months.November"),
    11: t("months.December"),
  },
  monthsAbbreviated: {
    0: t("months_abbr.Jan."),
    1: t("months_abbr.Feb."),
    2: t("months_abbr.Mar."),
    3: t("months_abbr.Apr."),
    4: t("months_abbr.May."),
    5: t("months_abbr.Jun."),
    6: t("months_abbr.Jul."),
    7: t("months_abbr.Aug."),
    8: t("months_abbr.Sept."),
    9: t("months_abbr.Oct."),
    10: t("months_abbr.Nov."),
    11: t("months_abbr.Dec."),
  },
  formats: {
    // bucket type: year
    fullYear_monthAbbreviation: "yyyy MMM",
    fullYear_monthAbbreviation_label: "yyyy MMM, Example: 2023 Jan",
    fullYear_fullMonthName: "yyyy MMMM",
    fullYear_fullMonthName_label: "yyyy MMMM, Example: 2023 January",
    monthAbbreviation_fullYear: "MMM yyyy",
    monthAbbreviation_fullYear_label: "MMM yyyy, Example: Jan 2023",
    fullMonthName_fullYear: "MMMM yyyy",
    fullMonthName_fullYear_label: "MMMM yyyy, Example: January 2023",
    // bucket type: month
    twoDigitsYear_monthAbbreviation_dayOfMonth: "yy MMM dd",
    twoDigitsYear_monthAbbreviation_dayOfMonth_label:
      "yy MMM dd, Example: 23 Jan 10",
    fullYear_monthAbbreviation_dayOfMonth: "yyyy MMM dd",
    fullYear_monthAbbreviation_dayOfMonth_label:
      "yyyy MMM dd, Example: 2023 Jan 10",
    // bucket type: day
    dayOfMonth_monthAbbreviation_fullYear_hours_minutes: "dd MMM yyyy HH:mm tt",
    dayOfMonth_monthAbbreviation_fullYear_hours_minutes_label:
      "dd MMM yyyy HH:mm tt, Ex: 10 Jan 2023 14:00 PM",
    dayOfWeekName_dayOfMonth_monthAbbreviation_fullYear_hours_minutes:
      "dddd dd MMM yyyy HH:mm tt",
    dayOfWeekName_dayOfMonth_monthAbbreviation_fullYear_hours_minutes_label:
      "dddd dd MMM yyyy HH:mm tt, Ex: Tuesday 10 Jan 2023 14:00 PM",
  },
};
export default DateConstants;
