import { FrownOutlined } from "@ant-design/icons";
import { notification } from "antd";

async function useMobileAppUserUpdate(
  userId,
  formValues,
  setIsLoading,
  setIsFormDirty,
  editMobileAppUserMutationFn,
) {
  let editUserResponse;
  // Remove extra whitespaces from a string and ensure there is only one space between.
  const nameParts = formValues.fullName.replace(/\s+/g, " ").trim().split(" ");
  const firstName = nameParts[0];
  // The rest of the array elements are joined back together to form the last name.
  const lastName = nameParts.length > 1 ? nameParts.slice(1).join(" ") : "";

  // merge existing (already assigned zones) with newly added
  const addUserZoneIds = [
    ...formValues.addUserZones,
    ...formValues.userZones,
  ].map((x) => x.zoneId);

  const request = {
    UserId: userId,
    FirstName: firstName,
    LastName: lastName,
    Email: formValues.email,
    PhoneNumber: formValues.phoneNumber,
    AddUserZoneIds: addUserZoneIds,
  };

  try {
    editUserResponse = await editMobileAppUserMutationFn.mutateAsync({
      userId: request.UserId,
      request,
    });
    notification.success({
      message: (
        <span className="uppercase tracking-widest">user update success</span>
      ),
      duration: 2,
      placement: "topRight",
    });
  } catch (error) {
    let description = "Could not update user.";
    if (error?.response?.data.includes("Could not update HomeAppUser.")) {
      description =
        "Could not update home app user zones. The zone contains devices on multiple properties. Please fix your zone.";
    }
    notification.error({
      message: (
        <span className="uppercase tracking-widest">Something went wrong!</span>
      ),
      duration: 0,
      description,
      placement: "topRight",
      icon: <FrownOutlined className="text-triple-red" />,
    });
  } finally {
    setIsLoading(false);
    setIsFormDirty(false);
  }

  return editUserResponse;
}
export default useMobileAppUserUpdate;
