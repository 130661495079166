import { FrownOutlined } from "@ant-design/icons";
import { notification } from "antd";
import { t } from "i18next";

import { usefnCreatePropertyFloorDtos } from "./useFactoryPropertyFloorDtos";

async function useHandleCreate(
  formValues,
  serviceProviderId,
  setIsLoading,
  setIsFormDirty,
  createPropertyMutation,
  updatePropertyPreferencesMutation,
  createPropertyFloorsMutation,
  uploadAttachmentMutation,
) {
  let responseUploadAttachment;
  let createPropertyResponse;
  const postPropertyRequest = {
    Name: formValues.Name,
    Address: formValues.Address ?? null,
    ImageUrl: null,
    ServiceProviderId: serviceProviderId,
  };

  try {
    if (formValues.ImageUrl?.file?.status === "removed") {
      postPropertyRequest.ImageUrl = null;
    }

    if (formValues.ImageUrl?.file?.status === "done") {
      const { fileList } = formValues.ImageUrl;
      const data = new FormData();
      data.append("file", fileList[0].originFileObj);
      responseUploadAttachment = await uploadAttachmentMutation.mutateAsync(
        data,
      );
      postPropertyRequest.ImageUrl = responseUploadAttachment?.data;
    }

    // Create Property Object.
    createPropertyResponse = await createPropertyMutation.mutateAsync(
      postPropertyRequest,
    );
    const newProperty = createPropertyResponse.data;

    // Create Floors to the Property object if any.
    if (formValues.AddPropertyFloors.length > 0) {
      const createPropertyFloorDtos = usefnCreatePropertyFloorDtos(
        formValues,
        newProperty.Id,
      );

      await createPropertyFloorsMutation.mutateAsync(createPropertyFloorDtos);
    }

    // Update Property Preferences
    const updatePropertyPreferencesRequest = {
      Id: newProperty.Id,
      Name: newProperty.Name,
      Address: newProperty.Address,
      ImageUrl: newProperty.ImageUrl,
      PropertyPreferences: {
        NumberOfOccupants: 0,
        CostOfWater: 0,
        Currency: "USD",
        WorkingDaysEnabled: formValues.WorkingDaysEnabled,
        WorkingDaysStartDayOfWeek: formValues.WorkingDaysStartDay ?? 1, // Default to Monday
        WorkingDaysEndDayOfWeek: formValues.WorkingDaysEndDay ?? 5, // Default to Friday
        WorkingDaysStartTime: formValues.WorkingDaysStartTime
          ? formValues.WorkingDaysStartTime.format(t("time_picker.hh_mm"))
          : "09:00",
        WorkingDaysEndTime: formValues.WorkingDaysEndTime
          ? formValues.WorkingDaysEndTime.format(t("time_picker.hh_mm"))
          : "18:00",
      },
    };

    await updatePropertyPreferencesMutation.mutateAsync({
      request: updatePropertyPreferencesRequest,
      propertyId: updatePropertyPreferencesRequest.Id,
    });
  } catch (error) {
    notification.error({
      message: (
        <span className="uppercase tracking-widest">
          {t("flow_configuration.somethimg_went_wrong")}
        </span>
      ),
      duration: 5,
      description: t("flow_configuration.not_update"),
      placement: "topRight",
      icon: <FrownOutlined className="text-triple-red" />,
    });
  } finally {
    setIsLoading(false);
    setIsFormDirty(false);
    notification.success({
      message: (
        <span className="uppercase tracking-widest">
          {t("flow_configuration.not_update")}
        </span>
      ),
      duration: 2,
      placement: "topRight",
    });
  }

  return createPropertyResponse;
}
export default useHandleCreate;
