import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal, Typography } from "antd";
import PropTypes from "prop-types";

import { useDeleteHomeAppUserZone } from "../../../../../api/hooks/useMobileAppUsers";
import { FormUserZonesAdd, FormUserZonesDisplay } from "./module";

const { Text } = Typography;

function FormUserZones({
  userForm,
  homeAppUserZoneFilters,
  setAddZoneDisabled,
  addZoneDisabled,
  setSelectedProperty,
  setZones,
  handlePropertyChange,
}) {
  const { mutate: deleteHomeAppUserZone } = useDeleteHomeAppUserZone();

  const onClickAddZone = (add, key) => {
    add();
    const addUserZones = userForm.getFieldValue("addUserZones") || [];
    const propertyId = userForm.getFieldValue("property");
    const zoneId = userForm.getFieldValue("zone");
    const property = homeAppUserZoneFilters?.Value?.Properties.find(
      (option) => option.Id === propertyId,
    );
    const zone = property?.Zones?.find((x) => x.Id === zoneId);
    addUserZones[key] = {
      propertyId,
      propertyName: property?.Name || "N/A",
      zoneId,
      zone: zone?.ZoneName || "N/A",
      id: zone?.Id,
      userFullname: userForm.getFieldValue("fullName"),
      phoneNumber: userForm.getFieldValue("phoneNumber"),
    };

    userForm.setFieldsValue({ addUserZones });
    userForm.setFieldsValue({
      property: null,
      zone: null,
    });
    setAddZoneDisabled(true);
    setSelectedProperty(null);
    setZones([]);
  };

  const onClickDeleteZone = (e, key) => {
    const userZone = e.currentTarget.dataset;
    Modal.confirm({
      width: 550,
      title: (
        <div className="text-center uppercase flex flex-col justify-center items-center">
          <ExclamationCircleOutlined
            style={{ fontSize: 36, color: "#ed1c24", marginBottom: "1rem" }}
          />
          are you sure?
        </div>
      ),
      content: (
        <div className="uppercase tracking-wider">
          <p className="my-1">
            Do you want to delete user zone &quot;{userZone.zonename}&quot; ?
          </p>
          <p className="my-1 text-center">This process cannot be undone.</p>
        </div>
      ),
      className: "alert-delete-confirm",
      icon: null,
      onOk() {
        // Make the API call to delete the object then sync the UI.
        const currentValues = userForm.getFieldValue("userZones");
        const zoneToRemove = currentValues[key];
        deleteHomeAppUserZone(zoneToRemove.id, {
          onSuccess: () => {
            userForm.setFieldsValue({
              userZones: currentValues.filter((_, i) => i !== key),
            });
          },
        });
      },
      onCancel() {
        // Do Nothing
      },
      okButtonProps: {
        danger: true,
        className: "btn-leave",
      },
      okText: "Delete",
      cancelButtonProps: { className: "btn-cancel" },
    });
  };

  return (
    <>
      <div className="user-zones">
        <div className="flex justify-center mt-4 mb-2">
          <Text className="uppercase tracking-widest">User Zones</Text>
        </div>
      </div>
      <FormUserZonesDisplay
        formFieldName="userZones"
        userForm={userForm}
        onClickDeleteZone={onClickDeleteZone}
      />
      <FormUserZonesAdd
        formFieldName="addUserZones"
        userForm={userForm}
        onClickAddZone={onClickAddZone}
        addZoneDisabled={addZoneDisabled}
        handlePropertyChange={handlePropertyChange}
      />
    </>
  );
}
FormUserZones.propTypes = {
  userForm: PropTypes.object,
  homeAppUserZoneFilters: PropTypes.object,
  setAddZoneDisabled: PropTypes.func,
  addZoneDisabled: PropTypes.bool,
  setSelectedProperty: PropTypes.func,
  setZones: PropTypes.func,
  handlePropertyChange: PropTypes.func,
};
FormUserZones.defaultProps = {
  userForm: {},
  homeAppUserZoneFilters: {},
  setAddZoneDisabled: () => {},
  addZoneDisabled: true,
  setSelectedProperty: () => {},
  setZones: () => {},
  handlePropertyChange: () => {},
};
export default FormUserZones;
