function ScheduleManagementIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="208"
      height="256"
      viewBox="0 0 208 256"
    >
      <path
        fill="#757782"
        fillRule="evenodd"
        transform="translate(-105, -110)"
        d="M264.459,211.208c0.014-.4.03-0.791,0.03-1.19,0-18.775-14.982-34.05-33.4-34.05a33.608,33.608,0,0,0-30.929,20.741V195.54h-48.71V276.1l113.037,0.814v-65.7h-0.031Zm-52.646-19.964a26.81,26.81,0,0,1,19.279-8.286c14.635,0,26.541,12.139,26.541,27.06a27.215,27.215,0,0,1-26.654,27.062,24.307,24.307,0,0,1-17.6-7.131,29.014,29.014,0,0,1-8.834-20.023A25.916,25.916,0,0,1,211.813,191.244Zm-13.434,11.285a33.139,33.139,0,0,0-.683,7.58,35.634,35.634,0,0,0,1.434,9.074H158.308V202.529h40.071Zm-40.071,66.629V226.172H201.99a35.673,35.673,0,0,0,6.692,8.863,31.1,31.1,0,0,0,21.554,9.046c0.322,0,.646,0,0.969-0.016a34.44,34.44,0,0,0,26.43-13.584v39.393ZM172.6,244.269h10.8V233.254H172.6v11.015Zm20.214,0h10.8V233.254h-10.8v11.015ZM172.6,261.831h10.8V250.816H172.6v11.015Zm20.214,0h10.8V250.816h-10.8v11.015Zm19.862,0h10.805V250.816H212.676v11.015Zm19.862,0h10.8V250.816h-10.8v11.015Zm10.8-42.289a3.433,3.433,0,0,0,3.246-2.377,3.51,3.51,0,0,0-2.15-4.429l-10.676-3.677V192.392a3.429,3.429,0,1,0-6.856,0v21.683l15.338,5.283A3.374,3.374,0,0,0,243.343,219.542Z"
      />
    </svg>
  );
}

export default ScheduleManagementIcon;
