import { useEffect, useRef, useState } from "react";

import { Button, Form, Input } from "antd";
import dayjs from "dayjs";
import { t } from "i18next";
import PropTypes from "prop-types";

import useAttachments from "../../../../api/hooks/useAttachments";
import usePropertyFloorCreateMultiple from "../../../../api/hooks/usePropertyFloorCreateMultiple";
import usePropertyFloorUpdateMulitple from "../../../../api/hooks/usePropertyFloorUpdateMultiple";
import usePropertyPreferences from "../../../../api/hooks/usePropertyPreferences";
import { useUpdatePropertyBasics } from "../../../../api/hooks/useUpdatePropertyPreferences";
import ImageUploader from "../../../ImageUploader/ImageUploader";
import Toggle from "../../../Shared/Toggle";
import { useHandleUpdate } from "./hooks/usePropertyHooks";
import PropertyFloors from "./PropertyFloors";
import PropertyPreferencesMapper from "./PropertyPreferencesMapper";
import PropertyPreferencesWorkingDays from "./PropertyPreferencesWorkingDays";
import PropertyServiceProvider from "./PropertyServiceProvider";
import "./scss/EditProperty.scss";

function EditProperty({ propertyId, setIsFormDirty, setEditFormReference }) {
  const [isLoading, setIsLoading] = useState(false);
  const [disableSaveChanges] = useState(false);
  const [showWorkingDaysRoutine, setShowWorkingDaysRoutine] = useState(false);
  const [propertyPreferencesForm] = Form.useForm();
  const [fileListArr, setFileListArr] = useState([]);
  const initialFormValues = useRef({});

  const updatePropertyPreferencesMutation = useUpdatePropertyBasics(propertyId);
  const updatePropertyFloorsMutation = usePropertyFloorUpdateMulitple();
  const createPropertyFloorsMutation = usePropertyFloorCreateMultiple();
  const uploadAttachmentMutation = useAttachments();
  const { refetch: getPropertyPreferences } = usePropertyPreferences(
    propertyId,
    { enabled: false },
  );

  useEffect(() => {
    getPropertyPreferences(propertyId).then((response) => {
      const initFormValues = PropertyPreferencesMapper(
        initialFormValues,
        response.data,
      );
      propertyPreferencesForm.setFieldsValue(initFormValues.current);
      setFileListArr(
        initFormValues.current?.ImageUrl
          ? [{ url: initFormValues.current?.ImageUrl }]
          : [],
      );
      setShowWorkingDaysRoutine(
        response?.data?.PropertyPreferences.WorkingDaysEnabled,
      );
    });
  }, [getPropertyPreferences, propertyId, propertyPreferencesForm]);

  useEffect(() => {
    setEditFormReference(propertyPreferencesForm);
  }, [propertyPreferencesForm, setEditFormReference]);

  const handleFieldsChanges = (changedFields) => {
    const fieldName = changedFields[0].name[0];
    const fieldValue = changedFields[0].value;

    if (fieldName === "WorkingDaysEnabled") {
      if (fieldValue === true) {
        setShowWorkingDaysRoutine(true);
      } else {
        setShowWorkingDaysRoutine(false);
      }
    }
  };

  const handleValuesChanges = (changedValues) => {
    const isFormDirty = Object.keys(changedValues).length > 0;
    setIsFormDirty(isFormDirty);
  };

  const HandleUpdate = async (formValues) => {
    const { createPropertyFloorsResponse, updatePropertyPreferencesResponse } =
      await useHandleUpdate(
        propertyId,
        initialFormValues,
        formValues,
        setIsLoading,
        setIsFormDirty,
        updatePropertyPreferencesMutation,
        updatePropertyFloorsMutation,
        createPropertyFloorsMutation,
        uploadAttachmentMutation,
      );
    propertyPreferencesForm.setFieldValue(
      "ImageUrl",
      updatePropertyPreferencesResponse.data.ImageUrl,
    );
    if (createPropertyFloorsResponse?.data?.length > 0) {
      const currentValues = propertyPreferencesForm.getFieldValue("Floors");
      const newFloors = createPropertyFloorsResponse.data;
      const updatedFloors = [...currentValues, ...newFloors];
      propertyPreferencesForm.setFieldsValue({ Floors: updatedFloors });
      propertyPreferencesForm.setFieldsValue({ AddPropertyFloors: [] }); // reset the dynamic fields
      initialFormValues.current.Floors = updatedFloors;
    }
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const formValues = propertyPreferencesForm.getFieldsValue();
    HandleUpdate(formValues);
  };

  const onRemoveImg = () => {
    setFileListArr([]);
  };

  const onImageChange = (info) => {
    const newFileList = [...info.fileList];
    setFileListArr(newFileList);
  };

  const request = ({ file, onSuccess, onError }) => {
    // Used to prevent the plugin auto post otherwise it will throw exception in the console.
    setTimeout(() => {
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > 4) {
        onError("");
        setFileListArr([]);
      } else {
        onSuccess(t("edit_property.ok"));
      }
    }, 1000);
  };

  return (
    <div className="content-wrapper mx-2">
      <Form
        form={propertyPreferencesForm}
        name="update-property-preferences"
        layout="vertical"
        className="w-full"
        initialValues={{
          Name: initialFormValues.current.Name,
          Address: initialFormValues.current.Address,
          ImageUrl: initialFormValues.current?.ImageUrl,
          WorkingDaysEnabled: initialFormValues.current?.WorkingDaysEnabled,
          WorkingDaysStartDay: initialFormValues.current?.WorkingDaysStartDay,
          WorkingDaysEndDay: initialFormValues.current?.WorkingDaysEndDay,
          WorkingDaysStartTime: initialFormValues.current?.WorkingDaysStartTime
            ? dayjs(
                initialFormValues.current?.WorkingDaysStartTime,
                t("time_picker.hh_mm"),
              )
            : "",
          WorkingDaysEndTime: initialFormValues.current?.WorkingDaysEndTime
            ? dayjs(
                initialFormValues.current?.WorkingDaysEndTime,
                t("time_picker.hh_mm"),
              )
            : "",
        }}
        onFinish={handleSubmit}
        onFieldsChange={handleFieldsChanges}
        onValuesChange={handleValuesChanges}
      >
        <div className="flex items-center justify-center mb-0">
          <h1 className="page-title">{t("edit_property.edit_property")}</h1>
        </div>
        <>
          <Form.Item name="ImageUrl">
            <ImageUploader
              fileListArr={fileListArr}
              onRemoveImg={onRemoveImg}
              onChange={onImageChange}
              request={request}
              autoUpload={false}
            />
          </Form.Item>
          <Form.Item
            className="mb-4"
            label={t("edit_property.name")}
            name="Name"
            rules={[
              { required: true, message: t("edit_property.name_required") },
            ]}
          >
            <Input size="middle" maxLength={64} />
          </Form.Item>
          <Form.Item
            className="mb-4"
            label={t("edit_property.address")}
            name="Address"
          >
            <Input size="middle" maxLength={128} />
          </Form.Item>
          <PropertyServiceProvider />
          <Toggle
            className="mb-4"
            size="medium"
            fieldLabel={t("edit_property.working_days")}
            fieldName="WorkingDaysEnabled"
            fieldValues={[
              {
                value: false,
                label: t("edit_property.disable"),
              },
              {
                value: true,
                label: t("edit_property.enable"),
              },
            ]}
          />

          {showWorkingDaysRoutine === true ? (
            <PropertyPreferencesWorkingDays />
          ) : null}
          {/* Floors */}
          <PropertyFloors
            formRef={propertyPreferencesForm}
            initialFormValues={initialFormValues}
          />
          <Button
            type="primary"
            htmlType="submit"
            size="medium"
            className="custom-property-save-btn"
            loading={isLoading}
            disabled={disableSaveChanges}
          >
            {t("edit_property.save")}
          </Button>
        </>
      </Form>
    </div>
  );
}

EditProperty.propTypes = {
  propertyId: PropTypes.number,
  setIsFormDirty: PropTypes.func,
  setEditFormReference: PropTypes.func,
};

EditProperty.defaultProps = {
  propertyId: null,
  setIsFormDirty: () => {},
  setEditFormReference: () => {},
};

export default EditProperty;
