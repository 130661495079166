const predefinedStructure = {
  Dashboard: {
    displayName: "users_permissions_data_structure.dashboard",
    features: [
      {
        name: "Dashboard Default View",
        displayName: "users_permissions_data_structure.dashboard_default_view",
      },
      {
        name: "View all Events (Redirect)",
        displayName: "users_permissions_data_structure.view_all_events",
      },
    ],
  },
  PropertyOverview: {
    displayName: "users_permissions_data_structure.property_overview",
    features: [
      {
        name: "Property Overview Default View",
        displayName:
          "users_permissions_data_structure.property_overview_default_view",
      },
      {
        name: "Send Commands",
        displayName: "users_permissions_data_structure.send_commands",
      },
      {
        name: "Property Overview Virtual View",
        displayName:
          "users_permissions_data_structure.property_overview_virtual_view",
      },
    ],
  },
  Statistics: {
    displayName: "users_permissions_data_structure.statistics",
    features: [
      {
        name: "Yearly Graph",
        displayName: "users_permissions_data_structure.yearly_graph",
      },
      {
        name: "Monthly Graph",
        displayName: "users_permissions_data_structure.monthly_graph",
      },
      {
        name: "Daily Graph",
        displayName: "users_permissions_data_structure.daily_graph",
      },
    ],
  },
  Reports: {
    displayName: "users_permissions_data_structure.reports",
    features: [
      {
        name: "Generate Report",
        displayName: "users_permissions_data_structure.generate_report",
      },
      {
        name: "Water Consumption - Table View",
        displayName: "users_permissions_data_structure.wc_table_view",
      },
      {
        name: "Water Consumption - Graph View",
        displayName: "users_permissions_data_structure.wc_graph_view",
      },
      {
        name: "Systems Real Time Status Report",
        displayName: "users_permissions_data_structure.systems_real",
      },
    ],
  },
  Events: {
    displayName: "users_permissions_data_structure.events",
    features: [
      {
        name: "Events Default View",
        displayName: "users_permissions_data_structure.events_default_view",
      },
      {
        name: "Export Events",
        displayName: "users_permissions_data_structure.export_events",
      },
    ],
  },
  Settings: {
    displayName: "users_permissions_data_structure.settings",
    features: [
      {
        name: "Flow Configuration Default View",
        displayName: "users_permissions_data_structure.fc_dafault_view",
      },
      {
        name: "Schedule Default View",
        displayName: "users_permissions_data_structure.schedule_default_view",
      },
      {
        name: "Create New Schedule",
        displayName: "users_permissions_data_structure.create_new_schedule",
      },
      {
        name: "Edit Schedule",
        displayName: "users_permissions_data_structure.edit_schedule",
      },
      {
        name: "Create New FC",
        displayName: "users_permissions_data_structure.create_new_fc",
      },
      {
        name: "Edit FC",
        displayName: "users_permissions_data_structure.edit_fc",
      },
    ],
  },
};

const userStaticData = {
  UserLevelName: "User",
  UserLevelFeatures: [
    {
      FeatureId: 2,
      FeatureName: "Dashboard Default View",
      UserLevelId: 3,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Dashboard",
    },
    {
      FeatureId: 30,
      FeatureName: "View all Events (Redirect)",
      UserLevelId: 3,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Dashboard",
    },
    {
      FeatureId: 3,
      FeatureName: "Property Overview Default View",
      UserLevelId: 3,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Property Overview",
    },
    {
      FeatureId: 36,
      FeatureName: "Property Overview Virtual View",
      UserLevelId: 3,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Property Overview",
    },
    {
      FeatureId: 6,
      FeatureName: "Events Default View",
      UserLevelId: 3,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Events",
    },
  ],
};

const operatorStaticData = {
  UserLevelId: 4,
  UserLevelName: "Operator",
  UserLevelFeatures: [
    {
      FeatureId: 2,
      FeatureName: "Dashboard Default View",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Dashboard",
    },
    {
      FeatureId: 30,
      FeatureName: "View all Events (Redirect)",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Dashboard",
    },
    {
      FeatureId: 3,
      FeatureName: "Property Overview Default View",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Property Overview",
    },
    {
      FeatureId: 29,
      FeatureName: "Send Commands",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Property Overview",
    },
    {
      FeatureId: 36,
      FeatureName: "Property Overview Virtual View",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Property Overview",
    },
    {
      FeatureId: 4,
      FeatureName: "Yearly Graph",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Statistics",
    },
    {
      FeatureId: 19,
      FeatureName: "Monthly Graph",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Statistics",
    },
    {
      FeatureId: 38,
      FeatureName: "Water Consumption - Table View",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Reports",
    },
    {
      FeatureId: 39,
      FeatureName: "Water Consumption - Graph View",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Reports",
    },
    {
      FeatureId: 40,
      FeatureName: "Systems Real Time Status Report",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Reports",
    },
    {
      FeatureId: 6,
      FeatureName: "Events Default View",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Events",
    },
    {
      FeatureId: 7,
      FeatureName: "Flow Configuration Default View",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Settings",
    },
    {
      FeatureId: 26,
      FeatureName: "Create New FC",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Settings",
    },
    {
      FeatureId: 27,
      FeatureName: "Edit FC",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Settings",
    },
    {
      FeatureId: 8,
      FeatureName: "Schedule Default View",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Settings",
    },

    {
      FeatureId: 24,
      FeatureName: "Create New Schedule",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Settings",
    },
    {
      FeatureId: 25,
      FeatureName: "Edit Schedule",
      UserLevelId: 4,
      FeatureEnabledByDefault: true,
      Overridable: true,
      FeatureArea: "Settings",
    },
  ],
};

export { predefinedStructure, userStaticData, operatorStaticData };
