import { useState } from "react";

import { LeftOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { t } from "i18next";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";

import BackArrow from "../../assets/icons/BackArrow";

const propTypes = {
  redirect: PropTypes.object,
  confirmation: PropTypes.bool,
  className: PropTypes.string,
  confirmationMessage: PropTypes.string,
  confirmationTitleMessage: PropTypes.string,
};

const defaultProps = {
  redirect: {
    to: "",
  },
  confirmation: false,
  className: "mb-5 self-start uppercase tracking-widest",
  confirmationMessage: "",
  confirmationTitleMessage: "",
};

function BackButton({
  redirect,
  confirmation,
  className,
  confirmationMessage,
  confirmationTitleMessage,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const mainRoutes = [
    "/",
    "/monitoring",
    "/property-overview",
    "/statistics",
    "/reports",
    "/events",
    "/settings",
    "/preferences",
  ];
  const handleCancel = () => {
    setOpenConfirmModal(false);
  };

  const handleLeave = () => {
    navigate(redirect.to);
  };

  if (confirmation) {
    return (
      <>
        <Button
          className={className}
          type="link"
          onClick={() => {
            setOpenConfirmModal(true);
          }}
        >
          <LeftOutlined />
          {t("back_button.back")}
        </Button>
        <Modal
          open={openConfirmModal}
          title={confirmationTitleMessage}
          onOk={handleLeave}
          onCancel={handleCancel}
          footer={[
            <Button key="back" size="large" onClick={handleCancel}>
              {t("back_button.cancel")}
            </Button>,
            <Button
              key="submit"
              type="primary"
              size="large"
              onClick={handleLeave}
            >
              {t("back_button.leave")}
            </Button>,
          ]}
        >
          <p>{confirmationMessage}</p>
        </Modal>
      </>
    );
  }

  // only render in the child routes
  if (!mainRoutes.includes(location.pathname)) {
    return (
      <Button
        className={className}
        type="link"
        onClick={() => {
          navigate(redirect.to);
        }}
      >
        <BackArrow />
      </Button>
    );
  }

  // return a div acting like a placeholder to conform the padding
  return <div className="pl-12" />;
}

BackButton.propTypes = propTypes;
BackButton.defaultProps = defaultProps;

export default BackButton;
