import { useEffect, useRef, useState } from "react";

import { Button, Form, Input } from "antd";
import { t } from "i18next";
import PropTypes from "prop-types";

import useAssignMultipleDevicesToFloor from "../../../../../api/hooks/useAssignMultipleDevicesToFloor";
import usePropertyFloorById from "../../../../../api/hooks/usePropertyFloorById";
import useUpdatePropertyFloor from "../../../../../api/hooks/useUpdatePropertyFloor";
import { updateTransferList } from "../helperFuncs/helperFunctions";
import useHandlePropertyFloorUpdate from "../hooks/useHandlePropertyFloorUpdate";
import FloorSystems from "./FloorSystems";
import "../scss/FloorEdit.scss";

function FloorEdit({ floorId, setIsFormDirty, setEditFloorFormReference }) {
  const [isLoading, setIsLoading] = useState(false);
  const [disableSaveChanges, setDisableSaveChanges] = useState(true);
  const [editFormFloor] = Form.useForm();
  const initialFormValues = useRef({});

  const { data } = usePropertyFloorById(floorId, {
    enabled: true,
  });
  // Mutation Fns
  const assignMultipleDevicesToFloorMutation =
    useAssignMultipleDevicesToFloor();
  const updatePropertyFloorMutation = useUpdatePropertyFloor();

  useEffect(() => {
    if (data) {
      initialFormValues.current = {
        Name: data?.Name,
        Systems: { AssignedSystems: [], UnassignedSystems: [] },
      };
      editFormFloor.setFieldsValue(initialFormValues.current);
    }
  }, [editFormFloor, floorId, data]);

  useEffect(() => {
    setEditFloorFormReference(editFormFloor);
  }, [editFormFloor, setEditFloorFormReference]);

  const handleValuesCahnges = (changedValues) => {
    const isFormDirty = Object.keys(changedValues).length > 0;
    setIsFormDirty(isFormDirty);
    setDisableSaveChanges(false);
  };
  const floorSystemsRef = useRef();
  const HandleSubmit = async () => {
    setIsLoading(true);
    const formValues = editFormFloor.getFieldsValue();
    const {
      updatePropertyFloorResponse,
      assignMultipleDevicesToFloorResponse,
    } = await useHandlePropertyFloorUpdate(
      formValues,
      floorId,
      updatePropertyFloorMutation,
      assignMultipleDevicesToFloorMutation,
      setIsFormDirty,
      setIsLoading,
    );

    const updated = updateTransferList(
      assignMultipleDevicesToFloorResponse.data,
    );
    editFormFloor.setFieldsValue({
      Name: updatePropertyFloorResponse?.data?.Name,
      Systems: {
        AssignedSystems: updated.assignedSystems,
        UnassignedSystems: updated.unassignedSystems,
      },
    });

    floorSystemsRef.current.setDataSource(updated.allSystems);
    floorSystemsRef.current.setExpandedRowKeys(updated.allRowKeys);
    floorSystemsRef.current.setTargetKeys(updated.flattenTargetKeys);
    floorSystemsRef.current.setLeftTableData(updated.assignedSystems);
    floorSystemsRef.current.setRightTableData(updated.unassignedSystems);

    setDisableSaveChanges(true);
  };

  return (
    <div className="content-wrapper mx-2">
      <Form
        form={editFormFloor}
        name="floor-management-edit-floor"
        layout="vertical"
        className="w-full"
        initialValues={{
          Name: initialFormValues.current.Name,
          Systems: initialFormValues.current.Systems,
        }}
        onFinish={HandleSubmit}
        onValuesChange={handleValuesCahnges}
      >
        <div className="flex items-center justify-center mb-0">
          <h1 className="page-title">{t("edit_floor.edit_floor")}</h1>
        </div>
        <Form.Item name={[t("edit_floor.systems"), "AssignedSystems"]} hidden>
          <Input />
        </Form.Item>
        <Form.Item name={[t("edit_floor.systems"), "UnassignedSystems"]} hidden>
          <Input />
        </Form.Item>
        <FloorSystems
          editFloorFormReference={editFormFloor}
          floorId={floorId}
          setDisableSaveChanges={setDisableSaveChanges}
          setIsFormDirty={setIsFormDirty}
          floorSystemsRef={floorSystemsRef}
        />
        <Button
          type="primary"
          htmlType="submit"
          size="medium"
          className="custom-property-save-btn"
          loading={isLoading}
          disabled={disableSaveChanges}
        >
          {t("edit_floor.save")}
        </Button>
      </Form>
    </div>
  );
}

FloorEdit.propTypes = {
  floorId: PropTypes.number.isRequired,
  setIsFormDirty: PropTypes.func,
  setEditFloorFormReference: PropTypes.func,
};

FloorEdit.defaultProps = {
  setIsFormDirty: () => {},
  setEditFloorFormReference: () => {},
};

export default FloorEdit;
