import { useMutation, useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const RESOURCE_ENDPOINT = "UsersManagement/HomeAppUsers";

function useHomeAppUsers(params, options, key = "getHomeAppUsers") {
  const axiosPrivate = useAxiosPrivate();
  const getHomeAppUsers = async () => {
    const mobileAppUsers = await axiosPrivate({
      method: "GET",
      url: `${RESOURCE_ENDPOINT}/GetUsers`,
      params,
    }).then((response) => response.data);
    return mobileAppUsers;
  };

  return useQuery([key, params], () => getHomeAppUsers(params), {
    ...options,
  });
}

function useHomeAppUsersTotalCount(
  params,
  options,
  key = "getHomeAppUsersTotalCount",
) {
  const axiosPrivate = useAxiosPrivate();
  const getHomeAppUsersTotalCount = async () => {
    const mobileAppUsers = await axiosPrivate({
      method: "GET",
      url: `${RESOURCE_ENDPOINT}/TotalCount`,
      params,
    }).then((response) => response.data);
    return mobileAppUsers;
  };

  return useQuery([key, params], () => getHomeAppUsersTotalCount(params), {
    ...options,
  });
}

function useHomeAppUser(userId, options, key = "getHomeAppUser") {
  const axiosPrivate = useAxiosPrivate();
  const getHomeAppUser = async () => {
    const mobileAppUser = await axiosPrivate({
      method: "GET",
      url: `${RESOURCE_ENDPOINT}/${userId}/GetHomeAppUser`,
    }).then((response) => response.data);
    return mobileAppUser;
  };

  return useQuery([key, userId], () => getHomeAppUser(userId), {
    ...options,
  });
}

function useCreateHomeAppUser() {
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationFn: ({ request }) => {
      return axiosPrivate({
        method: "POST",
        url: `${RESOURCE_ENDPOINT}/Create`,
        data: request,
      });
    },
  });
}

function useUpdateHomeAppUser() {
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationFn: ({ userId, request }) => {
      return axiosPrivate({
        method: "PUT",
        url: `${RESOURCE_ENDPOINT}/${userId}/UpdateHomeAppUser`,
        data: request,
      });
    },
  });
}

function useDeleteHomeAppUser() {
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationFn: (userName) => {
      return axiosPrivate({
        method: "DELETE",
        url: `${RESOURCE_ENDPOINT}/DeleteHomeAppUser`,
        params: { userName },
      });
    },
  });
}

function useDeleteHomeAppUserZone() {
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationFn: (id) => {
      return axiosPrivate({
        method: "DELETE",
        url: `${RESOURCE_ENDPOINT}/${id}/DeleteHomeAppUserZone`,
      });
    },
  });
}

function useHomeAppUserZoneFilters(
  params,
  options,
  key = "getHomeAppUserZoneFilters",
) {
  const axiosPrivate = useAxiosPrivate();
  const getHomeAppUserZoneFilters = async () => {
    const homeAppUserZoneFilters = await axiosPrivate({
      method: "GET",
      url: `${RESOURCE_ENDPOINT}/GetHomeAppUserZoneFilters`,
    }).then((response) => response.data);
    return homeAppUserZoneFilters;
  };

  return useQuery([key, params], () => getHomeAppUserZoneFilters(params), {
    ...options,
  });
}

export {
  useHomeAppUsers,
  useHomeAppUsersTotalCount,
  useHomeAppUser,
  useCreateHomeAppUser,
  useUpdateHomeAppUser,
  useDeleteHomeAppUserZone,
  useDeleteHomeAppUser,
  useHomeAppUserZoneFilters,
};
