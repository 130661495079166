/* eslint-disable react/prop-types */
import { useState } from "react";

import {
  DeleteOutlined,
  FrownOutlined,
  QuestionCircleOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { Button, Divider, Modal, notification } from "antd";
import dayjs from "dayjs";
import { t } from "i18next";

import useScheduleDelete from "../../../api/hooks/useScheduleDelete";

function DeleteScene({ program }) {
  const [isOpen, setIsOpen] = useState(false);

  const startAtEpoch = program?.Intervals[0]?.Start;
  const startAt = dayjs(startAtEpoch * 1000);
  const duration = program?.Intervals[0]?.Duration || 0;
  const endAt = dayjs(startAt).add(duration, "minute");

  const queryClient = useQueryClient();
  const { mutate: deleteSchedule, isLoading } = useScheduleDelete();

  const handleDeleteScene = () => {
    deleteSchedule(program?.ScheduleProgramId, {
      onSuccess: () => {
        setIsOpen(false);
        notification.success({
          message: (
            <span className="uppercase tracking-widest">
              {t("delete.schedule_deleted")}
            </span>
          ),
          // description:, see error to determine description
          placement: "topRight",
          icon: <SmileOutlined className="text-triple-green" />,
        });

        queryClient.invalidateQueries((query) => {
          // Check if the query key contains "Schedules"
          return query.queryKey.some((key) => {
            return typeof key === "string" && key.includes("Schedules");
          });
        });
      },
      onError: () => {
        notification.error({
          message: (
            <span className="uppercase tracking-widest">
              {t("delete.something_went_wrong")}
            </span>
          ),
          // description:, see error to determine description
          placement: "topRight",
          icon: <FrownOutlined className="text-triple-red" />,
        });
      },
    });
  };

  return (
    <>
      <Button
        className="delete-scene"
        type="link"
        size="small"
        onClick={() => setIsOpen(true)}
      >
        <DeleteOutlined />
        {t("delete.delete")}
      </Button>
      <Modal
        open={isOpen}
        width={584}
        footer={[
          <Button
            disabled={isLoading}
            key="cancel-scene-delete"
            size="large"
            onClick={() => setIsOpen(false)}
          >
            {t("delete.cancel")}
          </Button>,
          <Button
            key="confirm-scene-delete"
            type="primary"
            size="large"
            loading={isLoading}
            onClick={handleDeleteScene}
          >
            {isLoading ? t("delete.deleting") : t("delete.delete")}
          </Button>,
        ]}
      >
        <div className="p-8 flex flex-col items-center">
          <QuestionCircleOutlined className="text-triple-blue text-3xl mb-4" />
          <h1 className="text-white uppercase tracking-widest text-xl font-light text-center">
            {t("delete.are_you_want_to_delete")}
          </h1>
        </div>

        <div className="pb-16 px-4">
          <h3 className="text-center mb-4">
            {program?.ScheduleProgramName || "Unnamed Schedule"}
          </h3>
          <div className="text-white text-opacity-80 text-center font-light px-4">
            <span>
              {t("schedule_calendar.property")} {program?.PropertyName || "-"}
            </span>
            <Divider type="vertical" />
            <span>
              {" "}
              {t("schedule_calendar.floor")} {program?.FloorName || "-"}
            </span>
            <Divider type="vertical" />
            <span>
              {t("schedule_calendar.device_type")} {program?.DeviceType || "-"}
            </span>
            <Divider type="vertical" />
            <span>
              {t("schedule_calendar.systems")} {program?.SystemName || "-"}
            </span>
            <Divider type="vertical" />
            <span>
              {t("schedule_calendar.scenes")}{" "}
              {program?.Intervals?.length || "-"}
            </span>
            <Divider type="vertical" />
            <span>
              {t("delete.active_day")}{" "}
              {program?.ActiveDays?.map((day) => {
                return `${day.substring(0, 3)}  `;
              }) || "-"}
            </span>
            <Divider type="vertical" />
            <span>
              {t("events_filter.time")}{" "}
              {`${dayjs(startAt).format(
                t("time_picker.hh_mm"),
              )} - ${endAt.format(t("time_picker.hh_mm"))}`}
            </span>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default DeleteScene;
