import { InfoCircleOutlined } from "@ant-design/icons";
import { t } from "i18next";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";

import buildEventTitle from "../../../helpers/buildEventTitle";
import useBasePath from "../../../hooks/useBasePath";
import CustomTable from "../../CustomTable/CustomTable";
import EventIcon from "../EventIcon";
import EventsLogTableHeader from "./EventsLogTableHeader";

const propTypes = {
  data: PropTypes.array,
  status: PropTypes.string,
  pageSize: PropTypes.number,
  totalCount: PropTypes.number,
  withHeader: PropTypes.bool,
  withSidebar: PropTypes.bool,
  onChange: PropTypes.func,
};

const defaultProps = {
  data: [],
  status: "loading",
  pageSize: 3,
  totalCount: 0,
  withHeader: false,
  withSidebar: false,
  onChange: () => {},
};

function EventsLogTable({
  data,
  status,
  pageSize,
  totalCount,
  withHeader,
  withSidebar,
  onChange,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const basePath = useBasePath();

  const columns = [
    {
      title: (
        <div className="text-center">{t("floor_system_tables.status")}</div>
      ),
      key: "status",
      render: (record) => {
        return (
          <div className=" flex items-center">
            {record?.LogsInfo?.State && (
              <EventIcon
                key={record?.LogsInfo?.Id}
                iconName={record?.LogsInfo?.DeviceTypePublicName}
                className={`${record?.LogsInfo?.State.toLowerCase()}-event`}
                defaultIcon={<InfoCircleOutlined className="text-white" />}
              />
            )}

            <span className="pl-2">
              {t(`state.${record?.LogsInfo?.State}`)}
            </span>
          </div>
        );
      },
      width: 150,
    },
    {
      title: t("event_details.event"),
      key: "event",
      render: (record) => {
        const t1 = buildEventTitle(record?.EventTitle);
        return <span>{t(`${t1}`)}</span>;
      },
      width: 400,
    },
    {
      title: t("event_details.record"),
      key: "record",
      render: (record) => (
        <span>
          {record?.LogsInfo?.RecordType
            ? t(`record_type.${record?.LogsInfo?.RecordType}`)
            : "-"}
        </span>
      ),
      width: 125,
    },
    {
      title: t("event_details.type"),
      key: "type",
      render: (record) => (
        <span>
          {record?.LogsInfo?.DeviceTypePublicName
            ? t(
                `device_type_public_name.${record?.LogsInfo?.DeviceTypePublicName}`,
              )
            : "-"}
        </span>
      ),
      width: 150,
    },
    {
      title: t("event_details.owner"),
      key: "owner",
      render: (record) => <span>{record?.LogsInfo?.Owner || "-"}</span>,
      width: 150,
    },
    {
      title: t("event_details.system"),
      key: "system",
      render: (record) => <span>{record?.LogsInfo?.SystemName || "-"}</span>,
      width: 150,
    },
    {
      title: t("event_details.system_id"),
      key: "systemId",
      render: (record) => <span>{record?.LogsInfo?.SystemId || "-"}</span>,
      width: 150,
    },
    {
      title: t("event_details.serial_number"),
      key: "serialNumber",
      render: (record) => (
        <span>{record?.LogsInfo?.DeviceSerialNumber || "-"}</span>
      ),
      width: 200,
    },
    {
      title: t("event_details.time"),
      key: "time",
      render: (record) => (
        <span>
          {new Date(record?.LogsInfo?.DateTime).toLocaleString() || "-"}
        </span>
      ),
      width: 200,
    },
    {
      title: t("event_details.location"),
      key: "location",
      render: (record) => <span>{record?.LogsInfo?.Location || "-"}</span>,
      width: 125,
    },
    {
      title: t("event_details.property"),
      key: "property",
      render: (record) => <span>{record?.LogsInfo?.PropertyName || "-"}</span>,
      width: 175,
    },
    {
      title: t("event_details.floor"),
      key: "floor",
      render: (record) => (
        <span>{record?.LogsInfo?.PropertyFloorName || "-"}</span>
      ),
      width: 100,
    },
  ];

  const toggableColumns = columns?.filter(
    (column) =>
      column.key !== "event" &&
      column.key !== "time" &&
      column.key !== "status" &&
      column.key !== "serialNumber",
  );

  return (
    <CustomTable
      className={`${!pageSize ? "opacity-0" : "opacity:100"}`}
      withSidebar={withSidebar}
      data={data}
      status={status}
      autoRows
      noPagination={!(totalCount > pageSize) || status === "loading"}
      sidebarColumns={toggableColumns}
      tableColumns={columns}
      tableHeader={<EventsLogTableHeader />}
      totalCount={totalCount}
      withHeader={withHeader}
      onChange={onChange}
      onRowClick={(e, record) => {
        const eventId = record?.LogsInfo.Id;
        // don't navigate to event details if user is selecting text from the table
        if (document.getSelection().type !== "Range") {
          navigate({
            pathname: basePath === "/" ? `/event/${eventId}` : `${eventId}`,
            search: location.search,
          });
        }
      }}
    />
  );
}

EventsLogTable.propTypes = propTypes;
EventsLogTable.defaultProps = defaultProps;

export default EventsLogTable;
