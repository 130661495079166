import { t } from "i18next";

import {
  FloorManagementIcon,
  FlowManagementIcon,
  MobileUsersIcon,
  PropertyPreferencesIcon,
  ScheduleManagementIcon,
  // ZonesManagementIcon,
} from "../../../assets/icons/preferences-tiles-icons";
import useWindowSize from "../../../hooks/useWindowSize";
import MenuTile from "../../Shared/MenuTile";

function PropertyPreferencesMenuPage() {
  const window = useWindowSize();

  return (
    <>
      <div className="flex md:items-center flex-col-reverse md:flex-row mt-1">
        {window?.width > 768 && (
          <h1 className="page-title text-center mb-0 sm:text-left !text-triple-blue">
            {t("property_management_menu.property_management")}
          </h1>
        )}

        {window?.width <= 768 && (
          <h4 className="page-title-mobile mb-0 text-center sm:text-left uppercase !text-triple-blue">
            {t("property_management_menu.property_management")}
          </h4>
        )}
      </div>
      <div className="flex md:flex-row mt-2 flex-col md:items-start items-center gap-5">
        <MenuTile
          title={t("property_management_menu.property")}
          icon={<PropertyPreferencesIcon />}
          navigateTo="/preferences/property-management/properties"
        />
        <MenuTile
          title={t("property_management_menu.floor_management")}
          icon={<FloorManagementIcon />}
          navigateTo="/preferences/property-management/floor-management/properties"
        />
        <MenuTile
          title={t("property_management_menu.monitoring")}
          icon={<MobileUsersIcon />}
          navigateTo="/preferences/property-management/mobile-app/users"
        />
        {/* <MenuTile
          title={t("property_management_menu.zone_management")}
          icon={<ZonesManagementIcon />}
          navigateTo="/preferences/property-management/zone-management/properties"
        /> */}
        <MenuTile
          title={t("property_management_menu.flow")}
          icon={<FlowManagementIcon />}
          navigateTo="/settings/flow-configuration"
        />
        <MenuTile
          title={t("property_management_menu.schedule")}
          icon={<ScheduleManagementIcon />}
          navigateTo="/settings/schedule"
        />
      </div>
    </>
  );
}
export default PropertyPreferencesMenuPage;
