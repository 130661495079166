import { InfoCircleOutlined } from "@ant-design/icons";
import { Tree } from "antd";
import { t } from "i18next";

import { HubIcon } from "../../../assets/icons/systems";
import useScheduleSystems from "../../../hooks/useScheduleSystems";
import EventIcon from "../../Events/EventIcon";

// eslint-disable-next-line react/prop-types
function SummaryTree({ className = "" }) {
  const { scheduleSystems } = useScheduleSystems();

  const formattedData =
    scheduleSystems.selectedSystemsWithSelectedDevice?.length > 0
      ? [...scheduleSystems.selectedSystemsWithSelectedDevice].map(
          (system, indexSystem) => {
            return {
              title: (
                <span className="flex items-center">
                  <HubIcon className="mx-2" />
                  {system.Name}
                </span>
              ),
              key: `0-${indexSystem}`,
              children: system?.Devices.map((device, indexDevice) => {
                return {
                  title: (
                    <span
                      className="flex items-center"
                      data-deviceid={device.UniqueId}
                      data-devicetypepublicname={device.DeviceTypePublicName}
                    >
                      <EventIcon
                        key={device?.SerialNumber}
                        iconName={device?.DeviceTypePublicName}
                        className="mx-2"
                        defaultIcon={
                          <InfoCircleOutlined className="text-white mx-2" />
                        }
                      />
                      {device?.DeviceTypePublicName} ({device?.SerialNumber})
                    </span>
                  ),
                  key: `0-${indexSystem}-${indexDevice}`,
                };
              }),
            };
          },
        )
      : [];

  const summarySystems = formattedData
    ?.map((el) => {
      const summarySystem = {};

      if (
        el.children.some((el) => {
          return scheduleSystems.selectedDevicesUniqueIds?.includes(
            el.title?.props["data-deviceid"],
          );
        })
      ) {
        summarySystem.title = el?.title?.props?.children[1];
        summarySystem.children = el.children.filter((el) =>
          scheduleSystems.selectedDevicesUniqueIds?.includes(
            el.title?.props["data-deviceid"],
          ),
        );
      }

      return summarySystem;
    })
    .flat()
    .filter((system) => system?.title);

  const formattedSummarySystems = summarySystems?.map((el, indexEl) => {
    return {
      title: (
        <span className="flex items-center">
          <HubIcon className="mx-2" />
          {el.title}
        </span>
      ),
      key: `0-${indexEl}`,
      children: el?.children?.map((device, indexDevice) => {
        return {
          title: (
            <span className="flex items-center">
              <EventIcon
                key={device?.title?.props["data-deviceid"]}
                iconName={device?.title?.props["data-devicetypepublicname"]}
                className="mx-2"
                defaultIcon={<InfoCircleOutlined className="text-white mx-2" />}
              />
              {device?.title.props.children[1]} (
              {device?.title.props.children[3]})
            </span>
          ),
          key: `0-${indexEl}-${indexDevice}`,
        };
      }),
    };
  });

  return (
    <div className={`schedule-tree ${className}`}>
      <h3 className="text-triple-white uppercase tracking-widest font-light">
        {t("manage_property.summary")}
      </h3>

      {formattedSummarySystems?.length > 0 && (
        <Tree
          style={{ background: "#303343" }}
          className="bg-[#303343]"
          defaultExpandAll
          showLine
          treeData={formattedSummarySystems}
        />
      )}

      {(!formattedSummarySystems || formattedSummarySystems?.length === 0) && (
        <div className="flex h-full items-center justify-center text-triple-white font-light text-sm">
          <InfoCircleOutlined className="mr-2" />
          {scheduleSystems.systemsWithSelectedDevice?.length > 0
            ? t("manage_property.choose_system_device")
            : t("manage_property.choose_property_device")}
        </div>
      )}
    </div>
  );
}

export default SummaryTree;
