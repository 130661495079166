function MobileUsersIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="-35 -15 150 150"
      width="100"
      height="100"
    >
      <path
        fillRule="evenodd"
        fill="#757782"
        d="M87.892 124.731V76.482c0-2.668-1.684-6.278-3.93-8.402L72.028 56.835l.023 17.413 8.628 8.964-.17.819c-.207.979-2.99 3.42-3.198 3.53-1.293.656-1.257.582-17.257-15.296-2.172-1.664-4.686-.953-6.052.478-1.367 1.426-1.977 3.941.377 6.724 1.929 2.276 4.309 4.503 6.615 6.657 2.161 2.019 4.381 4.099 6.31 6.278 1.965 2.22.207 4.814-.843 6.369-.267.41-.536.795-.719 1.15-3.783 7.385-5.43 14.959-5.015 23.164l.085 1.646H11.056c-2.343 0-4.833-1.211-6.834-3.322-2.161-2.276-3.381-5.17-3.259-7.734L.988 11.064C1.293 5.697 5.589 1.108 10.775.527L62.41.515l.159.03c5.077 1.047 8.591 4.553 9.385 9.392l.055 37.231 17.75 16.979c3.137 3.523 4.748 7.641 5.028 12.683v47.901h-6.895zM7.858 102.747h43.763v6.902H7.858v4.754c0 1.182 2.234 3.421 3.417 3.421h42.617c.379-8.186 2.648-15.926 6.602-22.541-1.586-1.72-3.368-3.39-5.101-5.016-2.148-2.027-4.369-4.113-6.274-6.327-4.856-5.679-3.66-11.968-.328-15.682 3.283-3.64 9.506-5.684 15.352-1.603.33.232.659.502.976.79V18.723H7.858v84.024zM64.179 8.903c-.732-.887-1.818-1.394-3.257-1.505l-49.402.043c-2.514.299-3.552 2.276-3.698 4.381H65.18c-.061-1.176-.402-2.184-1.001-2.919zm-45.728 44.9c0-6.14 4.98-11.132 11.1-11.132h2.899a8.733 8.733 0 0 1-4.657-7.719c0-4.806 3.9-8.719 8.696-8.719 4.796 0 8.695 3.913 8.695 8.719a8.732 8.732 0 0 1-4.656 7.719h2.905c6.12 0 11.1 4.992 11.1 11.132v3.127H18.451v-3.127zm23.285-18.851c0-2.9-2.354-5.258-5.247-5.258-2.892 0-5.248 2.358-5.248 5.258 0 2.901 2.356 5.263 5.248 5.263 2.893 0 5.247-2.362 5.247-5.263zm9.337 18.517c-.171-4.076-3.533-7.339-7.64-7.339H29.551c-4.107 0-7.469 3.263-7.646 7.339h29.168z"
      />
    </svg>
  );
}
export default MobileUsersIcon;
