import { Row, Button } from "antd";
import { t } from "i18next";
import PropTypes from "prop-types";

import "./StatisticsConsumptionCards.scss";

function StatisticsConsumptionCards({
  showPreviousWaterConsumptionData,
  onToggle,
}) {
  return (
    <Row>
      <div className="chart-type">
        <Button
          className="stats-button mt-1 mx-1"
          size="small"
          type="primary"
          onClick={onToggle}
        >
          {showPreviousWaterConsumptionData
            ? t("statistics_filters.hide_last_year")
            : t("statistics_filters.show_last_year")}
        </Button>
      </div>
    </Row>
  );
}

StatisticsConsumptionCards.defaultProps = {
  showPreviousWaterConsumptionData: false,
  onToggle: () => {},
};

StatisticsConsumptionCards.propTypes = {
  showPreviousWaterConsumptionData: PropTypes.bool,
  onToggle: PropTypes.func,
};

export default StatisticsConsumptionCards;
