import { useEffect, useState } from "react";

import { t } from "i18next";
import PropTypes from "prop-types";

import usePropertyById from "../../../api/hooks/usePropertyById";
import "./StatisticsPropertyOverview.scss";
import { isNullOrEmpty } from "../../../helpers/jsLinq";
import Loader from "../../Loader/Loader";

function StatisticsPropertyOverview({ propertyId }) {
  const [isLoading, setIsLoading] = useState(false);

  const {
    data: property,
    status,
    refetch: getPropertyById,
  } = usePropertyById(propertyId, {
    enabled: false,
    cacheTime: 0,
  });

  useEffect(() => {
    if (propertyId !== "all" && !isNullOrEmpty(propertyId)) {
      setIsLoading(true);
      getPropertyById(propertyId).then(() => {
        setIsLoading(false);
      });
    }
  }, [propertyId, getPropertyById]);

  return (
    <div className="statistics-property-overview">
      <div className="statistics-property-details pb-2">
        <h1
          className="statistics-property-title page-title"
          title={property?.Name}
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          {status === "loading" && isLoading === true ? (
            <Loader />
          ) : propertyId && propertyId !== "all" ? (
            property?.Name
          ) : (
            t("statistics_filters.all")
          )}
        </h1>
        <h3
          className="statistics-property-address truncate"
          title={property?.Address}
        >
          {property?.Address}
        </h3>
      </div>
    </div>
  );
}
StatisticsPropertyOverview.defaultProps = {
  propertyId: PropTypes.any,
};
StatisticsPropertyOverview.propTypes = {
  propertyId: PropTypes.any,
};
export default StatisticsPropertyOverview;
