import { Outlet } from "react-router-dom";

import RequireAdmin from "./auth/RequireAdmin";
import RequireLicense from "./auth/RequireLicense";
import AlarmDetails from "./components/Dashboard/Alarms/AlarmDetails/AlarmDetails";
import AlarmModal from "./components/Dashboard/Alarms/AlarmModal/AlarmModal";
import Dashboard from "./components/Dashboard/Dashboard";
import EventDetails from "./components/Events/EventDetails/EventDetails";
import Events from "./components/Events/Events";
import EventsFilter from "./components/Events/EventsFilter/EventsFilter";
import CreateFlowConfiguration from "./components/FdsSchedulesList/CreateFlowConfiguration/CreateFlowConfiguration";
import FDSSchedulesList from "./components/FdsSchedulesList/FdsSchedulesList";
import Monitoring from "./components/Monitoring/Monitoring";
import {
  PreferencesMenuPage,
  PropertyManagementMenuPage,
  PropertyManagement,
  FloorManagementFloorList,
  FloorManagementPropertyList,
  MobileAppUsers,
  // ZoneManagementPropertyList,
  UsersList,
} from "./components/Preferences/module";
import Profile from "./components/Profile/Profile";
import {
  FloorList,
  FloorSystemDeviceDetails,
  FloorSystemsTableMobile,
  PropertyList,
} from "./components/PropertyOverview/module";
import {
  CustomerReport,
  PreferencesConsumptionReportsMenuPage,
  PreferencesReportsMenu,
  ReportsMenu,
  StatusReport,
  WaterConsumptionReport,
} from "./components/Reports/module";
import CreateNewSchedule from "./components/Schedule/CreateNewSchedule/CreateNewSchedule";
import Schedule from "./components/Schedule/Schedule";
import ScheduleCalendar from "./components/Schedule/ScheduleCalendar/ScheduleCalendar";
import SettingsMenuPage from "./components/Settings/SettingsMenuPage";
import Charts from "./components/Statistics/Charts/Charts";
import { FdsFiltersValuesProvider } from "./context/FdsFiltersValuesProvider";
import { ScheduleSystemsProvider } from "./context/ScheduleSystemsProvider";

const children = [
  {
    path: "",
    element: (
      <RequireLicense featureName="Dashboard Default View">
        <div className="content-wrapper no-scrollbar">
          <Dashboard />
          <AlarmModal />
          <Outlet />
        </div>
      </RequireLicense>
    ),
    children: [
      {
        path: "alarm/:alarmId",
        element: <AlarmDetails />,
      },
      {
        path: "event/:eventId",
        element: <EventDetails />,
      },
    ],
  },
  {
    path: "monitoring",
    element: (
      <RequireLicense featureName="Monitoring">
        <div className="content-wrapper overflow-y-auto">
          <Monitoring />
        </div>
      </RequireLicense>
    ),
  },
  {
    path: "property-overview",
    children: [
      {
        path: "",
        element: (
          <RequireLicense featureName="Property Overview Default View">
            <div className="content-wrapper overflow-y-auto">
              <PropertyList />
            </div>
          </RequireLicense>
        ),
      },
      {
        path: "property/:propertyId",
        element: (
          <div className="content-wrapper overflow-y-auto">
            <FloorList />
            <Outlet />
          </div>
        ),
        children: [
          {
            // mobile view for floor systems
            path: "floor/:floorId",
            element: <FloorSystemsTableMobile />,
          },
          {
            path: "floor/device-details/:deviceId",
            element: <FloorSystemDeviceDetails />,
          },
          // mobile view for system device details
          {
            path: "floor/:floorId/device-details/:deviceId",
            element: <FloorSystemDeviceDetails />,
          },
        ],
      },
    ],
  },
  {
    path: "statistics",
    children: [
      {
        path: "",
        element: (
          <RequireLicense featureName="Yearly Graph">
            <div className="content-wrapper text-triple-white">
              <Charts />
            </div>
          </RequireLicense>
        ),
      },
    ],
  },
  {
    path: "reports",
    children: [
      {
        path: "",
        element: (
          <div className="content-wrapper text-triple-white">
            <RequireLicense featureName="Water Consumption - Table View">
              <ReportsMenu />
            </RequireLicense>
          </div>
        ),
      },
      {
        path: "consumption",
        children: [
          {
            path: "water",
            element: (
              <RequireLicense featureName="Water Consumption - Graph View">
                <div className="content-wrapper text-triple-white">
                  <WaterConsumptionReport />
                </div>
              </RequireLicense>
            ),
          },
        ],
      },
      {
        path: "status",
        element: (
          <RequireLicense featureName="Systems Real Time Status Report">
            <div className="content-wrapper text-triple-white">
              <StatusReport />
            </div>
          </RequireLicense>
        ),
      },
    ],
  },
  {
    path: "events",
    children: [
      {
        path: "",
        element: (
          <RequireLicense featureName="Events Default View">
            <div className="content-wrapper no-scrollbar">
              <Events />
              <Outlet />
            </div>
          </RequireLicense>
        ),
        children: [
          {
            path: ":eventId",
            element: <EventDetails />,
          },
          {
            path: "filters",
            element: <EventsFilter />,
          },
        ],
      },
    ],
  },
  {
    path: "",
    children: [
      {
        path: "settings",
        element: (
          <div className="content-wrapper text-triple-white">
            <SettingsMenuPage />
          </div>
        ),
      },
      {
        path: "/settings/flow-configuration",
        element: (
          <div className="content-wrapper">
            <RequireLicense featureName="Flow Configuration Default View">
              <FDSSchedulesList />
            </RequireLicense>
          </div>
        ),
      },
      {
        path: "/settings/flow-configuration/create-flow-configuration",
        element: (
          <div className="content-wrapper overflow-y-auto">
            <RequireLicense featureName="Create New FC">
              <FdsFiltersValuesProvider>
                <CreateFlowConfiguration />
              </FdsFiltersValuesProvider>
            </RequireLicense>
          </div>
        ),
      },
      {
        path: "/settings/schedule",
        element: (
          <div className="content-wrapper overflow-auto">
            <RequireLicense featureName="Schedule Default View">
              <Schedule />
            </RequireLicense>
          </div>
        ),
      },
      {
        path: "/settings/schedule/create-schedule",
        element: (
          <div className="content-wrapper overflow-y-auto">
            <RequireLicense featureName="Create New Schedule">
              <ScheduleSystemsProvider>
                <CreateNewSchedule />
              </ScheduleSystemsProvider>
            </RequireLicense>
          </div>
        ),
      },
      {
        path: "/settings/schedule/manage-schedule-calendar",
        element: (
          <div className="content-wrapper overflow-y-auto">
            <RequireLicense featureName="Edit Schedule">
              <ScheduleSystemsProvider>
                <ScheduleCalendar />
              </ScheduleSystemsProvider>
            </RequireLicense>
          </div>
        ),
      },
    ],
  },
  {
    path: "preferences",
    children: [
      {
        path: "",
        element: (
          <div className="content-wrapper text-triple-white">
            <RequireLicense featureName="Preferences Default View">
              <RequireAdmin>
                <PreferencesMenuPage />
              </RequireAdmin>
            </RequireLicense>
          </div>
        ),
      },
      {
        path: "/preferences/property-management",
        element: (
          <div className="content-wrapper text-triple-white">
            <RequireLicense featureName="Property Management Default View">
              <RequireAdmin>
                <PropertyManagementMenuPage />
              </RequireAdmin>
            </RequireLicense>
          </div>
        ),
      },
      {
        path: "/preferences/property-management/properties",
        element: (
          <RequireLicense featureName="Property Management Default View">
            <RequireAdmin>
              <PropertyManagement />
            </RequireAdmin>
          </RequireLicense>
        ),
      },
      {
        path: "/preferences/property-management/floor-management/properties",
        element: (
          <RequireLicense featureName="Property Management Default View">
            <RequireAdmin>
              <FloorManagementPropertyList />
            </RequireAdmin>
          </RequireLicense>
        ),
      },
      {
        path: "/preferences/property-management/floor-management/property/:propertyId",
        element: (
          <RequireLicense featureName="Property Management Default View">
            <RequireAdmin>
              <FloorManagementFloorList />
            </RequireAdmin>
          </RequireLicense>
        ),
      },
      {
        path: "/preferences/property-management/mobile-app/users",
        element: (
          <RequireLicense featureName="Property Management Default View">
            <RequireAdmin>
              <MobileAppUsers />
            </RequireAdmin>
          </RequireLicense>
        ),
      },
      // {
      //   path: "/preferences/property-management/zone-management/properties",
      //   element: (
      //     <RequireLicense featureName="Property Management Default View">
      //       <RequireAdmin>
      //         <ZoneManagementPropertyList />
      //       </RequireAdmin>
      //     </RequireLicense>
      //   ),
      // },
      {
        path: "/preferences/users",
        element: (
          <RequireLicense featureName="Users List Default View">
            <RequireAdmin>
              <UsersList />
            </RequireAdmin>
          </RequireLicense>
        ),
      },
      {
        path: "/preferences/reports",
        children: [
          {
            path: "",
            children: [
              {
                path: "",
                element: (
                  <div className="content-wrapper text-triple-white">
                    <RequireLicense featureName="Generate Report">
                      <RequireAdmin>
                        <PreferencesReportsMenu />
                      </RequireAdmin>
                    </RequireLicense>
                  </div>
                ),
              },
              {
                path: "consumption",
                children: [
                  {
                    path: "",
                    element: (
                      <RequireLicense featureName="Generate Report">
                        <div className="content-wrapper text-triple-white">
                          <PreferencesConsumptionReportsMenuPage />
                        </div>
                      </RequireLicense>
                    ),
                  },
                  {
                    path: "water",
                    element: (
                      <RequireLicense featureName="Generate Report">
                        <div className="content-wrapper text-triple-white">
                          <WaterConsumptionReport />
                        </div>
                      </RequireLicense>
                    ),
                  },
                  {
                    path: "customer",
                    element: (
                      <RequireLicense featureName="Generate Report">
                        <div className="content-wrapper text-triple-white">
                          <CustomerReport />
                        </div>
                      </RequireLicense>
                    ),
                  },
                ],
              },
              {
                path: "status",
                element: (
                  <RequireLicense featureName="Generate Report">
                    <div className="content-wrapper text-triple-white">
                      <StatusReport />
                    </div>
                  </RequireLicense>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "profile",
    children: [
      {
        path: "",
        element: <Profile />,
      },
    ],
  },
];

export default children;
