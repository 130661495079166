import { WarningFilled, RightOutlined, SmileOutlined } from "@ant-design/icons";
import { Card, Carousel, Button, Divider, Skeleton } from "antd";
import { t } from "i18next";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import useRecentStatuses from "../../../api/hooks/useRecentStatuses";
import DeviceInAlarmIcon from "../../../assets/icons/DeviceInAlarmIcon";
import FloorIcon from "../../../assets/icons/FloorIcon";
import { HubIcon } from "../../../assets/icons/systems";

import "./Alarms.scss";

function Alarms({ className }) {
  const navigate = useNavigate();

  const { data: recentStatuses, status } = useRecentStatuses(
    "?systemStatuses[0]=Alert",
  );

  const alarmHTML = () => (
    <Carousel className="w-full h-auto text-[#888a93]">
      {recentStatuses?.map((recentStatus, index) => (
        <div key={recentStatus?.DeviceStatusKey}>
          <div className="alarm">
            <div className="alarm-details">
              <div className="alarm-title">
                <WarningFilled className="alarm-icon" />
                <div className="alarm-text">
                  {recentStatus.AlertName || "Unknown"} (
                  {new Date(recentStatus.LastModified).toLocaleTimeString(
                    "en-US",
                    {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                    },
                  )}
                  )
                </div>
              </div>
              <div className="alarm-location">
                <div className="flex items-center mb-2 sm:mb-0">
                  {recentStatus.PropertyName && (
                    <>
                      <span>{recentStatus.PropertyName}</span>
                      <Divider type="vertical" />
                    </>
                  )}
                  {recentStatus.FloorName && (
                    <>
                      <span className="flex items-center">
                        <FloorIcon className="mr-1 fill-red-300" />{" "}
                        {recentStatus.FloorName}
                      </span>
                      <Divider type="vertical" />
                    </>
                  )}
                </div>
                <div className="flex items-center">
                  {recentStatus.SystemName && (
                    <>
                      <span className="flex items-center">
                        <HubIcon className="mr-2" />
                        {recentStatus.SystemName}
                      </span>
                      <Divider type="vertical" />
                    </>
                  )}
                  {recentStatus.DeviceTypeName && (
                    <span className="flex items-center">
                      <DeviceInAlarmIcon className="mr-1" />
                      {recentStatus.DeviceTypeName}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="alarm-message">
              {t("alarm.status_change_from")}{" "}
              <span className=" uppercase">
                {recentStatus.PreviousStatus?.split(/(?=[A-Z])/).join(" ") ||
                  "-"}
              </span>
              &nbsp;{t("alarm.to")}&nbsp;
              <span className="uppercase">
                {recentStatus.Status?.split(/(?=[A-Z])/).join(" ") || "-"}
              </span>
            </div>

            <Button
              type="link"
              className="alarm-action"
              onClick={() => {
                navigate(`alarm/${index}`);
              }}
            >
              {t("alarm.more_info")} <RightOutlined />
            </Button>
          </div>
        </div>
      ))}
    </Carousel>
  );

  return (
    <Card size="small" className={`alarms ${className}`}>
      {status === "loading" && (
        <Skeleton
          active
          paragraph={{
            rows: 1,
          }}
        />
      )}

      {status === "success" && recentStatuses?.length > 0 && alarmHTML()}

      {status === "success" && !recentStatuses?.length && (
        <div className="p-2">
          <SmileOutlined className="text-lg mr-1" /> {t("units.water_measure")}
        </div>
      )}
    </Card>
  );
}

Alarms.propTypes = {
  className: PropTypes.string,
};

Alarms.defaultProps = {
  className: "",
};

export default Alarms;
