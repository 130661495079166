/* eslint-disable no-unused-vars */
import { t } from "i18next";

import { isNullOrEmpty } from "./jsLinq";

const getEventTitle = (keyWord) => {
  let translatedKeyword;
  if (!isNullOrEmpty(keyWord)) {
    switch (keyWord) {
      case "Status":
        translatedKeyword = t("build_event_title.status");
        break;
      case "Offline":
        translatedKeyword = t("build_event_title.offline");
        break;
      case "Closed":
        translatedKeyword = t("build_event_title.closed");
        break;
      case "System":
        translatedKeyword = t("build_event_title.system");
        break;
      case "ConfigurationApproved":
        translatedKeyword = t("build_event_title.configuration_approved");
        break;
      case "Protect":
        translatedKeyword = t("build_event_title.protect");
        break;
      case "OK":
        translatedKeyword = t("build_event_title.ok");
        break;
      default:
        translatedKeyword = keyWord;
        break;
    }
  } else {
    translatedKeyword = keyWord;
  }
  return translatedKeyword;
};

const buildEventTitle = (eventTitle) => {
  const wordMap = {
    ChangedFrom: t("build_event_title.change_from"),
    ChangedTo: t("build_event_title.changed_to"),
    SentBy: t("build_event_title.sent_by"),
  };

  if (eventTitle?.length > 0) {
    const newArr = [];
    for (let i = 0; i < eventTitle.length; i += 1) {
      let keyWord = eventTitle[i];
      keyWord = getEventTitle(keyWord);
      if (keyWord === t("build_event_title.configuration")) {
        keyWord = t("build_event_title.add_remove_devices");
      }

      if (wordMap[keyWord]?.length > 1) {
        newArr.push(wordMap[keyWord]);
      } else {
        newArr.push(
          keyWord?.replace(/([A-Z][a-z])/g, " $1").replace("/ ", "/"),
        );
      }
    }

    return newArr?.join("")?.replace(/ /g, " ");
  }

  return "";
};

export default buildEventTitle;
