import dayjs from "dayjs";
import { t } from "i18next";
import PropTypes from "prop-types";

function StatisticsCustomTooltip({ active, payload, chartType, waterUnit }) {
  let unitOfMeasure;
  switch (waterUnit) {
    case "Gallons":
      unitOfMeasure = "gal";
      break;
    case "Litres":
    default:
      unitOfMeasure = "m3";
      break;
  }

  const textContent = (fill, name, value) => {
    return (
      <p
        className="px-1 my-1"
        style={{ color: `${fill}` }}
      >{`${name} : ${value} ${unitOfMeasure}`}</p>
    );
  };

  const tooltipContent = (payload) => {
    if (payload.length > 1) {
      return (
        <div className="flex-col inline-flex">
          {textContent(payload[0].fill, payload[0].name, payload[0].value)}
          {textContent(payload[1].fill, payload[1].name, payload[1].value)}
        </div>
      );
    }

    return (
      <div className="inline-flex">
        {textContent(payload[0].fill, payload[0].name, payload[0].value)}
      </div>
    );
  };

  const payloadContent = (payload, key) => {
    return (
      <div className="border border-solid rounded bg-triple-white text-triple-background border-sky-500">
        <p className="px-2 my-1 text-center">{key}</p>
        <div className="px-2 my-1">{tooltipContent(payload)}</div>
      </div>
    );
  };

  if (active && payload && payload.length) {
    if (chartType === "Annual") {
      return payloadContent(payload, payload[0].payload.Key);
    }

    if (chartType === "Monthly") {
      return payloadContent(payload, `Day ${payload[0].payload.Key}`);
    }

    if (chartType === "Daily") {
      return payloadContent(
        payload,
        `Hour ${dayjs()
          .hour(payload[0].payload.Key)
          .minute(0)
          .format(t("time_picker.hh_mm"))}`,
      );
    }
  }

  return null;
}

StatisticsCustomTooltip.defaultProps = {
  active: false,
  payload: [],
  chartType: PropTypes.string,
  waterUnit: "",
};

StatisticsCustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  chartType: PropTypes.string,
  waterUnit: PropTypes.string,
};
export default StatisticsCustomTooltip;
