import { Button, Modal } from "antd";
import { t } from "i18next";
import PropTypes from "prop-types";
import "./scss/UnsavedChangesWarning.scss";

function UnsavedChangesWarning({
  onSaveClick,
  onDontSaveClick,
  onCancelClick,
  isAlertUnsavedChangesOpen,
}) {
  return (
    <Modal
      className="alert-unsaved-changes"
      maskClosable={false}
      closable={false}
      zIndex={9999}
      footer={
        <>
          <Button size="small" onClick={onSaveClick}>
            {t("users.save")}
          </Button>
          <Button
            size="small"
            className="btn-leave"
            danger
            onClick={onDontSaveClick}
          >
            {t("users.donot_save")}
          </Button>
          <Button size="small" onClick={onCancelClick}>
            {t("delete_scene.cancel")}
          </Button>
        </>
      }
      open={isAlertUnsavedChangesOpen}
      keyboard={false}
      destroyOnClose
    >
      <div className="text-center">
        <h4 className="uppercase">{t("users.unsaved_changes")}</h4>
        <p>{t("users.changes_msg")}</p>
      </div>
    </Modal>
  );
}

UnsavedChangesWarning.propTypes = {
  onSaveClick: PropTypes.func,
  onDontSaveClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  isAlertUnsavedChangesOpen: PropTypes.bool,
};

UnsavedChangesWarning.defaultProps = {
  onSaveClick: () => {},
  onDontSaveClick: () => {},
  onCancelClick: () => {},
  isAlertUnsavedChangesOpen: false,
};

export default UnsavedChangesWarning;
