import { useEffect, useState } from "react";

import { PlusOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import Search from "antd/es/input/Search";
import { useSearchParams } from "react-router-dom";

import {
  useHomeAppUsers,
  useHomeAppUsersTotalCount,
} from "../../../../api/hooks/useMobileAppUsers";
import useWindowSize from "../../../../hooks/useWindowSize";
import UnsavedChangesWarning from "../../../Shared/UnsavedChangesWarning";
import { MobileAppUsersTable, MobileAppUserCreate } from "./module";
import "./scss/MobileAppUsers.scss";

function MobileAppUsersList() {
  const window = useWindowSize();

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");

  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);
  const [isAlertUnsavedChangesOpen, setAlertUnsavedChangesOpen] =
    useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [createFormReference, setFormReference] = useState();

  const pageSize = 14;
  // get search params from URL and format them as expected from the API
  const params = {};
  searchParams.forEach((value, key) => {
    params[key] = value;
  });

  const { page } = params;
  const { data: totalCount, refetch: getHomeAppUsersTotalCount } =
    useHomeAppUsersTotalCount(
      {
        search: debouncedSearchQuery,
      },
      {
        enabled: pageSize > 0,
        cacheTime: 0,
      },
    );
  const {
    data: mobileAppUsers,
    status,
    refetch: getMobileAppUsers,
  } = useHomeAppUsers(
    {
      pageSize,
      startIndex: Number(page) - 1 >= 0 ? (Number(page) - 1) * pageSize : 0,
      search: debouncedSearchQuery,
    },
    {
      enabled: pageSize > 0,
      cacheTime: 0,
    },
  );

  useEffect(() => {
    getMobileAppUsers();
    getHomeAppUsersTotalCount();
  }, [
    debouncedSearchQuery,
    page,
    getMobileAppUsers,
    getHomeAppUsersTotalCount,
  ]);

  const onPageChange = (pagination) => {
    setSearchParams({ ...params, page: pagination.current });
  };

  const onSearch = (value) => {
    setSearchQuery(value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  const openCreateNewUserModal = () => {
    setIsCreateUserModalOpen(true);
  };

  const handleOnCancelCreateModal = () => {
    if (isFormDirty) {
      setAlertUnsavedChangesOpen(true);
    } else {
      setIsCreateUserModalOpen(false);
      createFormReference.resetFields();
      getMobileAppUsers();
    }
  };
  return (
    <div className="content-wrapper overflow-y-auto">
      <div className="mobile-app-users-list flex h-full flex-col">
        <div className="flex items-center sm:justify-end justify-center">
          {window?.width > 992 && (
            <h1 className="page-title mb-0">Mobile Users</h1>
          )}

          <div className="ml-0 sm:ml-auto flex-col-reverse sm:flex-row flex sm:inline-flex items-center justify-center md:justify-between">
            <Search
              className="w-full mr-4 my-1"
              placeholder="Search Users"
              onSearch={onSearch}
              onChange={handleSearchChange}
              allowClear
              size="middle"
            />
            <Button
              size="small"
              type=""
              className="new-mobile-app-user"
              onClick={openCreateNewUserModal}
            >
              <span className="text-16">Add Mobile User</span>
              <PlusOutlined style={{ fontSize: "16px", color: "white" }} />
            </Button>
          </div>
        </div>
        <div className="my-2">
          <MobileAppUsersTable
            status={status}
            data={mobileAppUsers?.Result}
            totalCount={totalCount}
            pageSize={pageSize}
            refetchMobileAppUsers={getMobileAppUsers}
            onChange={onPageChange}
          />
          <Modal
            className="mobile-app-user-modal"
            maskClosable={false}
            footer={null}
            open={isCreateUserModalOpen}
            keyboard={false}
            onCancel={handleOnCancelCreateModal}
            destroyOnClose
            wrapClassName="custom-mobile-app-user-modal-content"
            width={725}
            bodyStyle={{
              height: 500,
              overflowY: "auto",
              marginTop: "1.5rem",
            }}
          >
            <MobileAppUserCreate
              setIsFormDirty={setIsFormDirty}
              setFormReference={setFormReference}
              setIsCreateUserModalOpen={setIsCreateUserModalOpen}
              refetchMobileAppUsers={getMobileAppUsers}
            />
          </Modal>
          <UnsavedChangesWarning
            onSaveClick={() => {
              setAlertUnsavedChangesOpen(false);
              createFormReference.submit();
            }}
            onDontSaveClick={() => {
              setAlertUnsavedChangesOpen(false);
              setIsCreateUserModalOpen(false);
              setIsFormDirty(false);
              createFormReference.resetFields();
              getMobileAppUsers();
            }}
            onCancelClick={() => {
              setAlertUnsavedChangesOpen(false);
            }}
            isAlertUnsavedChangesOpen={isAlertUnsavedChangesOpen}
          />
        </div>
      </div>
    </div>
  );
}
export default MobileAppUsersList;
