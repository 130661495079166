import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function usePropertyTotalWaterConsumption(
  options,
  key = "getPropertyTotalWaterConsumption",
) {
  const axiosPrivate = useAxiosPrivate();

  const getPropertyTotalWaterConsumption = async () => {
    const events = await axiosPrivate({
      method: "GET",
      url: "PropertyOverview/AggregatedWaterConsumption",
    }).then((res) => res.data);
    return events;
  };

  return useQuery([key], () => getPropertyTotalWaterConsumption(), {
    ...options,
  });
}

export default usePropertyTotalWaterConsumption;
