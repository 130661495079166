import {
  CloseOutlined,
  ArrowLeftOutlined,
  PhoneOutlined,
  MailOutlined,
  FrownOutlined,
} from "@ant-design/icons";
import { Drawer, Descriptions, Skeleton, Alert, Divider } from "antd";
import { t } from "i18next";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import useRecentStatuses from "../../../../api/hooks/useRecentStatuses";
import useWindowSize from "../../../../hooks/useWindowSize";

function AlarmDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const { alarmId } = useParams();
  const window = useWindowSize();

  const { data: recentStatuses, status } = useRecentStatuses(
    "?systemStatuses[0]=Alert",
  );

  return (
    <Drawer
      key="alarmDetailsDrawer"
      placement="right"
      open={alarmId}
      width={window?.width > 576 ? "420px" : "100%"}
      headerStyle={{ borderBottom: "none" }}
      onClose={() =>
        navigate({
          pathname: "..",
          search: location.search,
        })
      }
      closeIcon={
        window?.width <= 576 ? (
          <ArrowLeftOutlined className="drawer-details-close-button" />
        ) : (
          <CloseOutlined className="drawer-details-close-button" />
        )
      }
    >
      <div className="drawer-details">
        {status === "loading" && (
          <Skeleton
            active
            paragraph={{
              rows: 10,
            }}
          />
        )}

        {status === "error" && (
          <Alert
            message={t("problems.bad_news")}
            description={t("problems.broke_something")}
            type="error"
            showIcon
            icon={<FrownOutlined />}
          />
        )}

        {status === "success" && (
          <>
            <h1 className="drawer-details-title">
              {recentStatuses[alarmId].AlertName || "Unknown"}
            </h1>
            <div className="drawer-details-subtitle">
              <div className="mb-2 sm:mb-0 break-all w-1/2">
                <PhoneOutlined className="mr-1" />
                <span>{recentStatuses[alarmId].SupportPhone}</span>
              </div>
              <Divider type="vertical" className="hidden sm:inline-block" />
              <div className="break-all w-1/2">
                <MailOutlined className="mr-1" />
                <span>{recentStatuses[alarmId].SupportEmail}</span>
              </div>
            </div>
            <Descriptions
              bordered={false}
              className="text-16"
              colon={false}
              column={1}
              labelStyle={{
                background: "#2F3342",
                width: "50%",
              }}
            >
              <Descriptions.Item
                label={t("property_overview_page.property")}
                className="drawer-details-description-row"
              >
                <span className="drawer-details-description-item">
                  {recentStatuses[alarmId].PropertyName || "-"}
                </span>
              </Descriptions.Item>
              <Descriptions.Item
                label={t("property_overview_page.floor")}
                className="drawer-details-description-row"
              >
                <span className="drawer-details-description-item">
                  {recentStatuses[alarmId].FloorName || "-"}
                </span>
              </Descriptions.Item>
              <Descriptions.Item
                label={t("property_overview_page.system")}
                className="drawer-details-description-row"
              >
                <span className="drawer-details-description-item">
                  {recentStatuses[alarmId].SystemName || "-"}
                </span>
              </Descriptions.Item>
              <Descriptions.Item
                label={t("property_overview_page.device")}
                className="drawer-details-description-row"
              >
                <span className="drawer-details-description-item">
                  {recentStatuses[alarmId].DeviceTypeName || "-"}
                </span>
              </Descriptions.Item>
              <Descriptions.Item
                label={t("filters_modal.device_serial_number")}
                className="drawer-details-description-row"
              >
                <span className="drawer-details-description-item">
                  {recentStatuses[alarmId].DeviceSerialNumber || "-"}
                </span>
              </Descriptions.Item>
              <Descriptions.Item
                label={t("property_overview_page.time")}
                className="drawer-details-description-row"
              >
                <span className="drawer-details-description-item">
                  {new Date(
                    recentStatuses[alarmId].LastModified,
                  ).toLocaleDateString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })}
                </span>
              </Descriptions.Item>
              <Descriptions.Item
                label={t("property_overview_page.status")}
                className="drawer-details-description-row"
              >
                <span className="drawer-details-description-item">
                  {recentStatuses[alarmId].Status?.split(/(?=[A-Z])/).join(" ")}
                </span>
              </Descriptions.Item>
            </Descriptions>
          </>
        )}
      </div>
    </Drawer>
  );
}

export default AlarmDetails;
