import { Button, Modal } from "antd";
import { t } from "i18next";
import PropTypes from "prop-types";

function DeleteWarning({ onDeleteClick, onCancelClick, isAlertDeleteOpen }) {
  return (
    <Modal
      className="alert-unsaved-changes"
      maskClosable={false}
      closable={false}
      zIndex={9999}
      footer={
        <>
          <Button size="small" onClick={onDeleteClick}>
            {t("delete_scene.delete")}
          </Button>
          <Button size="small" onClick={onCancelClick}>
            {t("delete_scene.cancel")}
          </Button>
        </>
      }
      open={isAlertDeleteOpen}
      keyboard={false}
      destroyOnClose
    >
      <div className="text-center">
        <h4 className="uppercase">{t("users.are_you_want_to_delete")}</h4>
        <p>{t("users.changes_msg_delete")}</p>
      </div>
    </Modal>
  );
}

DeleteWarning.propTypes = {
  onDeleteClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  isAlertDeleteOpen: PropTypes.bool,
};

DeleteWarning.defaultProps = {
  onDeleteClick: () => {},
  onCancelClick: () => {},
  isAlertDeleteOpen: false,
};

export default DeleteWarning;
