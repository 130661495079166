import { useMutation, useQueryClient } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useZoneDelete() {
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (systemId) => {
      return axiosPrivate({
        method: "DELETE",
        url: `Zone/Delete/${systemId}`,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["getZonesByPropertyId"]);
    },
  });
}

export default useZoneDelete;
