import { Col, Form, Input, Row, Select } from "antd";
import Search from "antd/es/transfer/search";
import { t } from "i18next";
import PropTypes from "prop-types";

import useWindowSize from "../../../../../hooks/useWindowSize";
import { deviceTypeOptions } from "../helperFuncs/helperFunctions";

function NameFilterGroup({ setDeviceTypeId, handleSearchChange }) {
  const window = useWindowSize();
  if (window.width > 1024) {
    return (
      <Row>
        <Col xl={12} md={12} className="pr-24">
          <Form.Item
            className="mb-4 mr-2 w-full"
            label={t("name_filter_group.name")}
            name="Name"
            rules={[
              { required: true, message: t("name_filter_group.name_required") },
            ]}
          >
            <Input size="middle" maxLength={64} />
          </Form.Item>
        </Col>
        <Col
          xl={12}
          sm={24}
          className="inline-flex items-center justify-center mt-4 pl-24"
        >
          <Select
            className="w-full mr-2"
            placeholder={t("name_filter_group.device_type")}
            allowClear
            options={deviceTypeOptions}
            onChange={(value) => {
              setDeviceTypeId(value);
            }}
          />
          <Search
            className="w-full"
            placeholder={t("name_filter_group.search")}
            onChange={handleSearchChange}
            allowClear
            size="middle"
          />
        </Col>
      </Row>
    );
  }
  if (window.width <= 1024) {
    return (
      <>
        <Form.Item
          className="mb-4"
          label={t("name_filter_group.name")}
          name="Name"
          rules={[
            { required: true, message: t("name_filter_group.name_required") },
          ]}
        >
          <Input size="middle" maxLength={64} />
        </Form.Item>
        <div className="mb-4">
          <Select
            className="w-full mb-4"
            placeholder={t("name_filter_group.device_type")}
            allowClear
            options={deviceTypeOptions}
            onChange={(value) => {
              setDeviceTypeId(value);
            }}
          />
          <Search
            className="w-full mr-2"
            placeholder={t("name_filter_group.search_systems")}
            onChange={handleSearchChange}
            allowClear
            size="middle"
          />
        </div>
      </>
    );
  }
}

NameFilterGroup.propTypes = {
  setDeviceTypeId: PropTypes.func,
  handleSearchChange: PropTypes.func,
};
NameFilterGroup.defaultProps = {
  setDeviceTypeId: () => {},
  handleSearchChange: () => {},
};

export default NameFilterGroup;
