import { FrownOutlined } from "@ant-design/icons";
import { notification } from "antd";

async function useMobileAppUserCreate(
  formValues,
  createUserForm,
  setIsLoading,
  setIsFormDirty,
  setIsCreateUserModalOpen,
  refetchGetMobileAppUsers,
  createMobileAppUserMutationFn,
) {
  let createUserResponse;
  // Remove extra whitespaces from a string and ensure there is only one space between.
  const nameParts = formValues.fullName.replace(/\s+/g, " ").trim().split(" ");
  const firstName = nameParts[0];
  // The rest of the array elements are joined back together to form the last name.
  const lastName = nameParts.length > 1 ? nameParts.slice(1).join(" ") : "";
  const request = {
    Email: formValues.email,
    FirstName: firstName,
    LastName: lastName,
    PhoneNumber: formValues.phoneNumber,
    AddUserZoneIds: formValues.addUserZones.map((x) => x.zoneId),
  };

  try {
    createUserResponse = await createMobileAppUserMutationFn.mutateAsync({
      request,
    });
    const responseData = createUserResponse?.data;
    if (responseData?.HasFailed) {
      const dataErrors = responseData?.Errors;
      let description = dataErrors.join(", ");
      if (dataErrors?.includes("Could not create HomeAppUserZones.")) {
        description =
          "Could not create home app user zones. The zone contains devices on multiple properties. Please fix your zone.";
      }
      notification.error({
        message: (
          <span className="uppercase tracking-widest">
            Something went wrong!
          </span>
        ),
        duration: 0,
        description,
        placement: "topRight",
        icon: <FrownOutlined className="text-triple-red" />,
      });
    } else {
      notification.success({
        message: (
          <span className="uppercase tracking-widest">User created</span>
        ),
        duration: 2,
        placement: "topRight",
      });
    }
  } catch (error) {
    const responseData = error?.response?.data;
    let description = "Could not create user.";
    if (Array.isArray(responseData) && responseData.length > 0) {
      description = responseData.join(", ");
    } else if (responseData.includes("already exist")) {
      description = "User with that email already exists.";
    } else {
      description = responseData;
    }
    notification.error({
      message: (
        <span className="uppercase tracking-widest">Something went wrong!</span>
      ),
      duration: 0,
      description,
      placement: "topRight",
      icon: <FrownOutlined className="text-triple-red" />,
    });
  } finally {
    setIsLoading(false);
    setIsFormDirty(false);
    setIsCreateUserModalOpen(false);
    createUserForm.resetFields();
    refetchGetMobileAppUsers();
  }

  return createUserResponse;
}
export default useMobileAppUserCreate;
