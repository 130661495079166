import { FrownOutlined } from "@ant-design/icons";
import { notification } from "antd";

async function useHandlePropertyZoneUpdate(
  formValues,
  createZoneMutation,
  setIsFormDirty,
  setIsLoading,
) {
  const createZoneResponse = formValues?.Systems;

  try {
    // Device Assignment/Unassignment
    /* eslint-disable no-restricted-syntax */
    for (const system of formValues?.Systems?.AssignedSystems ?? []) {
      for (const child of system.children) {
        /* eslint-disable no-await-in-loop */
        await createZoneMutation.mutateAsync({
          systemId: system.UniqueId,
          request: [
            {
              Parent: null, // BE will automatically assign a Zone Name
              Child: child.SerialNumber,
              RelationType: 3,
            },
          ],
        });
      }
    }

    setIsLoading(false);
    setIsFormDirty(false);
    notification.success({
      message: (
        <span className="uppercase tracking-widest">Zone update success</span>
      ),
      duration: 2,
      placement: "topRight",
    });
  } catch (error) {
    notification.error({
      message: (
        <span className="uppercase tracking-widest">Something went wrong!</span>
      ),
      duration: 5,
      description: "Could not update zone.",
      placement: "topRight",
      icon: <FrownOutlined className="text-triple-red" />,
    });
  } finally {
    setIsLoading(false);
    setIsFormDirty(false);
  }

  return { createZoneResponse };
}

export default useHandlePropertyZoneUpdate;
