import { useState } from "react";

import { t } from "i18next";
import PropTypes from "prop-types";

import useAlertsByPropertyId from "../../api/hooks/useAlertsByPropertyId";
import PropertyAlertsTable from "../PropertyOverview/PropertyAlertsTable/PropertyAlertsTable";
// import SearchInput from "../SearchInput/SearchInput";
import CustomModal from "./CustomModal";

const propTypes = {
  alertsCount: PropTypes.number,
  propertyId: PropTypes.number,
};
const defaultProps = {
  alertsCount: 0,
  propertyId: null,
};

function AlertsModal({ alertsCount, propertyId }) {
  const [enabled, setEnabled] = useState(false);
  // const [searchQuery, setSearchQuery] = useState("");
  // const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");

  const [currentPage, setcurrentPage] = useState(1);
  const pageSize = 7;

  const {
    data: alerts,
    status,
    remove: removeGetAlertsbyPropertyIdQuery,
  } = useAlertsByPropertyId(
    {
      propertyId,
      pageSize,
      startIndex: (currentPage - 1) * pageSize,
      // search: debouncedSearchQuery,
    },
    {
      cacheTime: 0,
      enabled,
    },
  );

  return (
    <CustomModal
      title={
        <div className="flex items-center justify-between pr-8 pl-2">
          <h3 className="uppercase tracking-widest font-light">
            {t("system_details.alerts")}
          </h3>

          {/* <SearchInput
            className="ml-auto w-full sm:w-fit"
            placeholder="Search Alerts"
            disabled={
              (!searchQuery && alerts?.length === 0) || status === "loading"
            }
            value={searchQuery}
            onChange={(e) => {
              if (e.target.value === "") {
                setDebouncedSearchQuery("");
              }
              setcurrentPage(1);
              setSearchQuery(e.target.value);
            }}
            onSearch={() => {
              setDebouncedSearchQuery(searchQuery);
            }}
          /> */}
        </div>
      }
      triggerText={`${alertsCount} ${t("system_details.alerts")}`}
      triggerType="text"
      triggerSize="small"
      triggerClassName="text-inherit font-thin text-10"
      destroyOnClose
      width="70%"
      onOpen={() => setEnabled(true)}
      onClose={() => {
        setEnabled(false);
        removeGetAlertsbyPropertyIdQuery();
        setcurrentPage(1);
      }}
    >
      <div className="p-4">
        <PropertyAlertsTable
          data={alerts}
          status={status}
          currentPage={currentPage}
          pageSize={pageSize}
          totalCount={alerts?.TotalCount}
          onChange={(pagination) => {
            setcurrentPage(pagination?.current);
          }}
        />
      </div>
    </CustomModal>
  );
}

AlertsModal.propTypes = propTypes;
AlertsModal.defaultProps = defaultProps;

export default AlertsModal;
