import { useEffect, useState } from "react";

import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Layout } from "antd";
import { useTranslation } from "react-i18next";
import { Outlet, useRoutes } from "react-router-dom";

import useUser from "./api/hooks/useUser";
import useUserBasicsV2 from "./api/hooks/useUserBasicsV2";
import children from "./AppChildrenRoutes";
import PersistLogin from "./auth/PersistLogin";
import RequireAuth from "./auth/RequireAuth";
import Forbidden from "./components/ErrorPages/Forbidden";
import PageNotFound from "./components/ErrorPages/PageNotFound";
import Unauthorized from "./components/ErrorPages/Unauthorized";
import CmsFooter from "./components/Footer/CmsFooter";
import Header from "./components/Header/Header";
import ForgotPassword from "./components/LoginPge/ForgotPassword";
import LoginPage from "./components/LoginPge/LoginPage";
import BackButton from "./components/Shared/BackButton";
import Sidebar from "./components/Sidebar/Sidebar";
import useAuth from "./hooks/useAuth";
import useProfileSettings from "./hooks/useProfileSettings";
import useWindowSize from "./hooks/useWindowSize";
import ThemeConfigProvider from "./theme/ThemeConfigProvider";
import "./App.scss";

function App() {
  const [isMobileNavActive, setIsMobileNavActive] = useState(false);

  const { auth, setAuth } = useAuth();
  const { setUserSettings } = useProfileSettings();
  const { refetch: getLoggedUser } = useUser();
  const { refetch: getUserBasics } = useUserBasicsV2();

  const { i18n } = useTranslation();
  const window = useWindowSize();

  const { Sider, Content } = Layout;

  // get the logged user and add it to the auth context
  useEffect(() => {
    if (auth.isAuthenticated && !auth.user) {
      getLoggedUser().then((res) => {
        setAuth((prevState) => ({ ...prevState, user: res.data }));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isAuthenticated]);

  // get the logged user's settings add them to the user settings context
  useEffect(() => {
    if (auth.isAuthenticated && !auth.user) {
      getUserBasics().then((res) => {
        setUserSettings((prevState) => ({
          ...prevState,
          waterUnit: res.data.WaterUnit,
          temperatureUnut: res.data.TemperatureUnit,
          clockType: res.data.ClockType,
        }));
        i18n.changeLanguage(res.data.Language);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.isAuthenticated]);

  // remove scrollbar when the mobile nav is active
  useEffect(() => {
    if (isMobileNavActive) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [window, isMobileNavActive]);

  // app routing
  const routes = [
    {
      path: "/",
      element: (
        <PersistLogin>
          <RequireAuth>
            <>
              <Header
                withNavigation
                isMobileNavActive={isMobileNavActive}
                setIsMobileNavActive={setIsMobileNavActive}
              />

              <div className="flex min-h-0 h-full">
                {/* Desktop sidebar */}
                {window?.width > 992 && (
                  <Sider className="sidebar-wrapper">
                    <Sidebar className="sidebar" />
                  </Sider>
                )}

                {/* Mobile nav */}
                {window?.width <= 992 && isMobileNavActive && (
                  <div
                    role="none"
                    onClick={(e) =>
                      e.target.classList.contains("nav-link") &&
                      setIsMobileNavActive(false)
                    }
                    className="mobile-nav"
                  >
                    <Sidebar
                      className={`sidebar--mobile ${
                        window?.isLandscape ? "overflow-y-scroll" : ""
                      }`}
                    />
                  </div>
                )}

                {/* Main content */}
                <Content className="main-content">
                  <BackButton
                    redirect={{ to: -1 }}
                    confirmation={false}
                    title="Back Button"
                    className="uppercase tracking-widest mx-1 mt-4"
                  />
                  {/* Outlet to display the router pages */}
                  <Outlet />
                </Content>
              </div>
              <CmsFooter />
            </>
          </RequireAuth>
        </PersistLogin>
      ),
      children,
    },
    {
      path: "/login",
      element: (
        <>
          <Header />
          <LoginPage />
        </>
      ),
    },
    {
      path: "/forbidden",
      element: <Forbidden />,
    },
    {
      path: "/unauthorized",
      element: <Unauthorized />,
    },
    {
      path: "/reset-password",
      element: (
        <>
          <Header />
          <ForgotPassword />
        </>
      ),
    },
    {
      path: "*",
      element: <PageNotFound />,
    },
  ];

  const RoutesElement = useRoutes(routes);

  return (
    <ThemeConfigProvider>
      <div className="app">
        {RoutesElement}
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </div>
    </ThemeConfigProvider>
  );
}

export default App;
