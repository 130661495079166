import RegularExpressions from "../../../../../constants/RegExpressions";

function useHandleFieldsChange(
  changedFields,
  formInstance,
  initialFormValues,
  setDisableSaveChanges,
  setIsFormDirty,
  setAddZoneDisabled,
) {
  const formInstanceValues = formInstance.getFieldsValue();
  const fieldName = changedFields[0]?.name[0];
  const fieldValue = changedFields[0]?.value;

  if (fieldName === "phoneNumber") {
    if (fieldValue === "") {
      setDisableSaveChanges(true);
      formInstance.setFields([
        {
          name: "phoneNumber",
          errors: ["Mobile field is required."],
        },
      ]);
    } else {
      const isValidInput = RegularExpressions.NumbersOnly.test(fieldValue);
      if (isValidInput) {
        formInstance.setFields([{ name: "phoneNumber", errors: [] }]);
      } else {
        setDisableSaveChanges(true);
        formInstance.setFields([
          {
            name: "phoneNumber",
            errors: ["Mobile should contain only digits."],
          },
        ]);
      }
    }
  }

  if (
    formInstanceValues?.fullName !== initialFormValues.current.fullName ||
    formInstanceValues?.phoneNumber !== initialFormValues.current.phoneNumber ||
    formInstanceValues?.email !== initialFormValues.current.email ||
    formInstanceValues?.addUserZones.length !==
      initialFormValues.current.addUserZones.length
  ) {
    setDisableSaveChanges(false);
    setIsFormDirty(true);
  }

  if (
    formInstanceValues?.fullName === initialFormValues.current.fullName &&
    formInstanceValues?.phoneNumber === initialFormValues.current.phoneNumber &&
    formInstanceValues?.email === initialFormValues.current.email &&
    formInstanceValues?.addUserZones.length ===
      initialFormValues.current.addUserZones.length
  ) {
    setDisableSaveChanges(true);
    setIsFormDirty(false);
  }

  if (
    formInstanceValues.fullName &&
    formInstanceValues.phoneNumber &&
    formInstanceValues.email &&
    formInstanceValues.property &&
    formInstanceValues.zone
  ) {
    setAddZoneDisabled(false);
  } else {
    setAddZoneDisabled(true);
  }
}

export default useHandleFieldsChange;
