import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { English, Russian, Hebrew } from "../assets/locales/translations";

export const languages = {
  English: "English",
  Hebrew: "Hebrew",
  Russian: "Russian",
  French: "French",
  Dutch: "Dutch",
};

i18n.use(initReactI18next).init({
  // Default language when a user visits the website for the first time.
  // lng: "English",
  // Language the use if translations in user language are not available.
  fallbackLng: "English",
  supportedLngs: Object.keys(languages),
  debug: false,
  interpolation: {
    escapeValue: false, // React escape passed in values to avoid xss injection by default.
  },
  resources: {
    // English
    English: {
      translation: English,
    },
    Russian: {
      translation: Russian,
    },
    Hebrew: {
      translation: Hebrew,
    },
  },
});

export default i18n;
