/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from "react";

import {
  QuestionCircleOutlined,
  CloseCircleOutlined,
  FrownOutlined,
} from "@ant-design/icons";
import { Button, Modal, notification } from "antd";
import { t } from "i18next";

import useScheduleSystems from "../../../hooks/useScheduleSystems";

function DeleteScene({ interval }) {
  const [isOpen, setIsOpen] = useState(false);
  const { scheduleSystems, setScheduleSystems } = useScheduleSystems();

  const { interval: data } = interval;
  const { startTime, name } = data;

  const handleDeleteScene = () => {
    const filteredScheduleIntervals = scheduleSystems.scheduleIntervals.filter(
      (intervalItem) => intervalItem.startTime !== startTime,
    );
    setScheduleSystems({
      ...scheduleSystems,
      scheduleIntervals: filteredScheduleIntervals,
    });

    if (filteredScheduleIntervals.length === 0) {
      notification.error({
        message: (
          <span className="uppercase tracking-widest">
            {t("delete_scene.cannot_delete")}
          </span>
        ),
        description: t("delete_scene.you_cannot_delete"),
        placement: "topRight",
        icon: <FrownOutlined className="text-triple-red" />,
        duration: 0,
      });
    }
  };
  const isDisabled = scheduleSystems.scheduleIntervals.length === 1;

  return (
    <>
      <Button type="link" className="text-lg" onClick={() => setIsOpen(true)}>
        <CloseCircleOutlined />
      </Button>
      <Modal
        open={isOpen}
        width={584}
        footer={[
          <Button
            key="cancel-scene-delete"
            size="large"
            onClick={() => setIsOpen(false)}
          >
            {t("delete_scene.cancel")}
          </Button>,
          <Button
            key="confirm-scene-delete"
            type="primary"
            size="large"
            onClick={handleDeleteScene}
            disabled={isDisabled}
          >
            {t("delete_scene.delete")}
          </Button>,
        ]}
        onCancel={() => setIsOpen(false)}
      >
        <div className="p-8 flex flex-col items-center">
          <QuestionCircleOutlined className="text-triple-blue text-3xl mb-4" />
          <h1 className="text-white uppercase tracking-widest text-xl font-light text-center">
            {t("delete_scene.are_you_sure")}
          </h1>
        </div>

        <div className="pb-16 px-4">
          <h3 className="text-center mb-4">
            {name || t("delete_scene.unknown_scene")}
          </h3>
        </div>
      </Modal>
    </>
  );
}

export default DeleteScene;
