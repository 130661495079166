import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useMonitoringEvents(options, key = "getMonitoringEvents") {
  const axiosPrivate = useAxiosPrivate();

  const getMonitoringEvents = async () => {
    const monitoringEvents = await axiosPrivate({
      method: "GET",
      url: "/Monitoring/Events",
    }).then((res) => res.data);
    return monitoringEvents;
  };

  return useQuery([key], () => getMonitoringEvents(), {
    ...options,
  });
}

export default useMonitoringEvents;
