import React from "react";

import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { t } from "i18next";
import PropTypes from "prop-types";
import { CSVLink } from "react-csv";

const propTypes = {
  btnClassName: PropTypes.string,
  className: PropTypes.string,
  data: PropTypes.array,
  disabled: PropTypes.bool,
  filename: PropTypes.string,
  size: PropTypes.string,
};

const defaultProps = {
  className: "",
  btnClassName: "",
  data: [],
  disabled: false,
  filename: "export",
  size: "middle",
};

const ExportCSV = React.forwardRef((props, ref) => {
  const today = new Date();
  const currentDateAndTime = `${today.toLocaleDateString()} ${today.toLocaleTimeString()}`;

  return (
    <div className={`text-small ${props.className}`}>
      <CSVLink
        data={props.data}
        filename={`${currentDateAndTime} ${props.filename}.csv`}
        target="_blank"
        ref={ref}
      >
        <Button
          type="primary"
          className={`flex items-center justify-center w-full ${props.btnClassName}`}
          disabled={props.disabled}
          size={props.size}
        >
          <DownloadOutlined /> {t("export.export")}
        </Button>
      </CSVLink>
    </div>
  );
});

ExportCSV.propTypes = propTypes;
ExportCSV.defaultProps = defaultProps;

export default ExportCSV;
