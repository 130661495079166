import { t } from "i18next";
import PropTypes from "prop-types";

import CustomTable from "../../CustomTable/CustomTable";
import EventIcon from "../../Events/EventIcon";

const propTypes = {
  data: PropTypes.array,
  status: PropTypes.string,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  totalCount: PropTypes.number,
  onChange: PropTypes.func,
};

const defaultProps = {
  data: [],
  status: "loading",
  pageSize: null,
  currentPage: null,
  totalCount: null,
  onChange: () => {},
};

function SystemDevicesTable({
  data,
  status,
  pageSize,
  currentPage,
  totalCount,
  onChange,
}) {
  const columns = [
    {
      title: t("events_filter.device_type"),
      key: "type",
      render: (record) => (
        <>
          <EventIcon
            key={record?.Id}
            iconName={record?.DeviceTypePublicName}
            className={`${record?.DeviceStatus?.toLowerCase()}-event mr-2`}
            defaultIcon={<span>*icon*</span>}
          />
          {record.DeviceTypePublicName || "-"}
        </>
      ),
      width: 200,
    },
    {
      title: t("events_filter.device_serial_number"),
      key: "DeviceSerialNumber",
      render: (record) => <span>{record?.DeviceSerialNumber || "-"}</span>,
      width: 175,
    },
    {
      title: t("floor_system_tables.status"),
      key: "status",
      render: (record) => <span>{record?.DeviceStatus || "-"}</span>,
      width: 125,
    },
    {
      title: t("events_filter.device_name"),
      key: "Name",
      render: (record) => <span>{record?.DeviceName || "-"}</span>,
      width: 125,
    },
    {
      title: t("event_details.location"),
      key: "location",
      render: (record) => <span>{record?.DeviceLocation || "-"}</span>,
      width: 125,
    },
    {
      title: t("event_details.floor"),
      key: "floorName",
      render: (record) => <span>{record?.FloorName || "-"}</span>,

      width: 100,
    },
    {
      title: t("filters_modal.system_name"),
      key: "systemName",
      render: (record) => <span>{record?.SystemName || "-"}</span>,

      width: 125,
    },
    {
      title: t("filters_modal.system_unique_id"),
      key: "SystemUniqueId",
      render: (record) => <span>{record?.SystemUniqueId || "-"}</span>,

      width: 175,
    },
  ];

  return (
    <CustomTable
      data={data?.Result}
      status={status}
      noPagination={!(totalCount > pageSize) || status === "loading"}
      pageSize={pageSize}
      totalCount={totalCount}
      currentPage={currentPage}
      tableColumns={columns}
      onChange={onChange}
    />
  );
}

SystemDevicesTable.propTypes = propTypes;
SystemDevicesTable.defaultProps = defaultProps;

export default SystemDevicesTable;
