import { CloseOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { t } from "i18next";
import PropTypes from "prop-types";

import useProfileSettings from "../../../hooks/useProfileSettings";
import "./StatisticsChartTitle.scss";

function ChartTitle({
  title,
  handleHideChart,
  waterConsumptionData,
  chartType,
}) {
  const { userSettings } = useProfileSettings();
  return (
    <div
      className={`bar-chart-title bg-triple-white rounded-t-xl flex justify-between p-1 mt-1 ${chartType}`}
    >
      <span className="rounded-md bg-triple-blue flex justify-center px-1 items-center">
        {title}
      </span>
      <span className="text-triple-blue p-1 mt-1 text-16">
        {waterConsumptionData?.TotalWaterConsumption}
        {userSettings?.waterUnit === "Litres" && (
          <span className="text-triple-blue ml-1">
            m<sup>3</sup>
          </span>
        )}
        {userSettings?.waterUnit === "Gallons" && (
          <span className="text-triple-blue ml-1">gal</span>
        )}
      </span>
      {chartType !== "annual-chart" && (
        <Button
          onClick={handleHideChart}
          className="w-8 p-1"
          type="ghost"
          size="small"
        >
          <CloseOutlined
            title={t("report.close_chart")}
            className="text-triple-wintergreen text-base"
          />
        </Button>
      )}
    </div>
  );
}
ChartTitle.defaultProps = {
  title: "",
  handleHideChart: null,
  waterConsumptionData: {},
  chartType: "",
};

ChartTitle.propTypes = {
  title: PropTypes.string,
  handleHideChart: PropTypes.func,
  waterConsumptionData: PropTypes.object,
  chartType: PropTypes.string,
};
export default ChartTitle;
