import { useEffect, useRef, useState } from "react";

import { FrownOutlined } from "@ant-design/icons";
import { Divider, Form, Input, notification } from "antd";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

import useAttachments from "../../api/hooks/useAttachments";
import useUpdateUserBasicsV2 from "../../api/hooks/useUpdateUserBasicsV2";
import useUser from "../../api/hooks/useUser";
import useUserBasicsV2 from "../../api/hooks/useUserBasicsV2";
import useUsersUpdateImage from "../../api/hooks/useUsersUpdateImage";
import RegularExpressions from "../../constants/RegExpressions";
import useAuth from "../../hooks/useAuth";
import useProfileSettings from "../../hooks/useProfileSettings";
import ImageUploader from "../ImageUploader/ImageUploader";
import LanguageOptions from "../Shared/Languages";
import SaveChangesButton from "../Shared/SaveChangesButton";
import FormItemSelect from "../Shared/Select";
import "./Profile.scss";

function Profile() {
  // Hooks
  const { auth, setAuth } = useAuth();
  const { setUserSettings } = useProfileSettings();
  const { refetch: getUserBasics } = useUserBasicsV2();
  const { refetch: getLoggedUser } = useUser();
  const { mutate: updateUserBasicsV2 } = useUpdateUserBasicsV2();
  const { mutate: updateProfileImage } = useUsersUpdateImage();
  const { mutate: uploadAttachment } = useAttachments();
  const { i18n } = useTranslation();
  // State
  const [isLoading, setIsLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);
  const [userBasicsForm] = Form.useForm();
  const [disableSaveChanges, setDisableSaveChanges] = useState(true);
  const [fileListArr, setFileListArr] = useState([]);
  const initialFormValues = useRef({});

  // Effects
  useEffect(() => {
    if (auth.isAuthenticated) {
      getUserBasics().then((res) => {
        userBasicsForm.setFieldsValue(res.data);

        initialFormValues.current = res.data;
        initialFormValues.current.WaterUnit =
          res.data?.WaterUnit?.toLowerCase();
        initialFormValues.current.TemperatureUnit =
          res.data?.TemperatureUnit?.toLowerCase();
        initialFormValues.current.Language = res.data?.Language;
        initialFormValues.current.ClockType = res.data?.ClockType;
        if (res.data.ImageUrl) {
          setFileListArr([{ url: res.data.ImageUrl }]);
        }
        setImageLoading(false);
      });
    }
  }, [auth.isAuthenticated, getUserBasics, userBasicsForm]);

  // Handlers
  const handleFieldsChanges = (changedFields) => {
    const fieldName = changedFields[0]?.name[0];
    const fieldValue = changedFields[0]?.value;
    const userProfileValues = userBasicsForm.getFieldsValue();
    if (fieldName === "PhoneNumber") {
      const isValidInput = RegularExpressions.NumbersOnly.test(fieldValue);
      if (isValidInput) {
        userBasicsForm.setFields([{ name: "PhoneNumber", errors: [] }]);
      } else {
        setDisableSaveChanges(true);
        userBasicsForm.setFields([
          {
            name: "PhoneNumber",
            errors: [t("profile.phone_number_only_digits")],
          },
        ]);
      }
    }

    if (
      userProfileValues?.FirstName !== initialFormValues.current.FirstName ||
      userProfileValues?.LastName !== initialFormValues.current.LastName ||
      userProfileValues?.PhoneNumber !==
        initialFormValues.current.PhoneNumber ||
      userProfileValues?.ImageUrl !== initialFormValues.current.ImageUrl ||
      userProfileValues.Language !== initialFormValues.current.Language ||
      userProfileValues.WaterUnit !== initialFormValues.current.WaterUnit ||
      userProfileValues.TemperatureUnit !==
        initialFormValues.current.TemperatureUnit ||
      userProfileValues.ClockType !== initialFormValues.current.ClockType
    ) {
      setDisableSaveChanges(false);
    }

    if (
      userProfileValues?.FirstName === initialFormValues.current.FirstName &&
      userProfileValues?.LastName === initialFormValues.current.LastName &&
      userProfileValues?.PhoneNumber ===
        initialFormValues.current.PhoneNumber &&
      userProfileValues?.ImageUrl === initialFormValues.current.ImageUrl &&
      userProfileValues.Language === initialFormValues.current.Language &&
      userProfileValues.WaterUnit === initialFormValues.current.WaterUnit &&
      userProfileValues.TemperatureUnit ===
        initialFormValues.current.TemperatureUnit &&
      userProfileValues.ClockType === initialFormValues.current.ClockType
    ) {
      setDisableSaveChanges(true);
    }
  };

  const onRemoveImg = () => {
    setFileListArr([]);
  };

  const onChange = (info) => {
    const newFileList = [...info.fileList];
    setFileListArr(newFileList);
  };

  const request = ({ file, onSuccess, onError }) => {
    // Used to prevent the plugin auto post otherwise it will throw exception in the console.
    setTimeout(() => {
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > 4) {
        onError("");
        setFileListArr([]);
      } else {
        onSuccess("ok");
      }
    }, 1000);
  };

  const handleUpdate = (userProfileValues) => {
    const request = {
      firstName: userProfileValues.FirstName,
      lastName: userProfileValues.LastName,
      phoneNumber: userProfileValues.PhoneNumber,
      language: userProfileValues.Language,
      imageUrl: userProfileValues.ImageUrl,
      waterUnit: userProfileValues.WaterUnit,
      temperatureUnit: userProfileValues.TemperatureUnit,
      clockType: userProfileValues.ClockType,
    };

    updateUserBasicsV2(request, {
      onSuccess: (response) => {
        userBasicsForm.setFieldsValue(response.data);
        initialFormValues.current = response.data;
        // Update Auth Context
        getLoggedUser().then((res) => {
          setAuth((prevState) => ({ ...prevState, user: res.data }));
        });
        setUserSettings((prevState) => ({
          ...prevState,
          waterUnit: response.data.WaterUnit,
          temperatureUnut: response.data.TemperatureUnit,
          clockType: response.data.ClockType,
        }));
        i18n.changeLanguage(response.data.Language);
        setDisableSaveChanges(true);
        setIsLoading(false);
      },
      onError: () => {
        setIsLoading(false);
        notification.error({
          message: (
            <span className="uppercase tracking-widest">
              {t("flow_configuration.somethimg_went_wrong")}
            </span>
          ),
          duration: 0,
          placement: "topRight",
          icon: <FrownOutlined className="text-triple-red" />,
        });
      },
    });
  };

  const handleUpdateWithImage = (userProfileValues) => {
    const { fileList } = userProfileValues.ImageUrl;
    const data = new FormData();
    data.append("file", fileList[0].originFileObj);
    uploadAttachment(data, {
      onSuccess: (responseUploadAttachment) => {
        updateProfileImage(responseUploadAttachment.data, {
          onSuccess: (responseUpdateProfileImage) => {
            setFileListArr([
              {
                url: responseUpdateProfileImage.data,
              },
            ]);
            const request = {
              firstName: userProfileValues.FirstName,
              lastName: userProfileValues.LastName,
              phoneNumber: userProfileValues.PhoneNumber,
              language: userProfileValues.Language,
              imageUrl: responseUpdateProfileImage.data,
              waterUnit: userProfileValues.WaterUnit,
              temperatureUnit: userProfileValues.TemperatureUnit,
              clockType: userProfileValues.ClockType,
            };
            updateUserBasicsV2(request, {
              onSuccess: (responseUpdateUserBasicsV2) => {
                userBasicsForm.setFieldsValue(responseUpdateUserBasicsV2.data);
                initialFormValues.current = responseUpdateUserBasicsV2.data;
                // Update Auth Context
                getLoggedUser().then((res) => {
                  setAuth((prevState) => ({ ...prevState, user: res.data }));
                });
                setUserSettings((prevState) => ({
                  ...prevState,
                  waterUnit: responseUpdateUserBasicsV2.data.WaterUnit,
                  temperatureUnut:
                    responseUpdateUserBasicsV2.data.TemperatureUnit,
                  clockType: responseUpdateUserBasicsV2.data.ClockType,
                }));
                i18n.changeLanguage(responseUpdateUserBasicsV2.data.Language);
                setDisableSaveChanges(true);
                setIsLoading(false);
              },
            });
          },
        });
      },
      onError: () => {
        setIsLoading(false);
        notification.error({
          message: (
            <span className="uppercase tracking-widest">
              {t("flow_configuration.somethimg_went_wrong")}
            </span>
          ),
          duration: 0,
          placement: "topRight",
          icon: <FrownOutlined className="text-triple-red" />,
        });
      },
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const userProfileValues = userBasicsForm.getFieldsValue();
    if (userProfileValues.ImageUrl?.file?.status === "removed") {
      userProfileValues.ImageUrl = null;
    }
    if (
      userProfileValues.ImageUrl &&
      typeof userProfileValues.ImageUrl === "object"
    ) {
      handleUpdateWithImage(userProfileValues);
    } else {
      handleUpdate(userProfileValues);
    }
  };

  return (
    <div className="content-wrapper overflow-auto">
      <Form
        form={userBasicsForm}
        name="update-user-profile"
        layout="vertical"
        className="w-full"
        initialValues={{
          FirstName: initialFormValues.current?.FirstName,
          LastName: initialFormValues.current?.LastName,
          Email: initialFormValues.current?.Email,
          PhoneNumber: initialFormValues.current?.PhoneNumber,
          ImageUrl: initialFormValues.current?.ImageUrl,
          Language: initialFormValues.current?.Language,
          WaterUnit: initialFormValues.current?.WaterUnit,
          TemperatureUnit: initialFormValues.current?.TemperatureUnit,
          ClockType: initialFormValues.current?.ClockType,
        }}
        onFinish={handleSubmit}
        onFieldsChange={handleFieldsChanges}
      >
        <div className="mb-3">
          <h1 className="text-triple-blue page-title-mobile">
            {t("profile.profile")}
          </h1>
        </div>
        <div className="flex">
          <div className="w-full">
            <Form.Item
              className="mb-1"
              label={t("profile.first_name")}
              name="FirstName"
              rules={[
                { required: true, message: t("profile.first_name_required") },
              ]}
            >
              <Input size="middle" maxLength={64} />
            </Form.Item>

            <Form.Item
              className="mb-1"
              label={t("profile.last_name")}
              name="LastName"
              rules={[
                { required: true, message: t("profile.last_name_required") },
              ]}
            >
              <Input size="middle" maxLength={64} />
            </Form.Item>

            <Form.Item className="mb-1" label={t("profile.email")} name="Email">
              <Input size="middle" disabled />
            </Form.Item>

            <Form.Item
              className="mb-1"
              label={t("profile.phone_number")}
              name="PhoneNumber"
            >
              <Input size="middle" maxLength={15} />
            </Form.Item>
          </div>
          <div className="flex justify-center items-center">
            <div className="ml-2">
              <Form.Item name="ImageUrl">
                {!imageLoading && (
                  <ImageUploader
                    fileListArr={fileListArr}
                    onRemoveImg={onRemoveImg}
                    onChange={onChange}
                    request={request}
                    autoUpload={false}
                    placeholder={t("profile.upload_img")}
                  />
                )}
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="mt-5">
          <span className="text-triple-blue page-title-mobile">
            {t("units.units")}
          </span>
          <Divider className="w-full my-1" />
          <div className="flex flex-col md:flex-row">
            <FormItemSelect
              className="mb-1 form-item-select"
              fieldLabel={t("units.water_measure")}
              fieldName="WaterUnit"
              placeholder="Water Measure Unit"
              size="small"
              options={[
                {
                  value: "litres",
                  label: t("units.litres"),
                },
                {
                  value: "gallons",
                  label: t("units.gallons"),
                },
              ]}
            />
            <FormItemSelect
              className="mb-1 form-item-select"
              fieldLabel={t("units.temperature")}
              fieldName="TemperatureUnit"
              placeholder={t("profile.temperature_unit")}
              size="small"
              options={[
                {
                  value: "Celsius",
                  label: t("units.celsius"),
                },
                {
                  value: "Fahrenheit",
                  label: t("units.fahrenheit"),
                },
              ]}
            />
            <FormItemSelect
              className="mb-1 form-item-select"
              fieldLabel={t("units.time_mode")}
              fieldName="ClockType"
              placeholder={t("units.time_mode")}
              size="small"
              options={[
                {
                  value: "12",
                  label: t("units.12h"),
                },
                {
                  value: "24",
                  label: t("units.24h"),
                },
              ]}
            />
            <FormItemSelect
              className="mb-1 form-item-select"
              fieldLabel={t("units.language")}
              fieldName="Language"
              placeholder={t("units.language")}
              size="small"
              options={LanguageOptions}
            />
          </div>
        </div>
        <div className="flex justify-center items-center">
          <SaveChangesButton
            className="mb-0"
            size="small"
            text={t("units.save")}
            isLoading={isLoading}
            disableSaveChanges={disableSaveChanges}
          />
        </div>
      </Form>
    </div>
  );
}

export default Profile;
