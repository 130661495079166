import { useEffect, useRef } from "react";

/**
 * usePolling - A custom React hook that periodically triggers a data fetch function
 * while ensuring efficiency by stopping polling when the page is not visible.
 *
 * @param {Function} refetch The function to fetch data (typically from React Query's refetch).
 * @param {number} [interval=30000] The polling interval in milliseconds (default: 30000ms).
 *
 * @returns {null} This hook does not return any value; it only triggers refetching.
 */
function usePolling(refetch, interval = 30000) {
  const intervalRef = useRef(null);
  const isPageVisible = useRef(document.visibilityState === "visible");

  useEffect(() => {
    const fetchData = () => {
      if (isPageVisible.current) {
        refetch();
      }
    };

    const handleVisibilityChange = () => {
      isPageVisible.current = document.visibilityState === "visible";
      if (isPageVisible.current) {
        fetchData();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    fetchData(); // initial fetch
    intervalRef.current = setInterval(fetchData, interval);

    return () => {
      clearInterval(intervalRef.current);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [refetch, interval]);

  return null;
}

export default usePolling;
