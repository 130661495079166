import { useState, useEffect } from "react";

import { Modal, Slider, InputNumber, Button } from "antd";
import { t } from "i18next";
import PropTypes, { number } from "prop-types";

const propTypes = {
  max: PropTypes.number,
  open: PropTypes.bool,
  initialRange: PropTypes.objectOf(number),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

const defaultProps = {
  max: 0,
  open: false,
  initialRange: { min: 0, max: 0 },
  onClose: () => {},
  onSubmit: () => {},
};

function ConfigureExportModal({ max, open, initialRange, onClose, onSubmit }) {
  const [range, setRange] = useState({});

  useEffect(() => {
    if (initialRange) {
      const { min, max } = initialRange;
      setRange({ min: min < max ? min : 0, max });
    }

    if (!initialRange && max) {
      setRange({ min: 0, max });
    }
  }, [max, initialRange, open]);

  const onChange = (value) => {
    if (value[0] < value[1]) {
      setRange({ min: value[0], max: value[1] });
    }
  };

  const onChangeMin = (value) => {
    if (range.max > value) {
      setRange({ ...range, min: value });
    }
  };

  const onChangeMax = (value) => {
    if (range.min < value) {
      setRange({ ...range, max: value });
    }
  };

  return (
    <Modal
      className="alarm-modal"
      maskClosable={false}
      open={open}
      footer={null}
      width={680}
      onCancel={onClose}
    >
      <h2 className="uppercase tracking-widest font-light">
        {t("configure_export.configure_export_title")}
      </h2>
      <div className="mb-12">
        {t("configure_export.messages_first")} {max.toLocaleString()}{" "}
        {t("configure_export.messages_second")}
      </div>
      <div className="flex items-center justify-between ">
        <InputNumber
          className="bg-transparent border-triple-blue w-20"
          controls={false}
          min={0}
          max={range.max - 1}
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }
          value={range.min}
          onChange={onChangeMin}
        />
        <div className="flex items-center">
          <InputNumber
            className="bg-transparent w-20 mr-4 border-triple-blue"
            controls={false}
            min={0}
            max={max}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            value={range.max}
            onChange={onChangeMax}
          />
          <span className="hidden sm:inline">
            / <span className="inline-block ml-2">{max.toLocaleString()}</span>
          </span>
        </div>
      </div>
      <Slider
        className="w-11/12 mx-auto mb-12"
        controls={false}
        defaultValue={[range.min, range.max]}
        min={0}
        max={max}
        range
        tooltip={{ open: false }}
        value={[range.min, range.max]}
        onChange={onChange}
      />
      <p className="text-sm mb-6">{t("configure_export.messages_third")}</p>
      <Button
        type="primary"
        size="large"
        className="flex ml-auto"
        onClick={() => {
          onSubmit(range);
          onClose(true);
        }}
      >
        {t("configure_export.configure")}
      </Button>
    </Modal>
  );
}

ConfigureExportModal.propTypes = propTypes;
ConfigureExportModal.defaultProps = defaultProps;

export default ConfigureExportModal;
