import { useEffect } from "react";

import { Select, Spin } from "antd";
import { t } from "i18next";

// import usePropertyFloorById from "../../../../api/hooks/usePropertyFloorById";
import useScheduleProperty from "../../../../api/hooks/useScheduleProperty";
import useScheduleSystems from "../../../../hooks/useScheduleSystems";

function SelectFloorInput() {
  const { scheduleSystems, setScheduleSystems } = useScheduleSystems();
  const isSelectedAllProperties =
    scheduleSystems.selectedPropertyId === t("statistics_filters.all");
  const isSelectedAll =
    scheduleSystems.selectedFloorId === t("statistics_filters.all");
  const selectValue =
    scheduleSystems?.selectedFloor?.Name ||
    (isSelectedAll ? t("statistics_filters.all_floors") : undefined);

  const {
    data: selectedProperties,
    status: floorStatus,
    refetch: getFloorById,
    remove: removeGetFLoorByIdQuery,
  } = useScheduleProperty(
    {
      propertyId: scheduleSystems.selectedPropertyId,
      floorId: scheduleSystems.selectedFloorId,
    },
    {
      enabled: false,
      cacheTime: 0,
    },
  );

  if (selectedProperties) {
    const selectedFloor =
      Array.isArray(selectedProperties) && selectedProperties.length > 0
        ? {
            ...selectedProperties[0].Floors.filter(
              (floor) => floor.Id === scheduleSystems.selectedFloorId,
            )[0],
          }
        : {}; // assume it's an object

    if (
      (selectedFloor || isSelectedAll) &&
      scheduleSystems.selectedFloor?.Id !== selectedFloor?.Id
    ) {
      setScheduleSystems((prevState) => ({
        ...prevState,
        selectedFloor,
      }));
    }
  }

  useEffect(() => {
    if (scheduleSystems.selectedFloorId && !isSelectedAll) {
      getFloorById();
    }
    return () => {
      removeGetFLoorByIdQuery();
    };
  }, [
    scheduleSystems.selectedFloorId,
    isSelectedAll,
    getFloorById,
    removeGetFLoorByIdQuery,
  ]);

  const handleChange = (value) => {
    if (value) {
      setScheduleSystems((prevState) => ({
        ...prevState,
        selectedFloorId: value,
        selectedDeviceType: null,
        systemsWithSelectedDevice: [],
        selectedSystemsWithSelectedDevice: [],
        confirmedSystemsWithSelectedDevice: [],
        selectedDevicesUniqueIds: [],
        checkedSystemsKeys: [],
        checkedDevicesKeys: [],
      }));
    }
  };

  const handleClear = () => {
    setScheduleSystems((prevState) => ({
      ...prevState,
      selectedFloorId: null,
      selectedFloor: null,
      selectedDeviceType: null,
      systemsWithSelectedDevice: [],
      selectedSystemsWithSelectedDevice: [],
      confirmedSystemsWithSelectedDevice: [],
      selectedDevicesUniqueIds: [],
      checkedSystemsKeys: [],
      checkedDevicesKeys: [],
    }));
  };

  return (
    <Select
      allowClear
      className="w-full"
      disabled={!scheduleSystems.selectedPropertyId || isSelectedAllProperties}
      filterOption={(input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
      showSearch
      size="large"
      value={selectValue}
      options={
        scheduleSystems.selectedProperty
          ? [
              {
                label: t("statistics_filters.all_floors"),
                value: "all",
              },
              ...scheduleSystems.selectedProperty.Floors.map((floor) => {
                return {
                  label: floor?.Name,
                  value: floor?.Id,
                };
              }),
            ]
          : []
      }
      placeholder={t("events_filter.floor")}
      defaultActiveFirstOption
      notFoundContent={
        floorStatus === "loading" ? (
          <div className="flex items-center justify-center p-8">
            <Spin size="small" />
          </div>
        ) : (
          <div className="flex items-center justify-center p-8">
            <span>{t("statistics_filters.no_floors")}</span>
          </div>
        )
      }
      getPopupContainer={(trigger) => trigger.parentNode}
      onChange={handleChange}
      onClear={handleClear}
    />
  );
}

export default SelectFloorInput;
