import { FrownOutlined } from "@ant-design/icons";
import { notification } from "antd";

async function useHandleCreateUser(
  formValues,
  createUserForm,
  setIsLoading,
  setIsFormDirty,
  setIsCreateUserModalOpen,
  refetchUsersForManagement,
  refetchUsersLicensesCount,
  createCmsOperatorMutationFn,
) {
  let createUserResponse;
  // Remove extra whitespaces from a string and ensure there is only one space between.
  const nameParts = formValues.FullName.replace(/\s+/g, " ").trim().split(" ");
  const firstName = nameParts[0];
  // The rest of the array elements are joined back together to form the last name.
  const lastName = nameParts.length > 1 ? nameParts.slice(1).join(" ") : "";
  const request = {
    FirstName: firstName,
    LastName: lastName,
    ...formValues,
  };

  try {
    createUserResponse = await createCmsOperatorMutationFn.mutateAsync({
      request,
    });
  } catch (error) {
    notification.error({
      message: (
        <span className="uppercase tracking-widest">Something went wrong!</span>
      ),
      description:
        error.response && error.response.status !== 500 && error.response.data
          ? error.response.data
          : undefined,
      duration: 0,
      placement: "topRight",
      icon: <FrownOutlined className="text-triple-red" />,
    });
  } finally {
    setIsLoading(false);
    notification.success({
      message: <span className="uppercase tracking-widest">User created</span>,
      duration: 2,
      placement: "topRight",
    });
    setIsFormDirty(false);
    setIsCreateUserModalOpen(false);
    createUserForm.resetFields();
    refetchUsersForManagement();
    refetchUsersLicensesCount();
  }

  return createUserResponse;
}

export default useHandleCreateUser;
