import PropTypes from "prop-types";

function RU({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 85.333 512 341.333"
      width="22"
      height="22"
      className={className}
    >
      <path fill="#FFF" d="M0 85.33v341.332h512V85.33z" />
      <path fill="#0052B4" d="M0 85.333h512V426.67H0z" />
      <path fill="#FFF" d="M0 85.333h512v113.775H0z" />
      <path fill="#D80027" d="M0 312.884h512v113.775H0z" />
    </svg>
  );
}

RU.propTypes = {
  className: PropTypes.string,
};

RU.defaultProps = {
  className: "",
};

export default RU;
