import { Col, Row } from "antd";
import Search from "antd/es/transfer/search";
import PropTypes from "prop-types";

import useWindowSize from "../../../../../hooks/useWindowSize";

function NameFilterGroup({ handleSearchChange }) {
  const window = useWindowSize();
  if (window.width > 1024) {
    return (
      <Row>
        <Col xl={12} md={12} className="pr-24" />
        <Col
          xl={12}
          sm={24}
          className="inline-flex items-center justify-center my-4 pl-20"
        >
          <Search
            className="w-full"
            placeholder="Search"
            onChange={handleSearchChange}
            allowClear
            size="middle"
          />
        </Col>
      </Row>
    );
  }
  if (window.width <= 1024) {
    return (
      <div className="mb-4">
        <Search
          className="w-full mr-2"
          placeholder="Search Systems"
          onChange={handleSearchChange}
          allowClear
          size="middle"
        />
      </div>
    );
  }
}

NameFilterGroup.propTypes = {
  handleSearchChange: PropTypes.func,
};
NameFilterGroup.defaultProps = {
  handleSearchChange: () => {},
};

export default NameFilterGroup;
