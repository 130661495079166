import { useEffect } from "react";

import { EditOutlined } from "@ant-design/icons";
import { Button } from "antd";
import dayjs from "dayjs";
import { t } from "i18next";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

import useScheduleSystems from "../../../hooks/useScheduleSystems";
import ScrollableTable from "../../ScrollableTable/ScrollableTable";
import AddSceneButton from "../AddSceneButton/AddSceneButton";
import DeleteScene from "../DeleteScene/DeleteScene";

import "./ScheduleCalendarTable.scss";

const isoWeek = require("dayjs/plugin/isoWeek");
const weekday = require("dayjs/plugin/weekday");

dayjs.extend(weekday);
dayjs.extend(isoWeek);

const propTypes = {
  className: PropTypes.string,
  schedule: PropTypes.any,
  isLoading: PropTypes.bool,
};

const defaultProps = {
  className: "",
  schedule: null,
  isLoading: false,
};

const defaultInterval = {
  id: "",
  name: "",
  startTime: "",
  endTime: "",
  days: [],
};

function SceneCardDetails(interval) {
  const { interval: data } = interval;
  const { name, startTime, endTime } = data;
  // eslint-disable-next-line no-unused-vars
  const [_, setSearchParams] = useSearchParams();

  const handleOnClick = () => {
    setSearchParams((params) => {
      params.set("editScene", "t");
      return params;
    });
  };

  return (
    <div className="scene-card relative ">
      <div className="hidden group-hover:flex items-center justify-evenly absolute top-0 left-0 bottom-0 right-0 bg-[#343646] bg-opacity-80 text-triple-blue text-18 cursor-pointer transition-all">
        <Button type="link" className="text-lg" onClick={handleOnClick}>
          <EditOutlined />
        </Button>
        <DeleteScene interval={interval} />
      </div>
      <span className="text-xs truncate" title={name}>
        {name}
      </span>
      <span className="text-xs">
        {startTime} - {endTime}
      </span>
    </div>
  );
}

function ScheduleCalendarTable({ className, schedule, isLoading }) {
  // eslint-disable-next-line no-unused-vars
  const [searchParams, _] = useSearchParams();
  const programId = searchParams.get("programId");
  const { scheduleSystems, setScheduleSystems } = useScheduleSystems();

  const daysOfWeek = {
    1: "mon",
    2: "tue",
    3: "wed",
    4: "thu",
    5: "fri",
    6: "sat",
    0: "sun",
  };

  // TODO: Find a way to merge cells with longer periods;
  // let timeDuration;
  // let startIndex;
  // const rowSpanZeroIndexes = new Set();
  // const handleOnCell = (record, index, day) => {
  //   const start = Number(record[day]?.startTime?.substring(0, 2));
  //   const end = Number(record[day]?.endTime?.substring(0, 2));
  //   const duration = end - start;
  //   if (record[day]?.name) {
  //     startIndex = start - 1;
  //     timeDuration = duration;
  //   }
  //   if (timeDuration > 0) {
  //     if (index === startIndex) {
  //       for (let i = 1; i < timeDuration; i += 1) {
  //         rowSpanZeroIndexes.add(index + i);
  //       }
  //       return { rowSpan: timeDuration };
  //     }
  //     if (rowSpanZeroIndexes.has(index)) {
  //       return { rowSpan: 0 };
  //     }
  //   }
  //   return {};
  // };

  const daysOfWeekColumns = Object.values(daysOfWeek)?.map((day) => {
    return {
      title: day.toUpperCase(),
      key: day,
      dataIndex: day,
      render: (interval, record, index) => (
        <div className="scene-wrapper group">
          {interval.startTime && <SceneCardDetails interval={interval} />}
          {!interval.startTime && !programId && (
            <AddSceneButton day={day} hour={index + 1} />
          )}
        </div>
      ),
      width: 150,
      align: "center",
      // onCell: (record, index) => handleOnCell(record, index, day),
    };
  });

  const columns = [
    {
      title: t("time_picker.time"),
      key: "time",
      dataIndex: "time",
      render: (time) => {
        return (
          <span className="text-white text-opacity-80 font-light">{time}</span>
        );
      },
      width: 100,
      align: "center",
      fixed: "left",
    },
    ...daysOfWeekColumns,
  ];

  const hoursAM = [...Array(12).keys()].map((i) => ({
    time: `${i + 1} am`,
    sun: defaultInterval,
    mon: defaultInterval,
    tue: defaultInterval,
    wed: defaultInterval,
    thu: defaultInterval,
    fri: defaultInterval,
    sat: defaultInterval,
  }));

  const hoursPM = [...Array(12).keys()].map((i) => ({
    time: `${i + 1} pm`,
    sun: defaultInterval,
    mon: defaultInterval,
    tue: defaultInterval,
    wed: defaultInterval,
    thu: defaultInterval,
    fri: defaultInterval,
    sat: defaultInterval,
  }));

  const data = [...hoursAM, ...hoursPM];

  useEffect(() => {
    if (schedule) {
      const startAtEpoch = schedule.Intervals[0]?.Start;
      const startAt = dayjs(startAtEpoch * 1000);
      const duration = schedule.Intervals[0]?.Duration;
      const endAt = dayjs(startAt).add(duration, "minute");

      const intervalDays = schedule?.Intervals.map((interval) => {
        const startAtEpoch = interval?.Start;
        const startAt = dayjs(startAtEpoch * 1000);
        const dayOfWeek = daysOfWeek[dayjs(startAt).day()];

        return dayOfWeek;
      });

      setScheduleSystems({
        ...scheduleSystems,
        scheduleIntervals: [
          {
            name: schedule.Name || "Unnamed scene",
            startTime: dayjs(startAt).format("HH:mm"),
            endTime: endAt.format("HH:mm"),
            days: intervalDays,
          },
        ],
      });
    }
  }, [schedule]);

  useEffect(() => {
    const row = document.querySelector(
      "[data-row-key] > td > div.scene-wrapper.group > div.scene-card.relative",
    );
    if (row) {
      row.scrollIntoView(true, { behavior: "smooth" });
    }
  });

  const hours = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "00",
  ];

  scheduleSystems.scheduleIntervals?.map((interval) => {
    const { startTime } = interval;
    const hoursSubstring = startTime?.substr(0, 2);
    const index = hours.indexOf(hoursSubstring);

    interval?.days.map((dayString) => {
      const day = dayString.substr(0, 3).toLowerCase();
      data[index][day] = interval;
      return null;
    });
    return null;
  });

  return (
    <ScrollableTable
      columns={columns}
      dataSource={data}
      className={`schedule-calendar-table ${className}`}
      size="small"
      isLoading={isLoading}
    />
  );
}

ScheduleCalendarTable.propTypes = propTypes;
ScheduleCalendarTable.defaultProps = defaultProps;

export default ScheduleCalendarTable;
