import { Col, Divider, Row } from "antd";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import useWindowSize from "../../../../../hooks/useWindowSize";
import Loader from "../../../../Loader/Loader";
import PropertyWaterConsumptionChart from "../../../../PropertyOverview/PropertyCharts/PropertyWaterConsumptionChart";
import PropertyWaterSavingsChart from "../../../../PropertyOverview/PropertyCharts/PropertyWaterSavingsChart";
import FloorManagementPropertyDetails from "./FloorManagementPropertyDetails";
import FloorManagementPropertyImage from "./FloorManagementPropertyImage";
import "../scss/FloorManagement.scss";

function FloorManagementPropertyCard({
  objectProperty,
  waterConsumptionCollectionData,
  overviewStatusesCollectionData,
  waterConsumptionIsLoading,
  overviewStatusesIsLoading,
}) {
  const navigate = useNavigate();
  const window = useWindowSize();
  const { Id } = objectProperty;
  const waterConsumption = waterConsumptionCollectionData?.find(
    (waterConsumption) => waterConsumption.Id === Id,
  );
  const overviewStatus = overviewStatusesCollectionData?.find(
    (overviewStatus) => overviewStatus.Id === Id,
  );
  const propertyAlertStatuses = overviewStatus?.SystemStatuses?.filter(
    (s) => s.Severity === "Alert",
  );
  return (
    <div
      role="none"
      className={`floor-management ${
        propertyAlertStatuses?.length > 0
          ? "border-triple-red"
          : "border-triple-blue"
      } `}
      onClick={() => {
        navigate(
          `/preferences/property-management/floor-management/property/${objectProperty?.Id}`,
          {
            state: {
              property: {
                ...objectProperty,
              },
            },
          },
        );
      }}
    >
      <Row className="h-full">
        <Col xs={{ span: 24 }} lg={{ span: 6 }}>
          <FloorManagementPropertyImage imageUrl={objectProperty?.ImageUrl} />
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 6 }}>
          <FloorManagementPropertyDetails
            property={objectProperty}
            overviewStatus={overviewStatus}
            overviewStatusesIsLoading={overviewStatusesIsLoading}
          />
        </Col>
        <Col
          xs={{ span: 22, offset: 1 }}
          lg={{ span: 1 }}
          className="flex items-center justify-center lg:m-0"
        >
          <Divider
            type={window?.width > 992 ? "vertical" : "horizontal"}
            className="lg:h-4/5 m-0"
          />
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 11 }}>
          <div className="property-charts">
            {waterConsumptionIsLoading && <Loader className="justify-center" />}
            {waterConsumption && (
              <PropertyWaterConsumptionChart
                className="justify-between"
                data={waterConsumption?.TotalWaterConsumption}
              />
            )}
            {window?.width < 768 && window?.width > 576 && (
              <Divider type="horizontal" className="my-4 lg:h-4/5 lg:my-0" />
            )}
            {waterConsumptionIsLoading && <Loader className="justify-center" />}
            {waterConsumption && (
              <PropertyWaterSavingsChart
                className="justify-between"
                percent={
                  waterConsumption?.TotalWaterConsumption
                    ?.WaterSavingsPercentage
                }
              />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}

FloorManagementPropertyCard.propTypes = {
  objectProperty: PropTypes.object,
  waterConsumptionCollectionData: PropTypes.array,
  overviewStatusesCollectionData: PropTypes.array,
  waterConsumptionIsLoading: PropTypes.bool,
  overviewStatusesIsLoading: PropTypes.bool,
};

FloorManagementPropertyCard.defaultProps = {
  objectProperty: {},
  waterConsumptionCollectionData: [],
  overviewStatusesCollectionData: [],
  waterConsumptionIsLoading: false,
  overviewStatusesIsLoading: false,
};
export default FloorManagementPropertyCard;
