import { useState } from "react";

import { Select } from "antd";
import PropTypes from "prop-types";

import { usePropertyOverviewMetadata } from "../../../api/hooks/usePropertyOverview";

function DropdownFilter({ onPropertyChange }) {
  const [selectedValue, setSelectedValue] = useState("");
  const onChangeProperty = (value, option) => {
    setSelectedValue(value || "");
    onPropertyChange({
      value: value || "",
      label: option ? option.label : "All",
    });
  };

  // Use PropertyOverview/Metadata GET Endpoint to populate the dropdown filter.
  const { data } = usePropertyOverviewMetadata();

  const dropdownListOptionAll = { value: "", label: "All" };
  const dropdownListOptions = data?.map((property) => {
    return {
      value: property.Id,
      label: `${property?.Name}, ${property?.Address}`,
    };
  });
  const options = [dropdownListOptionAll, ...(dropdownListOptions ?? [])];

  return (
    <Select
      dropdownStyle={{
        backgroundColor: "#141D37",
      }}
      value={selectedValue}
      className="w-1/4 flex items-center"
      showSearch
      allowClear
      onClear={() => {
        setSelectedValue("");
      }}
      size="small"
      placeholder="Select a property"
      optionFilterProp="label"
      onChange={(value, option) => {
        onChangeProperty(value, option);
      }}
      options={options}
    />
  );
}
DropdownFilter.propTypes = {
  onPropertyChange: PropTypes.func,
};
DropdownFilter.defaultProps = {
  onPropertyChange: () => {},
};
export default DropdownFilter;
