/* eslint-disable no-unused-vars */
import {
  CaretRightOutlined,
  CaretDownOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Table, Skeleton, Popover } from "antd";
import { t } from "i18next";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { SystemIcon, ZoneIcon } from "../../../assets/icons/systems";
import useBasePath from "../../../hooks/useBasePath";
import EventIcon from "../../Events/EventIcon";

// IMP: data should be formatted as shown belown
// in order to be expandable
// const data = [
//   {
//     key: 1,
//     name: "David's Office",
//     status: "Alert",
//     location: "Main Pipe",
//     note: "",
//     children: [
//       {
//         key: 11,
//         id: 11,
//         name: "Main Flow Sensor",
//         status: "Alert",
//         location: "Waterline",
//         note: "",
//       },
//       {
//         key: 12,
//         id: 12,
//         name: "Consumption Meter",
//         status: "OK",
//         location: "Second Pipe",
//         note: "",
//         children: [
//           {
//             key: 121,
//             name: "Jim Green jr.",
//             age: 25,
//             address: "London No. 3 Lake Park",
//           },
//         ],
//       },
//     ],
//   },
//   {
//     key: 2,
//     id: 2,
//     name: "Liky's Studio",
//     status: "OK",
//     location: "Waterline",
//     note: "",
//     children: [
//       {
//         key: 21,
//         id: 21,
//         name: "Main Flow Sensor",
//         status: "Alert",
//         location: "Waterline",
//         note: "",
//       },
//       {
//         key: 22,
//         id: 22,
//         name: "Consumption Meter",
//         status: "OK",
//         location: "Second Pipe",
//         note: "",
//       },
//     ],
//   },
//   {
//     key: 3,
//     id: 3,
//     name: "Lia's Appartment",
//     status: "Alert",
//     location: "Waterline",
//     note: "",
//   },
// ];

const propTypes = {
  className: PropTypes.string,
  floor: PropTypes.any,
  status: PropTypes.string,
  floorOverviewStatus: PropTypes.object,
};
const defaultProps = {
  className: "",
  floor: "",
  status: "",
  floorOverviewStatus: {},
};

function FloorSystemsTable({ className, floor, status, floorOverviewStatus }) {
  const navigate = useNavigate();
  const basePath = useBasePath();

  const formattedData = floor?.Systems?.map((system, index) => {
    const { SystemDevicesOnCurrentFloor: Devices, Name, ...rest } = system;

    if (Devices?.length > 0) {
      const [systemHub] = Devices.filter(
        (device) => device.PublicTypeName === "Hub",
      );

      const formattedSystemHub = {
        key: `${index + 1}1`,
        ...systemHub,
      };

      const devicesWithZone = Devices?.filter(
        (device) => device.Zone && device.PublicTypeName !== "Hub",
      );

      const allZonesFromDevices = devicesWithZone
        ?.map((device) => {
          const { Zone } = device;
          return Zone;
        })
        .filter((zone) => zone);

      const uniqueZoneIds = [
        ...new Set(allZonesFromDevices.map((zone) => zone.Id)),
      ];

      const formattedZones = uniqueZoneIds.map((zoneId, zoneIndex) => {
        const [zone] = allZonesFromDevices.filter((zone) => zone.Id === zoneId);

        const zoneDevices = devicesWithZone
          .map((device, deviceIndex) =>
            device.Zone.Id === zoneId
              ? {
                  // zoneIndex + 2 -> zero based + extra 1 for hub
                  key: `${index + 1}${zoneIndex + 2}${deviceIndex + 1}`,
                  ...device,
                }
              : null,
          )
          .filter((device) => device);

        return {
          key: `${index + 1}${zoneIndex + 2}`,

          ZoneName: zone.Name,
          Status: zone.Status,
          children: zoneDevices,
        };
      });

      const devicesWithoutZone = Devices?.filter(
        (device) => !device.Zone && device.PublicTypeName !== "Hub",
      );

      const formattedDevicesWithoutZone = devicesWithoutZone.map(
        (device, deviceIndex) => {
          return {
            key: `${index + 1}${1 + uniqueZoneIds.length + 1 + deviceIndex}`,
            ...device,
          };
        },
      );

      const systemsWithFloorsFormatted = {
        key: index + 1,
        SystemName: Name,
        ...rest,
        children: [...formattedZones, ...formattedDevicesWithoutZone],
      };

      // Do not render an empty row if the Hub is not presented
      // unshift will add the Hub in the first row if present.
      if (systemHub) {
        systemsWithFloorsFormatted.children.unshift(formattedSystemHub);
      }
      return systemsWithFloorsFormatted;
    }

    return {
      key: index + 1,
      SystemName: Name,
      ...rest,
    };
  });

  const columns = [
    {
      title: t("floor_system_tables.name"),
      render: (record) => {
        const systemStatus = floorOverviewStatus?.SystemStatuses?.find(
          (x) => x.UniqueId === record?.UniqueId,
        );
        const deviceStatus = floorOverviewStatus?.SystemStatuses?.flatMap(
          (system) => system.DeviceStatuses,
        )?.find((d) => d.DeviceId === record.Id);

        const systemIconClassName = systemStatus
          ? `${systemStatus.Status?.toLowerCase()}-system mr-2`
          : "na-system-status mr-2";

        const eventIconClassName = deviceStatus
          ? `${deviceStatus.Severity?.toLowerCase()}-event mr-2`
          : "na-device-status mr-2";

        return (
          <div className="inline-flex items-center">
            {record?.ZoneName && <ZoneIcon className="mr-2" />}
            {record?.SystemName && (
              <SystemIcon className={systemIconClassName} />
            )}
            {record?.PublicTypeName && (
              <EventIcon
                key={record?.Id}
                iconName={record?.PublicTypeName}
                className={eventIconClassName}
                defaultIcon={
                  <InfoCircleOutlined className={eventIconClassName} />
                }
              />
            )}

            {record?.Name || // custom given device name
              record?.PublicTypeName || // generic device name
              record?.ZoneName || // zone name
              record?.SystemName || // system name
              "-"}

            {/* Display (count/count) only on the system */}
            {record?.SystemName && (
              <Popover
                className="ml-1"
                color="#363346"
                overlayInnerStyle={{
                  borderColor: "#ffffff",
                  borderWidth: 1,
                  borderStyle: "solid",
                  padding: 5,
                  fontWeight: 400,
                  maxWidth: 300,
                }}
                content={
                  <div className="flex flex-col ml-4">
                    <ul className="list-disc">
                      {record?.HubIsOnAnotherFloor && (
                        <li>
                          {t("floor_system_tables.hub_at")}{" "}
                          {record?.HubFloorName}
                        </li>
                      )}
                      <li>
                        {record?.SystemDevicesOnCurrentFloorCount}{" "}
                        {t("floor_system_tables.out_of")}{" "}
                        {record?.SystemDevicesTotalCount}{" "}
                        {t("floor_system_tables.device_on_floor")}
                      </li>
                    </ul>
                  </div>
                }
                destroyTooltipOnHide
              >
                {`(${record.SystemDevicesOnCurrentFloorCount}/${record?.SystemDevicesTotalCount})`}
                <InfoCircleOutlined className="ml-2 mt-1" />
              </Popover>
            )}
          </div>
        );
      },
    },
    {
      title: t("floor_system_tables.status"),
      render: (record) => {
        const systemStatus = floorOverviewStatus?.SystemStatuses?.find(
          (x) => x.UniqueId === record?.UniqueId,
        );
        const deviceStatus = floorOverviewStatus?.SystemStatuses?.flatMap(
          (system) => system.DeviceStatuses,
        )?.find((d) => d.DeviceId === record.Id);
        return (
          <span>{systemStatus?.Status || deviceStatus?.Status || "-"}</span>
        );
      },
    },
    {
      title: t("floor_system_tables.serial_number"),
      render: (record) => (
        <span>{record?.HubSerialNumber || record?.SerialNumber || "-"}</span>
      ),
    },
    {
      title: t("floor_system_tables.location"),
      render: (record) => (
        <span>{record?.HubLocation || record?.Location || "-"}</span>
      ),
    },
  ];

  // TODO: refactor to use CustomTable
  // columns for skeleton table (loading state)
  const ghostTableColumns = columns.map((column) => {
    return {
      ...column,
      render: function renderPlaceholder() {
        return <Skeleton key={column.key} active paragraph={false} />;
      },
    };
  });

  // data for skeleton table (loading state)
  const ghostTableData = [...Array(5)].map((_, index) => ({
    key: `key${index}`,
  }));

  return (
    <Table
      dataSource={status === "loading" ? ghostTableData : formattedData}
      columns={status === "loading" ? ghostTableColumns : columns}
      rowKey="key"
      pagination={{ hideOnSinglePage: true }}
      className={`floor-systems-table ${className}`}
      size="middle"
      expandable={{
        // eslint-disable-next-line react/no-unstable-nested-components, no-unused-vars
        expandIcon: ({ expanded, onExpand, record }) => {
          const isRecordExpandable = !!record?.children;
          if (isRecordExpandable) {
            return expanded ? (
              <CaretDownOutlined className="mr-2 inline-flex items-center" />
            ) : (
              <CaretRightOutlined className="mr-2 inline-flex items-center" />
            );
          }

          return null;
        },
        expandRowByClick: true,
        indentSize: 44,
      }}
      // rowClassName={(record) =>
      //   record?.id === floor?.Id ? "bg-[#51566E]" : ""
      // }
      onRow={(record) => {
        if (!record?.PublicTypeName) {
          return null;
        }

        return {
          onClick: () => {
            navigate({
              pathname:
                basePath === "/property-overview/property/floor"
                  ? `device-details/${record.UniqueId}`
                  : `floor/device-details/${record.UniqueId}`,
            });
          },
        };
      }}
    />
  );
}

FloorSystemsTable.propTypes = propTypes;
FloorSystemsTable.defaultProps = defaultProps;

export default FloorSystemsTable;
