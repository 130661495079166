import { FrownOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

import "./ErrorPages.scss";

function PageNotFound() {
  const navigate = useNavigate();

  return (
    <div className="error-page">
      <div className="text-center">
        <FrownOutlined className="page-icon" />
        <h3 className="page-title">{t("custom_table.we_have_bad_news")}</h3>
        <h2 className="page-subtitle">{t("custom_table.msg_deleted")}</h2>
        <Button size="large" className="mt-10" onClick={() => navigate("/")}>
          {t("custom_table.go_back")}
        </Button>
      </div>
    </div>
  );
}

export default PageNotFound;
