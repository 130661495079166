import { t } from "i18next";

import {
  PropertyManagementIcon,
  UsersManagementIcon,
} from "../../assets/icons/preferences-tiles-icons";
import ReportsManagementIcon from "../../assets/icons/ReportsManagementIcon";
import useWindowSize from "../../hooks/useWindowSize";
import MenuTile from "../Shared/MenuTile";

function PreferencesMenuPage() {
  const window = useWindowSize();

  return (
    <>
      {window?.width > 768 && (
        <h1 className="page-title mb-0 text-center sm:text-left !text-triple-blue">
          {/* {t("preferences.preferences")} */}
          {t("preferences.preferences")}
        </h1>
      )}

      {window?.width <= 768 && (
        <h1 className="page-title-mobile mb-0 text-center sm:text-left uppercase !text-triple-blue">
          {t("preferences.preferences")}
        </h1>
      )}
      <div className="mt-2 flex md:flex-row flex-col md:items-start items-center gap-5">
        <MenuTile
          title={t("preferences.property_management")}
          icon={<PropertyManagementIcon />}
          navigateTo="property-management"
        />
        <MenuTile
          title={t("preferences.user_management")}
          icon={<UsersManagementIcon />}
          navigateTo="/preferences/users"
        />
        <MenuTile
          title={t("preferences.report_magagement")}
          icon={<ReportsManagementIcon />}
          navigateTo="/preferences/reports"
        />
      </div>
    </>
  );
}

export default PreferencesMenuPage;
