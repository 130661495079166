import PropTypes from "prop-types";

function FR({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 3 2"
      className={className}
      width="22"
      height="22"
    >
      <path xmlns="http://www.w3.org/2000/svg" fill="#EC1920" d="M0 0h3v2H0z" />
      <path xmlns="http://www.w3.org/2000/svg" fill="#fff" d="M0 0h2v2H0z" />
      <path xmlns="http://www.w3.org/2000/svg" fill="#051440" d="M0 0h1v2H0z" />
    </svg>
  );
}

FR.propTypes = {
  className: PropTypes.string,
};

FR.defaultProps = {
  className: "",
};

export default FR;
