import { useState, useEffect } from "react";

import { WarningFilled, PhoneOutlined, MailOutlined } from "@ant-design/icons";
import { Modal, Carousel, Divider } from "antd";
import "./AlarmModal.scss";
import { t } from "i18next";

import useRecentStatuses from "../../../../api/hooks/useRecentStatuses";
import DeviceInAlarmIcon from "../../../../assets/icons/DeviceInAlarmIcon";
import FloorIcon from "../../../../assets/icons/FloorIcon";
import { HubIcon } from "../../../../assets/icons/systems";

const propTypes = {};

const defaultProps = {};

function AlarmModal() {
  const [isOpen, setIsOpen] = useState(false);

  const { data: recentStatuses, status } = useRecentStatuses(
    "?systemStatuses[0]=Alert",
  );

  useEffect(() => {
    if (recentStatuses?.length > 0 && status === "success") {
      setIsOpen(true);
    }
  }, [recentStatuses, status]);

  return (
    <Modal
      className="alarm-modal"
      open={isOpen}
      footer={null}
      onCancel={() => setIsOpen(false)}
    >
      <Carousel className="alarm-modal-carousel">
        {recentStatuses?.map((recentStatus) => (
          <div key={recentStatus?.DeviceStatusKey}>
            <div className="alarm-wrapper">
              <WarningFilled className="alarm-modal-icon" />
              <h1 className="alarm-modal-title">
                {recentStatus.AlertName || "Unknown"}
              </h1>
              <div className="font-light flex items-center">
                {recentStatus.PropertyName && (
                  <>
                    <span>{recentStatus.PropertyName}</span>
                    <Divider type="vertical" />
                  </>
                )}
                {recentStatus.FloorName && (
                  <>
                    <span className="flex items-center">
                      <FloorIcon className="mr-1 fill-red-300" />
                      {recentStatus.FloorName}
                    </span>
                    <Divider type="vertical" />
                  </>
                )}

                {recentStatus.SystemName && (
                  <>
                    <span className="flex items-center">
                      <HubIcon className="mr-2" />
                      {recentStatus.SystemName}
                    </span>
                    <Divider type="vertical" />
                  </>
                )}
                {recentStatus.DeviceTypeName && (
                  <span className="flex items-center">
                    <DeviceInAlarmIcon className="mr-1" />
                    {recentStatus.DeviceTypeName}
                  </span>
                )}
              </div>
              <div className="alarm-modal-message">
                {t("alarm.status_change_from")}&nbsp;
                <span className="uppercase">
                  {recentStatus.PreviousStatus?.split(/(?=[A-Z])/).join(" ") ||
                    "-"}
                </span>
                &nbsp;{t("alarm.to")}&nbsp;
                <span className="uppercase">
                  {recentStatus.Status?.split(/(?=[A-Z])/).join(" ") || "-"}
                </span>
              </div>
              <div className="alarm-modal-contacts">
                <div className="mb-2 sm:mb-0">
                  <PhoneOutlined className="mr-1" />
                  <span>
                    {t("alarm.support")} {recentStatus.SupportPhone}
                  </span>
                </div>
                <Divider type="vertical" />
                <div>
                  <MailOutlined className="mr-1" />
                  <span>{recentStatus.SupportEmail}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </Modal>
  );
}

AlarmModal.propTypes = propTypes;
AlarmModal.defaultProps = defaultProps;

export default AlarmModal;
