function UsersManagementIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="208"
      height="256"
      viewBox="0 0 208 256"
    >
      <path
        fill="#757782"
        fillRule="evenodd"
        transform="translate(-105, -110)"
        d="M272.762,247.763A18.182,18.182,0,0,0,254.6,229.6h-4.744a14.227,14.227,0,1,0-13.22,0h-4.744a18.054,18.054,0,0,0-9.541,2.718,18.608,18.608,0,1,0-28.751-.133,18.05,18.05,0,0,0-9.322-2.585h-4.744a14.227,14.227,0,1,0-13.22,0H161.57a18.182,18.182,0,0,0-18.162,18.162v5.1h27.338a23.658,23.658,0,0,0-1.322,7.814v6.675h77.212v-6.675a23.658,23.658,0,0,0-1.322-7.814h27.448v-5.1Zm-38.1-30.754a8.582,8.582,0,1,1,8.582,8.582A8.591,8.591,0,0,1,234.664,217.009Zm-26.633-7.778a11.225,11.225,0,1,1-11.225,11.225A11.237,11.237,0,0,1,208.031,209.231Zm-43.689,7.778a8.582,8.582,0,1,1,8.582,8.582A8.591,8.591,0,0,1,164.342,217.009Zm-15.277,30.212a12.532,12.532,0,0,1,12.5-11.974h22.708a12.429,12.429,0,0,1,6.46,1.8,23.773,23.773,0,0,0-17.121,10.17H149.065Zm90.172,12.752H176.823a16.25,16.25,0,0,1,1.979-7.106l0.361-.629a16.375,16.375,0,0,1,14.017-7.928h29.7a16.38,16.38,0,0,1,14.122,8.1l0.256,0.459A16.236,16.236,0,0,1,239.237,259.973Zm3.207-12.752a23.77,23.77,0,0,0-17.029-10.159,12.422,12.422,0,0,1,6.477-1.815H254.6a12.532,12.532,0,0,1,12.505,11.974H242.444Z"
      />
    </svg>
  );
}

export default UsersManagementIcon;
