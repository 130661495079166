import { useState } from "react";

import { ExclamationCircleOutlined, FrownOutlined } from "@ant-design/icons";
import { Alert, Modal, Skeleton, Table } from "antd";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";

import { useDeleteHomeAppUser } from "../../../../api/hooks/useMobileAppUsers";
import useWindowSize from "../../../../hooks/useWindowSize";
import UnsavedChangesWarning from "../../../Shared/UnsavedChangesWarning";
import TableColumns from "./helpers/TableColumns";
import useMobileAppUserDelete from "./hooks/useMobileAppUserDelete";
import MobileAppUserEdit from "./MobileAppUserEdit";

const propTypes = {
  data: PropTypes.array,
  status: PropTypes.string,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  totalCount: PropTypes.number,
  onChange: PropTypes.func,
  refetchMobileAppUsers: PropTypes.func,
};

const defaultPropTypes = {
  data: [],
  status: "success",
  pageSize: 14,
  totalCount: 0,
  currentPage: null,
  onChange: () => {},
  refetchMobileAppUsers: () => {},
};

function MobileAppUsersTable({
  data,
  status,
  pageSize,
  totalCount,
  currentPage,
  onChange,
  refetchMobileAppUsers,
}) {
  const window = useWindowSize();
  const [isEditUserModalOpen, setIsEditUserModalOpen] = useState(false);
  const [isAlertUnsavedChangesOpen, setAlertUnsavedChangesOpen] =
    useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [editFormReference, setFormReference] = useState();
  const [searchParams] = useSearchParams();
  const [userId, setUserId] = useState(0);
  const useMobileAppUserDeleteMutationFn = useDeleteHomeAppUser();

  const handleOnCancelEditModal = () => {
    if (isFormDirty) {
      setAlertUnsavedChangesOpen(true);
    } else {
      setIsEditUserModalOpen(false);
      editFormReference.resetFields();
      refetchMobileAppUsers();
    }
  };

  const HandleOnDeleteOk = async (user) => {
    await useMobileAppUserDelete(useMobileAppUserDeleteMutationFn, user);
    refetchMobileAppUsers();
  };

  const handleDeleteClick = (object) => {
    Modal.confirm({
      width: 500,
      title: (
        <div className="text-center uppercase flex flex-col justify-center items-center">
          <ExclamationCircleOutlined
            style={{ fontSize: 36, color: "#ed1c24", marginBottom: "1rem" }}
          />
          are you sure?
        </div>
      ),
      content: (
        <div className="text-center uppercase tracking-wider">
          <p className="my-1">
            Do you want to delete user &quot;{object.Email}&quot; ?
          </p>
          <p className="my-1">This process cannot be undone.</p>
        </div>
      ),
      className: "alert-delete-confirm",
      icon: null,
      onOk() {
        HandleOnDeleteOk(object);
      },
      onCancel() {
        // Do Nothing
      },
      okButtonProps: {
        danger: true,
        className: "btn-leave",
      },
      okText: "Delete",
      cancelButtonProps: { className: "btn-cancel" },
    });
  };

  // columns for skeleton table (loading state)
  const ghostTableColumns = TableColumns({
    setUserId,
    setIsEditUserModalOpen,
    handleDeleteClick,
  }).map((column) => {
    return {
      ...column,
      render: function renderPlaceholder() {
        return <Skeleton key={column.key} active paragraph={false} />;
      },
    };
  });

  // data for skeleton table (loading state)
  const ghostTableData = [...Array(14)].map((_, index) => ({
    key: `key${index}`,
  }));

  if (status === "error") {
    return (
      <div className="flex items-center justify-center">
        <Alert
          message="We have bad news"
          description="Еither we broke something or there is nothing to display."
          type="error"
          showIcon
          icon={<FrownOutlined />}
          className="w-fit"
        />
      </div>
    );
  }

  const mobileAppUsersColumns = TableColumns({
    setUserId,
    setIsEditUserModalOpen,
    handleDeleteClick,
  });

  return (
    <>
      <Table
        className="overflow-y-auto"
        dataSource={status === "loading" ? ghostTableData : data}
        columns={
          status === "loading" ? ghostTableColumns : mobileAppUsersColumns
        }
        rowKey={(record) => record?.Id || Math.random()}
        pagination={{
          current: Number(searchParams.get("page")) || currentPage || 1,
          pageSize,
          total: totalCount,
          showSizeChanger: false,
          size: "small",
          showLessItems: window?.width <= 576,
        }}
        onChange={(pagination) => {
          onChange(pagination);
        }}
      />
      <Modal
        className="mobile-app-user-modal"
        maskClosable={false}
        footer={null}
        open={isEditUserModalOpen}
        keyboard={false}
        onCancel={handleOnCancelEditModal}
        destroyOnClose
        wrapClassName="custom-mobile-app-user-modal-content"
        width={725}
        bodyStyle={{
          height: 500,
          overflowY: "auto",
          marginTop: "1.5rem",
        }}
      >
        <MobileAppUserEdit
          setIsFormDirty={setIsFormDirty}
          setFormReference={setFormReference}
          userId={userId}
        />
      </Modal>
      <UnsavedChangesWarning
        onSaveClick={() => {
          setAlertUnsavedChangesOpen(false);
          editFormReference.submit();
        }}
        onDontSaveClick={() => {
          setAlertUnsavedChangesOpen(false);
          setIsEditUserModalOpen(false);
          setIsFormDirty(false);
          editFormReference.resetFields();
          refetchMobileAppUsers();
        }}
        onCancelClick={() => {
          setAlertUnsavedChangesOpen(false);
        }}
        isAlertUnsavedChangesOpen={isAlertUnsavedChangesOpen}
      />
    </>
  );
}
MobileAppUsersTable.propTypes = propTypes;
MobileAppUsersTable.defaultProps = defaultPropTypes;

export default MobileAppUsersTable;
