import { useState, useEffect, useCallback, useMemo } from "react";

import { Col, Select, Row } from "antd";
import { t } from "i18next";
import PropTypes from "prop-types";

import useFdsFilters from "../../../api/hooks/useFdsFilters";
import { useAvailableDeviceSerialNumberOptionsContext } from "../../../context/FdsFiltersValuesProvider";

import "./FdsSchedulesListFilters.scss";

function FdsSchedulesListFilters({
  handleOnChangePropertyCallback,
  handleOnChangeFloorCallback,
  handleOnChangeSystemCallback,
  handleOnChangeDeviceTypeCallback,
  handleOnChangeDeviceSerialNumberCallback,
}) {
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);
  const [selectedFloorId, setSelectedFloorId] = useState(null);
  const [selectedSystemId, setSelectedSystemId] = useState(null);
  const [selectedDeviceTypeId, setSelectedDeviceTypeId] = useState(null);
  const [selectedSerialNumberId, setSelectedSerialNumberId] = useState(null);

  const propertyOptions = [
    { value: "all", label: t("statistics_filters.all_properties") },
  ];

  let floorOptionsArray = useMemo(() => {
    return [{ value: "all", label: t("statistics_filters.all_floors") }];
  }, []);
  const [floorOptions, setFloorOptions] = useState(floorOptionsArray);

  let systemOptionsArray = useMemo(() => {
    return [{ value: "all", label: t("statistics_filters.all_systems") }];
  }, []);
  const [systemOptions, setSystemOptions] = useState(systemOptionsArray);

  let deviceTypeOptionsArray = useMemo(() => {
    return [{ value: "all", label: t("statistics_filters.all_device_type") }];
  }, []);
  const [deviceTypeOptions, setDeviceTypeOptions] = useState(
    deviceTypeOptionsArray,
  );

  let serialNumberOptionsArray = useMemo(() => {
    return [{ value: "all", label: t("statistics_filters.all_serial_number") }];
  }, []);
  const [serialNumberOptions, setSerialNumberOptionsArray] = useState(
    serialNumberOptionsArray,
  );

  const {
    updateAvailableSerialNumberOptions,
    updateAvailableServiceUniqueIdOptions,
  } = useAvailableDeviceSerialNumberOptionsContext();

  // Hooks
  const { data: properties } = useFdsFilters();

  if (properties) {
    properties.forEach((p) =>
      propertyOptions.push({ value: p.Id, label: p.Name }),
    );
  }

  // Custom Setters
  const setDistinctOptions = (newValues, setterFunction) => {
    if (Array.isArray(newValues) && typeof setterFunction === "function") {
      const distinctValues = new Set(newValues.map(JSON.stringify));
      setterFunction(Array.from(distinctValues).map(JSON.parse));
    }
  };

  const populateFilters = (property) => {
    floorOptionsArray = [
      { value: "all", label: t("statistics_filters.all_floors") },
    ];
    systemOptionsArray = [
      { value: "all", label: t("statistics_filters.all_systems") },
    ];
    deviceTypeOptionsArray = [
      { value: "all", label: t("statistics_filters.all_device_type") },
    ];
    serialNumberOptionsArray = [
      { value: "all", label: t("statistics_filters.all_serial_numbers") },
    ];

    if (property) {
      property?.Floors?.forEach((f) => {
        floorOptionsArray.push({ value: f.Id, label: f.Name });
        f.Systems.forEach((s) => {
          systemOptionsArray.push({ value: s.UniqueId, label: s.Name });
          s.Devices.forEach((d) => {
            deviceTypeOptionsArray.push({
              value: d.Type.Id,
              label: d.Type.PublicName,
            });
            serialNumberOptionsArray.push({
              value: d.SerialNumber,
              label: d.SerialNumber,
            });
          });
        });
      });
    } else {
      properties?.forEach((property) => {
        property.Floors?.forEach((f) => {
          floorOptionsArray.push({ value: f.Id, label: f.Name });
          f.Systems.forEach((s) => {
            systemOptionsArray.push({ value: s.UniqueId, label: s.Name });
            s.Devices.forEach((d) => {
              deviceTypeOptionsArray.push({
                value: d.Type.Id,
                label: d.Type.PublicName,
              });
              serialNumberOptionsArray.push({
                value: d.SerialNumber,
                label: d.SerialNumber,
              });
            });
          });
        });
      });
    }

    setFloorOptions(floorOptionsArray);
    setSystemOptions(systemOptionsArray);
    setDistinctOptions(deviceTypeOptionsArray, setDeviceTypeOptions);
    setDistinctOptions(serialNumberOptionsArray, setSerialNumberOptionsArray);

    setSelectedFloorId("all");
    setSelectedSystemId("all");
    setSelectedDeviceTypeId("all");
    setSelectedSerialNumberId("all");

    updateAvailableSerialNumberOptions(
      new Set(serialNumberOptionsArray.map(JSON.stringify)),
    );
    updateAvailableServiceUniqueIdOptions(systemOptionsArray);
  };

  useEffect(() => {
    if (properties) {
      properties.forEach((p) =>
        propertyOptions.push({ value: p.Id, label: p.Name }),
      );

      populateFilters();
    } // eslint-disable-next-line
  }, [properties]);

  const updateFloorOptions = useCallback(() => {
    setFloorOptions(floorOptionsArray);
  }, [floorOptionsArray]);

  const updateSystemOptions = useCallback(() => {
    setSystemOptions(systemOptionsArray);
  }, [systemOptionsArray]);

  const updateDeviceTypeOptions = useCallback(() => {
    setDistinctOptions(deviceTypeOptionsArray, setDeviceTypeOptions);
  }, [deviceTypeOptionsArray]);

  const updateSerialNumberOptions = useCallback(() => {
    setDistinctOptions(serialNumberOptionsArray, setSerialNumberOptionsArray);
  }, [serialNumberOptionsArray]);

  useEffect(() => {
    updateFloorOptions();
  }, [updateFloorOptions]);

  useEffect(() => {
    updateSystemOptions();
  }, [updateSystemOptions]);

  useEffect(() => {
    updateDeviceTypeOptions();
  }, [updateDeviceTypeOptions]);

  useEffect(() => {
    updateSerialNumberOptions();
  }, [updateSerialNumberOptions]);

  const onChangeProperty = (value, option) => {
    const property = properties?.find((p) => p.Id === value) || null;
    populateFilters(property);
    setSelectedPropertyId({ PropertyId: value, Property: property });
    handleOnChangePropertyCallback(value, option);
  };

  const onChangeFloor = (value, option) => {
    systemOptionsArray = [
      { value: "all", label: t("statistics_filters.all_systems") },
    ];
    deviceTypeOptionsArray = [
      { value: "all", label: t("statistics_filters.all_device_type") },
    ];
    serialNumberOptionsArray = [
      { value: "all", label: t("statistics_filters.all_serial_numbers") },
    ];

    let floors =
      selectedPropertyId?.Property?.Floors ||
      properties?.flatMap((p) => p.Floors) ||
      [];
    floors =
      floors.filter((f) => (value === "all" ? true : f.Id === value)) || [];

    if (floors) {
      const systems = floors.flatMap((f) => f.Systems);
      systems?.forEach((s) => {
        systemOptionsArray.push({ value: s.UniqueId, label: s.Name });
        s.Devices.forEach((d) => {
          deviceTypeOptionsArray.push({
            value: d.Type.Id,
            label: d.Type.PublicName,
          });
          serialNumberOptionsArray.push({
            value: d.SerialNumber,
            label: d.SerialNumber,
          });
        });
      });
    }

    setSystemOptions(systemOptionsArray);
    setDistinctOptions(deviceTypeOptionsArray, setDeviceTypeOptions);
    setDistinctOptions(serialNumberOptionsArray, setSerialNumberOptionsArray);
    setSelectedFloorId(value);
    setSelectedSystemId("all");
    setSelectedDeviceTypeId("all");
    setSelectedSerialNumberId("all");
    handleOnChangeFloorCallback(value, option);

    updateAvailableSerialNumberOptions(
      new Set(serialNumberOptionsArray.map(JSON.stringify)),
    );
    updateAvailableServiceUniqueIdOptions(systemOptionsArray);
  };

  const onChangeSystem = (value, option) => {
    deviceTypeOptionsArray = [
      { value: "all", label: t("statistics_filters.all_device_type") },
    ];
    serialNumberOptionsArray = [
      { value: "all", label: t("statistics_filters.all_serial_numbers") },
    ];

    let systems =
      selectedPropertyId?.Property?.Floors?.filter((f) =>
        selectedFloorId === "all" ? true : f.Id === selectedFloorId,
      ).flatMap((floor) => floor.Systems) ||
      properties?.flatMap((p) =>
        p.Floors?.filter((f) =>
          selectedFloorId === "all" ? true : f.Id === selectedFloorId,
        ).flatMap((floor) => floor.Systems),
      ) ||
      [];

    systems =
      systems.filter((s) => (value === "all" ? true : s.UniqueId === value)) ||
      [];

    if (systems) {
      systems
        .flatMap((s) => s.Devices)
        ?.forEach((d) => {
          deviceTypeOptionsArray.push({
            value: d.Type.Id,
            label: d.Type.PublicName,
          });
          serialNumberOptionsArray.push({
            value: d.SerialNumber,
            label: d.SerialNumber,
          });
        });
    }

    setDistinctOptions(deviceTypeOptionsArray, setDeviceTypeOptions);
    setDistinctOptions(serialNumberOptionsArray, setSerialNumberOptionsArray);
    setSelectedSystemId(value);
    setSelectedDeviceTypeId("all");
    setSelectedSerialNumberId("all");
    handleOnChangeSystemCallback(value, option);

    updateAvailableSerialNumberOptions(
      new Set(serialNumberOptionsArray.map(JSON.stringify)),
    );

    const systemUniqueIdArray = [];
    systemUniqueIdArray.push(option);
    updateAvailableServiceUniqueIdOptions(systemUniqueIdArray);
  };

  const onChangeDeviceType = (value, option) => {
    serialNumberOptionsArray = [
      { value: "all", label: t("statistics_filters.all_serial_numbers") },
    ];

    const selectedProperty = selectedPropertyId?.Property;
    const devices = selectedProperty
      ? selectedProperty?.Floors?.filter((f) =>
          selectedFloorId === "all" ? true : f.Id === selectedFloorId,
        ).flatMap((f) =>
          f.Systems?.filter((s) =>
            selectedSystemId === "all" ? true : s.UniqueId === selectedSystemId,
          ).flatMap((s) => s.Devices),
        )
      : properties?.flatMap((p) =>
          p.Floors.filter((f) =>
            selectedFloorId === "all" ? true : f.Id === selectedFloorId,
          ).flatMap((f) =>
            f.Systems?.filter((s) =>
              selectedSystemId === "all"
                ? true
                : s.UniqueId === selectedSystemId,
            ).flatMap((s) => s.Devices),
          ),
        ) || [];

    const filteredDevices =
      devices.filter((d) => (value === "all" ? true : d.Type.Id === value)) ||
      [];
    filteredDevices?.forEach((d) => {
      serialNumberOptionsArray.push({
        value: d.SerialNumber,
        label: d.SerialNumber,
      });
    });

    setSelectedDeviceTypeId(value);
    setDistinctOptions(serialNumberOptionsArray, setSerialNumberOptionsArray);
    setSelectedSerialNumberId("all");
    handleOnChangeDeviceTypeCallback(value, option);

    updateAvailableSerialNumberOptions(
      new Set(serialNumberOptionsArray.map(JSON.stringify)),
    );
  };

  const onChangeSerialNumber = (value, option) => {
    setSelectedSerialNumberId(value);
    handleOnChangeDeviceSerialNumberCallback(value, option);

    const serialNumbersArray = [];
    serialNumbersArray.push(option);
    updateAvailableSerialNumberOptions(
      new Set(serialNumbersArray.map(JSON.stringify)),
    );
  };

  const filterSelect = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div className="responsive-filters-container">
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={4} lg={4} xl={4}>
          <Select
            showSearch
            placeholder={t("statistics_filters.select_property")}
            optionFilterProp="property"
            onChange={onChangeProperty}
            filterOption={filterSelect}
            className="responsive-filters"
            defaultValue="all"
            options={propertyOptions}
          />
        </Col>
        <Col xs={24} sm={24} md={4} lg={4} xl={4}>
          <Select
            showSearch
            placeholder={t("statistics_filters.select_floor")}
            optionFilterProp="propertyFloor"
            onChange={onChangeFloor}
            filterOption={filterSelect}
            className="responsive-filters"
            value={selectedFloorId}
            options={floorOptions}
          />
        </Col>
        <Col xs={24} sm={24} md={4} lg={4} xl={4}>
          <Select
            showSearch
            placeholder={t("statistics_filters.select_system")}
            optionFilterProp="propertySystem"
            onChange={onChangeSystem}
            filterOption={filterSelect}
            className="responsive-filters"
            value={selectedSystemId}
            options={systemOptions}
          />
        </Col>
        <Col xs={24} sm={24} md={4} lg={4} xl={4}>
          <Select
            showSearch
            placeholder={t("statistics_filters.select_type")}
            optionFilterProp="propertyDeviceType"
            onChange={onChangeDeviceType}
            filterOption={filterSelect}
            className="responsive-filters"
            value={selectedDeviceTypeId}
            options={deviceTypeOptions}
          />
        </Col>
        <Col xs={24} sm={24} md={4} lg={4} xl={4}>
          <Select
            showSearch
            placeholder={t("statistics_filters.select_serial_number")}
            optionFilterProp="propertySerialNumber"
            onChange={onChangeSerialNumber}
            filterOption={filterSelect}
            className="responsive-filters"
            value={selectedSerialNumberId}
            options={serialNumberOptions}
          />
        </Col>
      </Row>
    </div>
  );
}

FdsSchedulesListFilters.defaultProps = {
  handleOnChangePropertyCallback: () => {},
  handleOnChangeFloorCallback: () => {},
  handleOnChangeSystemCallback: () => {},
  handleOnChangeDeviceTypeCallback: () => {},
  handleOnChangeDeviceSerialNumberCallback: () => {},
};
FdsSchedulesListFilters.propTypes = {
  handleOnChangePropertyCallback: PropTypes.func,
  handleOnChangeFloorCallback: PropTypes.func,
  handleOnChangeSystemCallback: PropTypes.func,
  handleOnChangeDeviceTypeCallback: PropTypes.func,
  handleOnChangeDeviceSerialNumberCallback: PropTypes.func,
};

export default FdsSchedulesListFilters;
