import PropTypes from "prop-types";

function NL({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 9 6"
      className={className}
      width="22"
      height="22"
    >
      <path xmlns="http://www.w3.org/2000/svg" fill="#21468B" d="M0 0h9v6H0z" />
      <path xmlns="http://www.w3.org/2000/svg" fill="#FFF" d="M0 0h9v4H0z" />
      <path xmlns="http://www.w3.org/2000/svg" fill="#AE1C28" d="M0 0h9v2H0z" />
    </svg>
  );
}

NL.propTypes = {
  className: PropTypes.string,
};

NL.defaultProps = {
  className: "",
};

export default NL;
