import {
  ArrowLeftOutlined,
  CloseOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import { Drawer, Divider } from "antd";
import { t } from "i18next";
import { useNavigate, useParams } from "react-router-dom";

import usePropertyById from "../../../api/hooks/usePropertyById";
import usePropertyFloorById from "../../../api/hooks/usePropertyFloorById";
import WaterDropIcon from "../../../assets/icons/WaterDropIcon";
import useWindowSize from "../../../hooks/useWindowSize";
import FloorSystemsTable from "./FloorSystemsTable";

function FloorSystemsTableMobile() {
  const navigate = useNavigate();
  const { floorId, propertyId } = useParams();
  const window = useWindowSize();

  const { data: floor, status } = usePropertyFloorById(floorId);

  const { data: property } = usePropertyById(propertyId);

  return (
    <Drawer
      key="alarmDetailsDrawer"
      placement="right"
      open={floorId && window?.width < 992}
      width="100%"
      headerStyle={{ borderBottom: "none" }}
      onClose={() =>
        navigate({
          pathname: "..",
        })
      }
      closeIcon={
        window?.width <= 576 ? (
          <ArrowLeftOutlined className="alarm-details-close-button" />
        ) : (
          <CloseOutlined className="alarm-details-close-button" />
        )
      }
    >
      <h1 className="alarm-details-title mb-2">
        {t("property_overview_page.floor")} {floor?.Name}
      </h1>
      <div className="text-white text-xs text-opacity-80 font-light mb-6">
        <span>{property?.Name || "-"}</span>
        <Divider type="vertical" />
        <span>{property?.Address || "-"}</span>
        <Divider type="vertical" />
        <span>
          {floor?.SystemsCount || 0} {t("property_overview_page.hubs")}{" "}
        </span>
        <Divider type="vertical" />
        <span>
          {floor?.AlertsCount || 0} {t("property_overview_page.alerts")}
        </span>
        <Divider type="vertical" />
        <span>
          <WaterDropIcon className="mr-1 w-[0.6rem] align-sub" />
          {t("property_overview_page.consumption")}{" "}
          {floor?.TotalWaterConsumption?.CurrentMonthValue || "-"}
        </span>
        <Divider type="vertical" />
        <span>
          <DollarOutlined className="mr-1" />
          {t("property_overview_page.savings")}{" "}
          {floor?.TotalWaterConsumption?.WaterSavingsPercentage || "-"}%
        </span>
      </div>
      <FloorSystemsTable floor={floor} status={status} />
    </Drawer>
  );
}

export default FloorSystemsTableMobile;
