import { FrownOutlined } from "@ant-design/icons";
import { Alert, Card } from "antd";
import dayjs from "dayjs";
import { t } from "i18next";
import {
  PolarAngleAxis,
  RadialBar,
  RadialBarChart,
  ResponsiveContainer,
} from "recharts";

import usePropertyTotalWaterConsumption from "../../../api/hooks/usePropertyTotalWaterConsumption";
import DateConstants from "../../../constants/DateConstants";
import Loader from "../../Loader/Loader";
import Water from "../../Shared/Water";

/** Water Savings Chart | All Properties. Renders pie chart total water savings data by percentage % */
function WaterSavings() {
  const { data: waterSavings, status } = usePropertyTotalWaterConsumption({
    cacheTime: 0,
  });

  const getWaterSavingsDate = (waterSavings) => {
    const currentDate = dayjs();
    let year = currentDate.year();

    if (
      waterSavings &&
      waterSavings?.CurrentMonthName > 0 &&
      waterSavings?.PreviousMonthName > 0
    ) {
      const month =
        DateConstants.monthsAbbreviated[waterSavings.CurrentMonthName - 1];
      if (
        waterSavings?.CurrentMonthName === 12 &&
        waterSavings?.PreviousMonthName === 11
      ) {
        year -= 1;
      }
      return `${month} ${year}`;
    }

    // Fallback to client-side calculation of month, always show previous month.
    // If current month is January, show December previous year, so year - 1
    const month = currentDate.subtract(1, "month").format("MMM");
    if (currentDate.month() === 0) {
      year -= 1;
    }
    return `${month} ${year}`;
  };

  const circleSize = 150;
  const data = [
    { name: "WaterSavings", value: waterSavings?.WaterSavingsPercentage },
  ];

  return (
    <Card
      headStyle={{ padding: 4 }}
      bodyStyle={{ padding: 4 }}
      title={
        <span className="page-title-mobile p-1">{t("water_saving.title")}</span>
      }
      bordered={false}
      className="text-14 p-1"
    >
      {status === "error" && (
        <div
          className="flex items-center justify-center"
          style={{ height: 150 }}
        >
          <Alert
            message={t("problems.bad_news")}
            description={t("problems.broke")}
            type="error"
            showIcon
            icon={<FrownOutlined />}
            className="w-fit"
          />
        </div>
      )}

      {status === "loading" && (
        <div style={{ height: 150 }}>
          <Loader className="absolute-center" />
        </div>
      )}
      {status === "success" && (
        <div className="flex">
          <div className="self-end w-2/3">
            <span className="mb-0 text-18 flex">
              {getWaterSavingsDate(waterSavings)}
            </span>
            <div className="inline-flex p-0">
              <div className="w-24 h-2 bg-triple-blue self-center mr-2 rounded-lg" />
              <p className="mb-0 text-18 self-center">
                <Water value={waterSavings?.CurrentMonthValue} />
              </p>
            </div>
          </div>
          <div style={{ height: 150, width: 200 }}>
            <ResponsiveContainer className="w-full" height={150}>
              <RadialBarChart
                width={circleSize}
                height={circleSize}
                cx={circleSize / 2}
                cy={circleSize / 2}
                innerRadius={65}
                outerRadius={75}
                barSize={10}
                data={data}
                startAngle={90}
                endAngle={-270}
              >
                <PolarAngleAxis
                  type="number"
                  domain={[0, 100]}
                  angleAxisId={0}
                  tick={false}
                />
                <RadialBar
                  background
                  clockWise
                  dataKey="value"
                  cornerRadius={circleSize / 2}
                  fill="#06a5d3"
                />
                <text
                  x={circleSize / 2}
                  y={circleSize / 2}
                  textAnchor="middle"
                  dominantBaseline="middle"
                  fill="#f6f6f6"
                >
                  <tspan fontSize={18} x={circleSize / 2} y={55}>
                    {waterSavings?.WaterSavingsPercentage}%
                  </tspan>
                  <tspan fontSize={16} x={circleSize / 2} y={75}>
                    {t("water_saving.water")}
                  </tspan>
                  <tspan fontSize={16} x={circleSize / 2} y={90}>
                    {t("water_saving.saving")}
                  </tspan>
                </text>
              </RadialBarChart>
            </ResponsiveContainer>
          </div>
        </div>
      )}
    </Card>
  );
}

export default WaterSavings;
