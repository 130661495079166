import { Card } from "antd";
import { t } from "i18next";
import PropTypes from "prop-types";

import "../scss/UsersListLicenses.scss";

function UsersListLicenses({ licensesCount }) {
  const renderLicenses = (title, countTotal, countUsed) => {
    return (
      <div className="licenses-card-content">
        <span className="truncate">{title}</span>
        <span className="licenses-card-count-total">{countTotal}</span>
        <span className="licenses-card-used">{t("users.used")}</span>
        <span className="licenses-card-count-used">{countUsed}</span>
      </div>
    );
  };
  return (
    <Card className="licenses-card" bodyStyle={{ padding: "0.5rem" }}>
      <span className="licenses-card-title-lg">{t("users.licenses")}</span>
      <div className="licenses-card-title-sm-container">
        <span className="licenses-card-title-sm">{t("users.licenses")}</span>
        {renderLicenses(
          t("users.administrator"),
          licensesCount?.Value?.AdministratorLicensesTotalCount,
          licensesCount?.Value?.AdministratorLicensesUsedCount,
        )}
        {renderLicenses(
          t("users.operator"),
          licensesCount?.Value?.OperatorLicensesTotalCount,
          licensesCount?.Value?.OperatorLicensesUsedCount,
        )}
        {renderLicenses(
          t("users.user"),
          licensesCount?.Value?.UserLicensesTotalCount,
          licensesCount?.Value?.UserLicensesUsedCount,
        )}
      </div>
    </Card>
  );
}

UsersListLicenses.defaultProps = {
  licensesCount: {},
};
UsersListLicenses.propTypes = {
  licensesCount: PropTypes.object,
};

export default UsersListLicenses;
