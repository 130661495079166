import { useEffect } from "react";

import { Select, Spin } from "antd";
import { t } from "i18next";

import useScheduleProperty from "../../../../api/hooks/useScheduleProperty";
import useScheduleSystems from "../../../../hooks/useScheduleSystems";

function SelectPropertyInput() {
  const { scheduleSystems, setScheduleSystems } = useScheduleSystems();
  const isSelectedAll = scheduleSystems.selectedPropertyId === "all";
  const selectValue =
    scheduleSystems?.selectedProperty?.Name ||
    (isSelectedAll ? "All properties" : undefined);

  const {
    data: properties,
    refetch: getProperties,
    status: propertiesStatus,
  } = useScheduleProperty(null, {
    enabled: false,
    cacheTime: 0,
  });

  const {
    data: selectedProperties,
    refetch: getSelectedProperty,
    remove: removeGetSelectedProperty,
  } = useScheduleProperty(
    {
      propertyId: scheduleSystems.selectedPropertyId,
    },
    {
      enabled: false,
      cacheTime: 0,
    },
  );

  if (properties && scheduleSystems.properties.length === 0) {
    setScheduleSystems((prevState) => ({
      ...prevState,
      properties,
    }));
  }

  if (selectedProperties) {
    const selectedProperty =
      Array.isArray(selectedProperties) && selectedProperties.length > 0
        ? { ...selectedProperties[0] }
        : selectedProperties; // assume it's an object

    if (
      (selectedProperty || isSelectedAll) &&
      scheduleSystems.selectedProperty?.Id !== selectedProperty?.Id
    ) {
      setScheduleSystems((prevState) => ({
        ...prevState,
        selectedProperty,
      }));
    }
  }

  useEffect(() => {
    if (scheduleSystems.selectedPropertyId && !isSelectedAll) {
      getSelectedProperty();
    }
    return () => {
      removeGetSelectedProperty();
    };
  }, [
    scheduleSystems.selectedPropertyId,
    isSelectedAll,
    getSelectedProperty,
    removeGetSelectedProperty,
  ]);

  const handleClick = () => {
    if (!properties) {
      getProperties();
    }
  };

  const handleChange = (value) => {
    if (value) {
      setScheduleSystems((prevState) => ({
        ...prevState,
        selectedPropertyId: value,
        selectedDeviceType: null,
        selectedFloorId: null,
        selectedFloor: null,
      }));
    }
  };

  const handleClear = () => {
    setScheduleSystems((prevState) => ({
      ...prevState,
      selectedPropertyId: null,
      selectedProperty: null,
      selectedDeviceType: null,
      selectedFloorId: null,
      selectedFloor: null,
      selectedSystemsWithSelectedDevice: [],
      confirmedSystemsWithSelectedDevice: [],
      selectedDevicesUniqueIds: [],
      checkedSystemsKeys: [],
      checkedDevicesKeys: [],
    }));
  };

  return (
    <Select
      allowClear
      className="w-full"
      filterOption={(input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
      showSearch
      size="large"
      options={
        properties && propertiesStatus === "success"
          ? [
              {
                label: t("statistics_filters.all_properties"),
                value: "all",
              },
              ...properties
                .filter((property) => property.Floors?.length > 0)
                .map((system) => {
                  return {
                    label: system?.Name,
                    value: system?.Id,
                  };
                }),
            ]
          : []
      }
      value={selectValue}
      placeholder={t("property_overview_page.property")}
      getPopupContainer={(trigger) => trigger.parentNode}
      notFoundContent={
        propertiesStatus === "loading" ? (
          <div className="flex items-center justify-center p-8">
            <Spin size="small" />
          </div>
        ) : (
          <div className="flex items-center justify-center p-8">
            <span>{t("property_overview_page.no_propereties")}</span>
          </div>
        )
      }
      onChange={handleChange}
      onClick={handleClick}
      onClear={handleClear}
    />
  );
}

export default SelectPropertyInput;
