import CountryFlags from "../../assets/icons/country-flags/CountryFlags";

const LanguageOptions = [
  {
    value: "English",
    label: (
      <>
        <CountryFlags.USA className="mr-2 align-middle" />
        English
      </>
    ),
  },
  {
    value: "Hebrew",
    label: (
      <>
        <CountryFlags.IL className="mr-2 align-middle" />
        Hebrew
      </>
    ),
  },
  // {
  //   value: "Russian",
  //   label: (
  //     <>
  //       <CountryFlags.RU className="mr-2 align-middle" />
  //       Russian
  //     </>
  //   ),
  // },
  // {
  //   value: "French",
  //   label: (
  //     <>
  //       <CountryFlags.FR className="mr-2 align-middle" />
  //       French
  //     </>
  //   ),
  // },
  // {
  //   value: "Dutch",
  //   label: (
  //     <>
  //       <CountryFlags.NL className="mr-2 align-middle" />
  //       Dutch
  //     </>
  //   ),
  // },
];

export default LanguageOptions;
