import { useState, useEffect } from "react";

import {
  CloseOutlined,
  ArrowLeftOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import {
  Drawer,
  Button,
  Col,
  Row,
  DatePicker,
  TimePicker,
  Divider,
  Input,
} from "antd";
import dayjs from "dayjs";
import { t } from "i18next";
import {
  useLocation,
  useNavigate,
  createSearchParams,
  useSearchParams,
} from "react-router-dom";

import useEventFilters from "../../../api/hooks/useEventFilters";
import useFloorsByPropertyId from "../../../api/hooks/useFloorsByPropertyId";
import { sortObjArrayByKey } from "../../../helpers/sortObjArrayByKey";
import useWindowSize from "../../../hooks/useWindowSize";
import FilterSelect from "./FilterSelect";

import "./EventsFilter.scss";

const defaultFilters = {
  SystemId: null,
  SystemName: null,
  Owner: null,
  DeviceTypePublicName: null,
  DeviceSerialNumber: null,
  PropertyId: null,
  Property: null,
  FloorId: null,
  Floor: null,
  EventType: null,
  RecordType: null,
  DateTimeStart: null,
  DateTimeEnd: null,
};

function EventsFilter() {
  const [selectedFilters, setSelectedFilters] = useState(null);
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [localeTime, setLocaleTime] = useState({
    DateTimeStart: null,
    DateTimeEnd: null,
  });

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const window = useWindowSize();

  const { data: eventFilters, status } = useEventFilters();

  const {
    data: selectedProperty,
    status: selectedFloorStatus,
    refetch: getSelectedProperty,
  } = useFloorsByPropertyId(
    {
      propertyId: selectedFilters?.PropertyId,
    },
    {
      enabled: false,
      cacheTime: 0,
    },
  );

  // get search params from url into params{}
  const params = {};
  useEffect(() => {
    searchParams.forEach((value, key) => {
      if (value && key !== "page") {
        params[key] = value;
      }
    });

    setSelectedFilters(params);
  }, [searchParams]);
  const { page, ...paramsToSendWithRequest } = params;

  // // fetch property on property select
  useEffect(() => {
    if (selectedFilters?.PropertyId) {
      getSelectedProperty();
    }
  }, [selectedFilters, getSelectedProperty]);

  // get floor name
  useEffect(() => {
    if (selectedProperty && selectedFilters?.FloorId) {
      const [selectedFloor] =
        selectedProperty?.Floors?.filter(
          (floor) => floor.Id === Number(selectedFilters?.FloorId),
        ) ?? [];

      setSelectedFloor(selectedFloor);
    }
  }, [selectedProperty, selectedFilters?.FloorId]);

  // set date-time filters on localTime change
  useEffect(() => {
    if (localeTime?.DateTimeStart) {
      const DateTimeStart = new Date(localeTime.DateTimeStart).toISOString();
      setSelectedFilters((selectedFilters) => {
        return {
          ...selectedFilters,
          DateTimeStart,
        };
      });
    }

    if (localeTime?.DateTimeEnd) {
      const DateTimeEnd = new Date(localeTime.DateTimeEnd).toISOString();
      setSelectedFilters((selectedFilters) => {
        return {
          ...selectedFilters,
          DateTimeEnd,
        };
      });
    }
  }, [localeTime]);

  const onFilterSelectChange = (value, key) => {
    if (!value) {
      delete selectedFilters[key];
      setSelectedFilters({
        ...selectedFilters,
      });
    } else {
      if (
        selectedFilters?.EventType &&
        key === "RecordType" &&
        value === "Command"
      ) {
        delete selectedFilters.EventType;
      }

      setSelectedFilters({
        ...selectedFilters,
        [key]: value,
      });
    }
  };

  const onDateTimeChange = (_, dateTimeString, key, type = "date") => {
    // Clear datePicker/timePicker on x press
    if (!dateTimeString) {
      delete selectedFilters[key];
      setSelectedFilters({
        ...selectedFilters,
      });
      return;
    }

    if (selectedFilters && selectedFilters[key]) {
      const selectedDateInLocaleTime = new Date(
        selectedFilters[key],
      ).toString();
      const dateFromSelectedDate = new Date(
        selectedDateInLocaleTime,
      ).toDateString();
      const timeFromSelectedDate = new Date(
        selectedDateInLocaleTime,
      ).toLocaleTimeString();
      let newDate = "";

      if (type === "date") {
        newDate = new Date(`${dateTimeString} ${timeFromSelectedDate}`);
      } else if (type === "time") {
        newDate = new Date(`${dateFromSelectedDate} ${dateTimeString}`);
      }

      setLocaleTime({ ...localeTime, [key]: newDate });
    } else {
      // set time to 00:00:00 when selecting date for the first time
      const dateInMilliseconds = new Date(dateTimeString).setHours(12, 0, 0, 0);
      // LOCALE time
      const localeTimeFromInput = new Date(dateInMilliseconds);
      setLocaleTime({ ...localeTime, [key]: localeTimeFromInput });
    }
  };

  const onFilter = () => {
    navigate({
      pathname: "/events",
      search: `?${createSearchParams({
        ...selectedFilters,
      })}`,
    });
  };

  const onClearFilters = () => {
    setSelectedFilters(null);
  };

  return (
    <Drawer
      key="eventsFilterDrawer"
      className="events-filter"
      placement="right"
      size="large"
      open={location.pathname === "/events/filters"}
      width={window?.width >= 768 ? "718px" : "100%"}
      headerStyle={{ borderBottom: "none" }}
      onClose={() => {
        if (Object.keys(paramsToSendWithRequest).length > 0) {
          onFilter();
        } else {
          navigate({
            pathname: "..",
            search: location.search,
          });
        }
      }}
      closeIcon={
        window?.width <= 768 ? (
          <ArrowLeftOutlined className="events-filter-close-button" />
        ) : (
          <CloseOutlined className="events-filter-close-button" />
        )
      }
      extra={
        <>
          <Button
            type="text"
            className="events-filter-clear-button"
            onClick={onClearFilters}
          >
            {t("events_filter.clear_all")}
          </Button>
          <Button type="primary" size="large" onClick={onFilter}>
            <CheckOutlined />
            {t("events_filter.apply")}
          </Button>
        </>
      }
    >
      <div className="events-filter-content">
        <h1 className="events-filter-title">{t("events_filter.filters")}</h1>

        <div className="events-filter-section">
          <Divider
            orientation="left"
            orientationMargin={0}
            className="events-filter-section-title"
          >
            {t("events_filter.system_filters")}
          </Divider>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8} className="events-filter-col">
              <FilterSelect
                placeholder={t("events_filter.system_id")}
                options={sortObjArrayByKey(
                  !selectedFilters?.Owner && !selectedFilters?.SystemName
                    ? eventFilters?.Systems
                    : eventFilters?.Systems.filter((system) => {
                        return (
                          system.Name === selectedFilters.SystemName ||
                          system.Owner === selectedFilters.Owner
                        );
                      }),
                  "UniqueId",
                ).map((system) => {
                  return {
                    value: system?.UniqueId,
                    label: system?.UniqueId,
                  };
                })}
                status={status}
                value={selectedFilters?.SystemId || defaultFilters.SystemId}
                onChange={(value) => {
                  onFilterSelectChange(value, "SystemId");
                }}
              />
            </Col>
            <Col xs={24} sm={12} md={8} className="events-filter-col">
              <FilterSelect
                placeholder={t("events_filter.system_name")}
                options={sortObjArrayByKey(
                  !selectedFilters?.SystemId && !selectedFilters?.Owner
                    ? [
                        ...new Map(
                          eventFilters?.Systems.map((system) => [
                            system.Name,
                            system,
                          ]),
                        ).values(),
                      ]
                    : [
                        ...new Map(
                          eventFilters?.Systems.filter((system) => {
                            return (
                              system.UniqueId === selectedFilters.SystemId ||
                              system.Owner === selectedFilters.Owner
                            );
                          }).map((filteredSystem) => [
                            filteredSystem.Name,
                            filteredSystem,
                          ]),
                        ).values(),
                      ],
                  "Name",
                ).map((uniqueSystem) => {
                  return {
                    value: uniqueSystem?.Name,
                    label: uniqueSystem?.Name,
                  };
                })}
                status={status}
                value={selectedFilters?.SystemName || defaultFilters.SystemName}
                onChange={(value) => {
                  onFilterSelectChange(value, "SystemName");
                }}
              />
            </Col>
            <Col xs={24} sm={12} md={8} className="events-filter-col">
              <FilterSelect
                placeholder={t("events_filter.system_owner")}
                options={sortObjArrayByKey(
                  !selectedFilters?.SystemId && !selectedFilters?.SystemName
                    ? [
                        ...new Map(
                          eventFilters?.Systems.map((system) => [
                            system.Owner,
                            system,
                          ]),
                        ).values(),
                      ]
                    : [
                        ...new Map(
                          eventFilters?.Systems.filter((system) => {
                            return (
                              system.UniqueId === selectedFilters.SystemId ||
                              system.Name === selectedFilters.SystemName
                            );
                          }).map((filteredSystem) => [
                            filteredSystem.Owner,
                            filteredSystem,
                          ]),
                        ).values(),
                      ],
                  "Owner",
                ).map((uniqueSystem) => {
                  return {
                    value: uniqueSystem?.Owner,
                    label: uniqueSystem?.Owner,
                  };
                })}
                status={status}
                value={selectedFilters?.Owner || defaultFilters.Owner}
                onChange={(value) => {
                  onFilterSelectChange(value, "Owner");
                }}
              />
            </Col>
          </Row>
        </div>

        <div className="events-filter-section">
          <Divider
            orientation="left"
            orientationMargin={0}
            className="events-filter-section-title"
          >
            {t("events_filter.device_type")}
          </Divider>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8} className="events-filter-col">
              <FilterSelect
                placeholder={t("events_filter.device_type")}
                options={sortObjArrayByKey(
                  eventFilters?.DeviceTypes,
                  "PublicName",
                ).map((deviceType) => {
                  return {
                    value: deviceType?.PublicName,
                    label: deviceType?.PublicName,
                  };
                })}
                status={status}
                value={
                  selectedFilters?.DeviceTypePublicName ||
                  defaultFilters.DeviceTypePublicName
                }
                onChange={(value) => {
                  onFilterSelectChange(value, "DeviceTypePublicName");
                }}
              />
            </Col>
            <Col xs={24} sm={12} md={8} className="events-filter-col">
              <Input
                className="ant-select-selection-search-input"
                size="large"
                placeholder={t("events_filter.device_serial_number")}
                onInput={(e) => {
                  onFilterSelectChange(e.target.value, "DeviceSerialNumber");
                }}
              />
            </Col>
          </Row>
        </div>

        <div className="events-filter-section">
          <Divider
            orientation="left"
            orientationMargin={0}
            className="events-filter-section-title"
          >
            {t("events_filter.property_filters")}
          </Divider>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8} className="events-filter-col">
              <FilterSelect
                placeholder={t("events_filter.property")}
                options={sortObjArrayByKey(
                  eventFilters?.Properties,
                  "Name",
                ).map((property) => {
                  return {
                    value: property?.Id,
                    label: property?.Name,
                  };
                })}
                status={status}
                value={selectedProperty?.Name || defaultFilters.Property}
                onChange={(value) => {
                  onFilterSelectChange(value, "PropertyId");
                }}
                onClear={() => setSelectedFloor(null)}
              />
            </Col>
            <Col xs={24} sm={12} md={8} className="events-filter-col">
              <FilterSelect
                placeholder={t("events_filter.floor")}
                disabled={!selectedProperty}
                options={selectedProperty?.Floors?.map((floor) => {
                  return {
                    value: floor?.Id,
                    label: floor?.Name,
                  };
                })}
                status={selectedFloorStatus}
                value={selectedFloor?.Name || defaultFilters.Floor}
                onChange={(value) => {
                  onFilterSelectChange(value, "FloorId");
                }}
                onClear={() => setSelectedFloor(null)}
              />
            </Col>
          </Row>
        </div>

        <div className="events-filter-section">
          <Divider
            orientation="left"
            orientationMargin={0}
            className="events-filter-section-title"
          >
            {t("events_filter.record_events_filters")}
          </Divider>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8} className="events-filter-col">
              <FilterSelect
                placeholder={t("events_filter.record")}
                options={eventFilters?.RecordsTypes.map((recordTypes) => {
                  return { value: recordTypes, label: recordTypes };
                })}
                status={status}
                value={selectedFilters?.RecordType || defaultFilters.RecordType}
                onChange={(value) => {
                  onFilterSelectChange(value, "RecordType");
                }}
              />
            </Col>
            <Col xs={24} sm={12} md={8} className="events-filter-col">
              <FilterSelect
                disabled={selectedFilters?.RecordType === "Command"}
                placeholder={t("events_filter.event_type")}
                options={eventFilters?.EventTypes.map((eventType) => {
                  return { value: eventType, label: eventType };
                })}
                status={status}
                value={selectedFilters?.EventType || defaultFilters.EventType}
                onChange={(value) => {
                  onFilterSelectChange(value, "EventType");
                }}
              />
            </Col>
          </Row>
        </div>

        <div className="events-filter-section">
          <Divider
            orientation="left"
            orientationMargin={0}
            className="events-filter-section-title"
          >
            {t("events_filter.date_time_filters")}
          </Divider>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8} className="events-filter-col">
              <DatePicker
                className="events-filter-datepicker"
                disabledDate={(current) => {
                  if (
                    current.isAfter(dayjs()) ||
                    (selectedFilters?.DateTimeEnd &&
                      current > dayjs(selectedFilters.DateTimeEnd))
                  ) {
                    return true;
                  }

                  return false;
                }}
                inputReadOnly
                placeholder={t("events_filter.start_date")}
                size="large"
                value={
                  selectedFilters?.DateTimeStart &&
                  dayjs(selectedFilters.DateTimeStart)
                }
                onChange={(_, dateString) =>
                  onDateTimeChange(_, dateString, "DateTimeStart")
                }
              />
            </Col>
            <Col xs={24} sm={12} md={8} className="events-filter-col">
              <TimePicker
                className="events-filter-timepicker"
                disabled={!selectedFilters?.DateTimeStart}
                format="h:mm A"
                inputReadOnly
                placeholder={t("events_filter.start_time")}
                size="large"
                use12Hours
                value={
                  selectedFilters?.DateTimeStart &&
                  dayjs(
                    new Date(
                      selectedFilters.DateTimeStart,
                    ).toLocaleTimeString(),
                    "HH:mm:ss A",
                  )
                }
                onChange={(_, timeString) =>
                  onDateTimeChange(_, timeString, "DateTimeStart", "time")
                }
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} className="mt-4">
            <Col xs={24} sm={12} md={8} className="events-filter-col">
              <DatePicker
                className="events-filter-datepicker"
                disabledDate={(current) => {
                  if (
                    selectedFilters?.DateTimeStart &&
                    current <
                      dayjs(selectedFilters.DateTimeStart)
                        .subtract(1, "day")
                        .endOf("day")
                  ) {
                    return true;
                  }

                  return false;
                }}
                inputReadOnly
                size="large"
                placeholder={t("events_filter.end_date")}
                value={
                  selectedFilters?.DateTimeEnd &&
                  dayjs(selectedFilters.DateTimeEnd)
                }
                onChange={(_, dateString) =>
                  onDateTimeChange(_, dateString, "DateTimeEnd")
                }
              />
            </Col>
            <Col xs={24} sm={12} md={8} className="events-filter-col">
              <TimePicker
                className="events-filter-timepicker"
                disabled={!selectedFilters?.DateTimeEnd}
                format="h:mm A"
                inputReadOnly
                placeholder={t("events_filter.end_time")}
                size="large"
                use12Hours
                value={
                  selectedFilters?.DateTimeEnd &&
                  dayjs(
                    new Date(selectedFilters.DateTimeEnd).toLocaleTimeString(),
                    "HH:mm:ss A",
                  )
                }
                onChange={(_, timeString) =>
                  onDateTimeChange(_, timeString, "DateTimeEnd", "time")
                }
              />
            </Col>
          </Row>
        </div>
      </div>
    </Drawer>
  );
}

export default EventsFilter;
