import { useMutation, useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const RESOURCE_ENDPOINT = "/UsersManagement";

function useCreateCmsOperator() {
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationFn: ({ request }) => {
      return axiosPrivate({
        method: "POST",
        url: `${RESOURCE_ENDPOINT}/CreateCmsOperator`,
        data: request,
      });
    },
  });
}
function useGetCmsOperator(userId, options, key = "getCmsOperator") {
  const axiosPrivate = useAxiosPrivate();

  const getCmsOperator = async () => {
    const cmsOperator = await axiosPrivate({
      method: "GET",
      url: `${RESOURCE_ENDPOINT}/${userId}/GetCmsOperator`,
    }).then((response) => response.data);

    return cmsOperator;
  };

  return useQuery({
    queryFn: getCmsOperator,
    queryKey: [key, userId],
    ...options,
  });
}

function useUpdateCmsOperator() {
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationFn: ({ userId, request }) => {
      return axiosPrivate({
        method: "PUT",
        url: `${RESOURCE_ENDPOINT}/${userId}/UpdateCmsOperator`,
        data: request,
      });
    },
  });
}

export { useCreateCmsOperator, useGetCmsOperator, useUpdateCmsOperator };
