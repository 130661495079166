import { useEffect, useImperativeHandle, useState } from "react";

import { Table, Transfer } from "antd";
import { t } from "i18next";
import PropTypes from "prop-types";

import useGetSystemsForFloorManagementWithDevices from "../../../../../api/hooks/useGetSystemsForFloorManagementWithDevices";
import useWindowSize from "../../../../../hooks/useWindowSize";
import {
  columns,
  dataStructure,
  filterLeftSource,
  filterRightSource,
  flattenTree,
  renderTitle,
} from "../helperFuncs/helperFunctions";
import "../scss/FloorSystems.scss";
import NameFilterGroup from "./NameFilterGroup";

function FloorSystems({
  editFloorFormReference,
  floorId,
  setDisableSaveChanges,
  setIsFormDirty,
  floorSystemsRef,
}) {
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);
  const [leftTableData, setLeftTableData] = useState([]);
  const [rightTableData, setRightTableData] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");
  const [deviceTypeId, setDeviceTypeId] = useState();
  const window = useWindowSize();
  const {
    data: systemsWithDevices,
    refetch: getSystemsForFloorMgmtWithDevices,
  } = useGetSystemsForFloorManagementWithDevices(
    floorId,
    {
      search: debouncedSearchQuery,
      deviceTypeId,
    },
    {
      enabled: true,
    },
  );

  useEffect(() => {
    getSystemsForFloorMgmtWithDevices(floorId, {
      search: debouncedSearchQuery,
      deviceTypeId,
    });
  }, [
    deviceTypeId,
    debouncedSearchQuery,
    getSystemsForFloorMgmtWithDevices,
    floorId,
  ]);

  useImperativeHandle(floorSystemsRef, () => ({
    setDataSource,
    setExpandedRowKeys,
    setTargetKeys,
    setLeftTableData,
    setRightTableData,
  }));

  useEffect(() => {
    if (systemsWithDevices) {
      const allSystems = dataStructure(systemsWithDevices);
      const allRowKeys = allSystems.map((system) => system.key);
      const assignedSystems = allSystems.filter((x) => x.assigned);
      const unassignedSystems = allSystems.filter((x) => !x.assigned);
      const flattenTargetKeys = flattenTree(unassignedSystems).map(
        (x) => x.key,
      );

      setExpandedRowKeys(allRowKeys);
      setDataSource(allSystems);
      setTargetKeys(flattenTargetKeys);
      setLeftTableData(assignedSystems);
      setRightTableData(unassignedSystems);
    }
  }, [systemsWithDevices]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  // Handling the Transfer of Items
  const handleChange = (newTargetKeys, direction, moveKeys) => {
    let newTargetKeysArr = newTargetKeys;
    if (direction === "right") {
      // if a child is being move, move the parent also.
      // if a parent is selected it will be already in the moveKeys along with the children keys
      moveKeys.forEach((key) => {
        const parent = dataSource.find((p) =>
          p.children.some((child) => child.key === key),
        );

        if (parent && !newTargetKeysArr.includes(parent.key)) {
          newTargetKeysArr.push(parent.key);
        }
      });
    } else {
      // Moving to the left: remove parent key if no children remain in target keys
      moveKeys.forEach((key) => {
        const parent = dataSource.find(
          (p) => p.key === key || p.children.some((child) => child.key === key),
        );

        if (
          parent &&
          parent.children.every(
            (child) => !newTargetKeysArr.includes(child.key),
          )
        ) {
          // Remove the parent if none of its children are in the target keys
          newTargetKeysArr = newTargetKeysArr.filter(
            (targetKey) => targetKey !== parent.key,
          );
        }
      });
    }

    const leftTableData = filterLeftSource(dataSource, newTargetKeysArr);
    const rightTableData = filterRightSource(dataSource, newTargetKeysArr);

    setTargetKeys(newTargetKeysArr);
    setSelectedKeys([]);
    setLeftTableData(leftTableData);
    setRightTableData(rightTableData);
    setDisableSaveChanges(false);
    setIsFormDirty(true);
    editFloorFormReference.setFieldsValue({
      Systems: {
        AssignedSystems: leftTableData,
        UnassignedSystems: rightTableData,
      },
    });
  };

  return (
    <div className="floor-system-table-transfer">
      <NameFilterGroup
        setDeviceTypeId={setDeviceTypeId}
        handleSearchChange={handleSearchChange}
      />
      <Transfer
        className={window.width <= 1024 ? "flex-col" : "flex-row"}
        dataSource={dataSource}
        titles={[
          renderTitle(leftTableData, t("create_zone.assigned_devices")),
          renderTitle(rightTableData, t("create_zone.unassigned_devices")),
        ]}
        targetKeys={targetKeys}
        selectedKeys={selectedKeys}
        onChange={handleChange}
        showSelectAll={false}
        operations={[
          t("create_zone.unassign"),
          t("create_zone.assign to floor"),
        ]}
      >
        {({ direction, selectedKeys }) => {
          return dataSource.length > 0 ? (
            <Table
              style={{ height: "55vh", overflowY: "auto" }}
              rowKey="key"
              columns={columns}
              dataSource={direction === "left" ? leftTableData : rightTableData}
              pagination={false}
              size="small"
              rowSelection={{
                checkStrictly: false,
                selectedRowKeys: selectedKeys,
                onChange: (selectedRowKeys) => {
                  setSelectedKeys(() => {
                    const updatedKeys = [...selectedRowKeys];
                    updatedKeys.forEach((key) => {
                      const parent = dataSource.find((p) =>
                        p.children.some((c) => c.key === key),
                      );
                      if (parent && targetKeys.includes(parent.key)) {
                        const index = updatedKeys.indexOf(parent.key);
                        if (index !== -1) {
                          updatedKeys.splice(index, 1);
                        }
                      }
                    });

                    dataSource.forEach((parent) => {
                      const parentChildrenKeys = parent.children.map(
                        (child) => child.key,
                      );

                      const parentStillSelected = parentChildrenKeys.some(
                        (childKey) => updatedKeys.includes(childKey),
                      );

                      if (!parentStillSelected) {
                        const index = updatedKeys.indexOf(parent.key);
                        if (index !== -1) {
                          updatedKeys.splice(index, 1);
                        }
                      }
                    });
                    return updatedKeys;
                  });
                },
              }}
              expandable={{
                defaultExpandAllRows: true,
                expandedRowKeys,
                expandIcon: () => null,
                rowExpandable: (record) => record.children?.length > 0,
                indentSize: 0,
              }}
            />
          ) : null;
        }}
      </Transfer>
    </div>
  );
}

FloorSystems.propTypes = {
  editFloorFormReference: PropTypes.object,
  floorId: PropTypes.number.isRequired,
  setDisableSaveChanges: PropTypes.func,
  setIsFormDirty: PropTypes.func,
  floorSystemsRef: PropTypes.object,
};

FloorSystems.defaultProps = {
  editFloorFormReference: {},
  setDisableSaveChanges: () => {},
  setIsFormDirty: () => {},
  floorSystemsRef: {},
};

export default FloorSystems;
