import { useState } from "react";

import { FrownOutlined } from "@ant-design/icons";
import { Alert, Row } from "antd";
import { t } from "i18next";

import usePolling from "../../api/hooks/customHooks/usePolling";
import useMonitoringEvents from "../../api/hooks/useMonitoringEvents";
import useWindowSize from "../../hooks/useWindowSize";
import Loader from "../Loader/Loader";
import DropdownFilter from "./Filters/DropdownFilter";
import EventTypeFilter from "./Filters/EventTypeFilter";
import MonitoringTable from "./MonitoringTable";
import "./scss/Monitoring.scss";

function Monitoring() {
  const window = useWindowSize();
  const [eventTypes, setEventTypes] = useState([]);
  const [property, setProperty] = useState({
    value: "",
    label: t("monitoring.all"),
  });
  const {
    data,
    status,
    refetch: refetchMonitoringEvents,
  } = useMonitoringEvents();

  // pull data every 30 sec.
  usePolling(refetchMonitoringEvents, 30000);

  const filteredData = data?.Events?.filter(
    (item) =>
      (eventTypes.length === 0 || eventTypes.includes(item.EventType)) &&
      (property?.value === "" || item.PropertyId === property?.value),
  );

  return (
    <div className="monitoring-main text-triple-white">
      {window?.width > 992 && (
        <h1 className="page-title">{t("sidebar.monitoring")}</h1>
      )}
      {status === "loading" && <Loader className="absolute-center" />}
      {status === "success" && (
        <>
          <div className="monitoring-filters">
            <Row>
              <DropdownFilter onPropertyChange={setProperty} />
              <EventTypeFilter
                totalStatusCount={data?.TotalStatusCount}
                onEventTypeChange={setEventTypes}
              />
            </Row>
          </div>
          <div className="monitoring-events">
            <MonitoringTable
              eventsData={filteredData}
              eventTypes={eventTypes}
            />
          </div>
        </>
      )}
      {status === "error" && (
        <div className="flex items-center justify-center">
          <Alert
            message={t("problems.bad_news")}
            description={t("problems.broke")}
            type="error"
            showIcon
            icon={<FrownOutlined />}
            className="w-fit"
          />
        </div>
      )}
    </div>
  );
}
export default Monitoring;
