function FlowManagementIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="208"
      height="256"
      viewBox="0 0 208 256"
    >
      <path
        fill="#757782"
        fillRule="evenodd"
        transform="translate(-105, -110)"
        d="M251.3,225.716v-5.55c0-15.371-10.8-28.186-24.615-28.186s-24.652,12.8-24.652,28.167l-0.019,29.5c0,9.7-6.353,17.26-13.687,17.26s-13.687-7.537-13.687-17.26v-1.841a17.655,17.655,0,0,0,0-33.927V197.462a5.474,5.474,0,0,0-10.947-.019v16.675a17.671,17.671,0,0,0,0,33.372v2.156c0,15.371,10.8,28.187,24.616,28.187s24.652-12.8,24.652-28.168l0.018-29.5c0-9.7,6.353-17.26,13.688-17.26s13.687,7.537,13.687,17.26V225.4a17.655,17.655,0,0,0,0,33.927v13.023a5.473,5.473,0,1,0,10.946.018V259.087A17.67,17.67,0,0,0,251.3,225.716ZM169.87,237.142a6.309,6.309,0,0,1-6.309-6.309c0-3.484,6.309-8.911,6.309-8.911s6.31,5.427,6.31,8.911A6.31,6.31,0,0,1,169.87,237.142Zm75.259,11.539a6.309,6.309,0,0,1-6.309-6.309c0-3.484,6.309-8.814,6.309-8.814s6.31,5.33,6.31,8.814A6.309,6.309,0,0,1,245.129,248.681Z"
      />
    </svg>
  );
}
export default FlowManagementIcon;
