import { useState } from "react";

import { EditOutlined, FrownOutlined } from "@ant-design/icons";
import { Button, Popover, notification } from "antd";
import { t } from "i18next";
import PropTypes from "prop-types";

import useCreateFlowConfiguration from "../../../api/hooks/useCreateFlowConfiguration";
import useFlowRateActions from "../../../api/hooks/useFlowRateActions";
import "./EditFdsSchedule.scss";
import {
  ConvertDurationInMinutesToHours,
  ConvertTimeToDurationInMinutes,
} from "../../../helpers/dates";
import EditFdsScheduleContent from "./EditFdsScheduleContent";

function EditFdsSchedule({ fdsScheduleObject, refetchFdsSchedulesList }) {
  const flowRateActions = useFlowRateActions();
  const { mutate: updateFlowConfiguration } = useCreateFlowConfiguration();

  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const {
    LowClose,
    LowDuration,
    MedClose,
    MedDuration,
    HighClose,
    HighDuration,
  } = fdsScheduleObject?.CustomFlow?.Data || null;

  const [flowRateStates, setFlowRateStates] = useState([
    {
      id: "low",
      label: t("flow_configuration.low"),
      durationInMinutes: LowDuration,
      close: LowClose,
      selected: true,
    },
    {
      id: "medium",
      label: t("flow_configuration.medium"),
      durationInMinutes: MedDuration,
      close: MedClose,
      selected: false,
    },
    {
      id: "high",
      label: t("flow_configuration.high"),
      durationInMinutes: HighDuration,
      close: HighClose,
      selected: false,
    },
  ]);

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnChange = (e) => {
    const newStateId = e.target.value;
    const updatedStates = flowRateStates.map((state) => {
      if (state.id === newStateId) {
        return { ...state, selected: true };
      }
      return { ...state, selected: false };
    });
    setFlowRateStates(updatedStates);
  };

  const handleOnChangeTimePicker = (e) => {
    const newDuration = ConvertTimeToDurationInMinutes(e);
    const updatedStates = flowRateStates.map((state) => {
      if (state.selected) {
        return { ...state, durationInMinutes: newDuration };
      }
      return { ...state };
    });
    setFlowRateStates(updatedStates);
  };

  const handleOnChangeFlowRateAction = (e) => {
    const updatedStates = flowRateStates.map((state) => {
      if (state.selected) {
        return { ...state, close: e.target.value };
      }
      return { ...state };
    });
    setFlowRateStates(updatedStates);
  };

  const handleSave = () => {
    setIsLoading(true);
    const { DeviceSerialNumber, SystemUniqueId } = fdsScheduleObject;
    const lowFlowRate = flowRateStates.find(
      (flowRate) => flowRate.id === t("flow_configuration.low"),
    );
    const mediumFlowRate = flowRateStates.find(
      (flowRate) => flowRate.id === t("flow_configuration.medium"),
    );
    const highFlowRate = flowRateStates.find(
      (flowRate) => flowRate.id === t("flow_configuration.high"),
    );

    const request = {
      UpdateFlowConfigurations: [
        {
          DeviceSerialNumber,
          LowDuration: ConvertDurationInMinutesToHours(
            lowFlowRate.durationInMinutes,
          ),
          LowDurationInMinutes: lowFlowRate.durationInMinutes,
          LowAction: lowFlowRate.close,
          MediumDuration: ConvertDurationInMinutesToHours(
            mediumFlowRate.durationInMinutes,
          ),
          MediumDurationInMinutes: mediumFlowRate.durationInMinutes,
          MediumAction: mediumFlowRate.close,
          HighDuration: ConvertDurationInMinutesToHours(
            highFlowRate.durationInMinutes,
          ),
          HighDurationInMinutes: highFlowRate.durationInMinutes,
          HighAction: highFlowRate.close,
        },
      ],
      SelectedSystemUniqueIds: [SystemUniqueId],
    };

    updateFlowConfiguration(request, {
      onSuccess: () => {
        setIsLoading(false);
        notification.success({
          message: (
            <span className="uppercase tracking-widest">
              {t("flow_configuration.update_successfull")}
            </span>
          ),
          description: `Serial Number ${request.UpdateFlowConfigurations[0].DeviceSerialNumber}`,
          duration: 5,
          placement: "topRight",
        });
        refetchFdsSchedulesList();
      },
      onError: (error) => {
        setIsLoading(false);
        notification.error({
          message: (
            <span className="uppercase tracking-widest">
              {t("flow_configuration.somethimg_went_wrong")}
            </span>
          ),
          description:
            error.response &&
            error.response.status !== 500 &&
            error.response.data
              ? error.response.data
              : undefined,
          duration: 0,
          placement: "topRight",
          icon: <FrownOutlined className="text-triple-red" />,
        });
      },
    });
  };

  return (
    <div className="edit-fds-schedule">
      <Popover
        trigger="click"
        placement="left"
        destroyTooltipOnHide
        overlayInnerStyle={{
          backgroundColor: "#5f616e",
        }}
        content={
          <EditFdsScheduleContent
            flowRateStates={flowRateStates}
            flowRateActions={flowRateActions}
            handleOnChangeFlowRateAction={handleOnChangeFlowRateAction}
            handleOnChangeTimePicker={handleOnChangeTimePicker}
            isLoading={isLoading}
            handleOnChange={handleOnChange}
            handleClose={handleClose}
            handleSave={handleSave}
          />
        }
        open={open}
        onOpenChange={handleOpenChange}
      >
        <Button size="small" type="primary">
          <EditOutlined />
        </Button>
      </Popover>
    </div>
  );
}

EditFdsSchedule.propTypes = {
  fdsScheduleObject: PropTypes.object,
  refetchFdsSchedulesList: PropTypes.func,
};

EditFdsSchedule.defaultProps = {
  fdsScheduleObject: {},
  refetchFdsSchedulesList: () => {},
};

export default EditFdsSchedule;
