import { useState } from "react";

import { t } from "i18next";
import PropTypes from "prop-types";

import useDevicesByPropertyId from "../../api/hooks/useDevicesByPropertyId";
import SearchInput from "../SearchInput/SearchInput";
import SystemDevicesTable from "../Systems/SystemDevicesTable/SystemDevicesTable";
import CustomModal from "./CustomModal";

const propTypes = {
  devicesCount: PropTypes.number,
  propertyId: PropTypes.number,
};
const defaultProps = {
  devicesCount: 0,
  propertyId: null,
};

function DevicesModal({ devicesCount, propertyId }) {
  const [enabled, setEnabled] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState("");

  const [currentPage, setcurrentPage] = useState(1);
  const pageSize = 7;

  const {
    data: devices,
    status,
    remove: removeDevicesQuery,
  } = useDevicesByPropertyId(
    {
      propertyId,
      pageSize,
      startIndex: (currentPage - 1) * pageSize,
      search: debouncedSearchQuery,
    },
    {
      cacheTime: 0,
      enabled,
    },
  );

  return (
    <CustomModal
      title={
        <div className="flex items-center justify-between pr-8 pl-2 mb-8">
          <h3 className="uppercase tracking-widest font-light">
            {t("schedule_calendar.devices")}
          </h3>

          <SearchInput
            className="ml-auto w-full sm:w-fit"
            placeholder={t("schedule_calendar.search_devices")}
            disabled={
              (!searchQuery && devices?.length === 0) || status === "loading"
            }
            value={searchQuery}
            onChange={(e) => {
              if (e.target.value === "") {
                setDebouncedSearchQuery("");
              }
              setcurrentPage(1);
              setSearchQuery(e.target.value);
            }}
            onSearch={() => {
              setDebouncedSearchQuery(searchQuery);
            }}
          />
        </div>
      }
      triggerText={`${devicesCount} ${t("schedule_calendar.devices")}`}
      triggerType="text"
      triggerSize="small"
      triggerClassName="text-inherit font-thin text-10"
      destroyOnClose
      width="70%"
      onOpen={() => setEnabled(true)}
      onClose={() => {
        setEnabled(false);
        removeDevicesQuery();
        setcurrentPage(1);
      }}
    >
      <div className="p-4">
        <SystemDevicesTable
          data={devices}
          status={status}
          currentPage={currentPage}
          pageSize={pageSize}
          totalCount={devices?.TotalCount}
          onChange={(pagination) => {
            setcurrentPage(pagination?.current);
          }}
        />
      </div>
    </CustomModal>
  );
}

DevicesModal.propTypes = propTypes;
DevicesModal.defaultProps = defaultProps;

export default DevicesModal;
