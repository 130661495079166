import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Form, Input, Space, Tooltip } from "antd";
import PropTypes from "prop-types";

function FormUserZonesDisplay({ formFieldName, userForm, onClickDeleteZone }) {
  return (
    <Form.List name={formFieldName}>
      {(fields) => (
        <>
          {fields.map(({ key, name }) => {
            const userZone = userForm.getFieldValue("userZones")[key];
            return (
              <Space key={key} align="baseline" size={0}>
                <div className="border-triple-blue border border-solid rounded-md flex px-1 mb-1">
                  <Form.Item
                    className="mb-0"
                    name={[name, "propertyName"]}
                    style={{ maxWidth: "100%" }}
                  >
                    <Input
                      placeholder="Property"
                      className="!bg-triple-blue rounded-r-none !cursor-pointer"
                      size="small"
                      disabled
                    />
                  </Form.Item>
                  <Form.Item
                    className="mb-0"
                    name={[name, "userFullname"]}
                    style={{ maxWidth: 125 }}
                  >
                    <Input
                      placeholder="Full Name"
                      size="small"
                      className="!cursor-pointer"
                      disabled
                      suffix={
                        <Tooltip title={userZone.userFullname} color="#5a5a5a">
                          <InfoCircleOutlined />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    className="mb-0 "
                    name={[name, "phoneNumber"]}
                    style={{ maxWidth: 100 }}
                  >
                    <Input
                      placeholder="PhoneNumber"
                      size="small"
                      className="!cursor-pointer"
                      disabled
                      suffix={
                        <Tooltip title={userZone.phoneNumber} color="#5a5a5a">
                          <InfoCircleOutlined />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    className="mb-0 ml-1 "
                    name={[name, "zone"]}
                    label="Zone"
                    colon={false}
                    style={{ maxWidth: "100%" }}
                  >
                    <Input
                      placeholder="Zone"
                      className="!bg-triple-blue rounded-l-none !cursor-pointer"
                      size="small"
                      disabled
                    />
                  </Form.Item>
                </div>
                <DeleteOutlined
                  className="mt-2 ml-2"
                  data-zoneid={userZone?.zoneId}
                  data-zonename={userZone?.zone}
                  style={{
                    fontSize: "18px",
                    color: "#01a2d1",
                  }}
                  onClick={(event) => onClickDeleteZone(event, key)}
                />
              </Space>
            );
          })}
        </>
      )}
    </Form.List>
  );
}

FormUserZonesDisplay.propTypes = {
  formFieldName: PropTypes.string,
  userForm: PropTypes.object,
  onClickDeleteZone: PropTypes.func,
};
FormUserZonesDisplay.defaultProps = {
  formFieldName: "userZones",
  userForm: {},
  onClickDeleteZone: () => {},
};

export default FormUserZonesDisplay;
