import { LinkOutlined } from "@ant-design/icons";
import { Input, Typography } from "antd";
import FormItemLabel from "antd/es/form/FormItemLabel";
import { t } from "i18next";

import useLoggedUser from "../../../../api/hooks/customHooks/useGetLoggedUser";
import useGetServiceProvider from "../../../../api/hooks/customHooks/useGetServiceProvider";

function PropertyServiceProvider() {
  const { user } = useLoggedUser();
  const { serviceProvider } = useGetServiceProvider(user?.Claims[0].Value);
  return (
    <>
      <FormItemLabel label={t("property_service_provider.contact_name")} />
      <Input
        size="middle"
        className="mb-4"
        maxLength={64}
        disabled
        value={serviceProvider?.Name}
      />
      <div className="flex justify-between">
        <div className="w-full mr-1">
          <FormItemLabel label={t("property_service_provider.contact_phone")} />
          <Input
            size="middle"
            className="mb-4"
            maxLength={64}
            disabled
            value={serviceProvider?.SupportPhone}
          />
        </div>
        <div className="w-full ml-1">
          <FormItemLabel label={t("property_service_provider.contact_email")} />
          <Input
            size="middle"
            className="mb-4"
            maxLength={64}
            disabled
            value={serviceProvider?.SupportEmail}
          />
        </div>
      </div>
      <FormItemLabel label={t("property_service_provider.contact_site")} />
      <Input
        size="middle"
        className="mb-4"
        maxLength={64}
        disabled
        value={serviceProvider?.SupportSite}
        addonAfter={
          <Typography.Link
            href={serviceProvider?.SupportSite}
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkOutlined />
          </Typography.Link>
        }
      />
    </>
  );
}

export default PropertyServiceProvider;
