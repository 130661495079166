import { useState } from "react";

import { Button, Modal } from "antd";
import { t } from "i18next";
import PropTypes from "prop-types";

const propTypes = {
  children: PropTypes.any,
  destroyOnClose: PropTypes.bool,
  triggerClassName: PropTypes.string,
  footer: PropTypes.any,
  triggerText: PropTypes.string,
  triggerType: PropTypes.string,
  triggerSize: PropTypes.string,
  title: PropTypes.any,
  width: PropTypes.string,
  withActions: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  onOpen: PropTypes.func,
};

const defaultProps = {
  children: null,
  destroyOnClose: false,
  triggerClassName: "",
  footer: null,
  triggerText: "Open Modal",
  triggerType: "primary",
  triggerSize: "middle",
  title: "Modal Title",
  width: "750px",
  withActions: false,
  onClose: () => {},
  onSubmit: () => {},
  onOpen: () => {},
};

function CustomModal({
  children,
  destroyOnClose,
  triggerClassName,
  footer,
  triggerText,
  triggerType,
  triggerSize,
  title,
  width,
  withActions,
  onClose,
  onSubmit,
  onOpen,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
    onOpen();
  };

  const handleOk = () => {
    setIsModalOpen(false);
    onSubmit();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    onClose();
  };

  return (
    <>
      <Button
        type={triggerType}
        size={triggerSize}
        className={triggerClassName}
        onClick={showModal}
      >
        {triggerText}
      </Button>
      <Modal
        style={{ maxHeight: "50vh" }}
        destroyOnClose={destroyOnClose}
        width={width}
        title={title}
        open={isModalOpen}
        footer={footer}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {children}
        {withActions && (
          <div className="flex w-full justify-end mt-10">
            <Button
              type="text"
              size="large"
              className="mr-2"
              onClick={handleCancel}
            >
              {t("back_button.cancel")}
            </Button>
            <Button type="primary" size="large" onClick={handleOk}>
              {t("edit_property.save")}
            </Button>
          </div>
        )}
      </Modal>
    </>
  );
}

CustomModal.propTypes = propTypes;
CustomModal.defaultProps = defaultProps;

export default CustomModal;
