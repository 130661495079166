import { Form, Input, Typography } from "antd";
import PropTypes from "prop-types";

const { Text } = Typography;

function FormUserDetails({ operation }) {
  return (
    <div className="user-details">
      <div className="flex justify-center">
        <Text className="uppercase tracking-widest">User Details</Text>
      </div>
      <div className="border border-solid rounded p-1 mt-2 border-triple-blue">
        <Form.Item className="mb-0">
          <Form.Item
            className="mb-0 inline-block w-1/2"
            label="Full Name"
            colon={false}
            name="fullName"
            rules={[
              { required: true, message: "Full Name field is required." },
            ]}
          >
            <Input size="small" maxLength={100} />
          </Form.Item>
          <Form.Item
            className="my-0 mx-2 inline-block w-full"
            label="Mobile"
            colon={false}
            name="phoneNumber"
            rules={[
              {
                required: true,
                message: "Phone Number field is required.",
              },
            ]}
            style={{
              width: "calc(50% - 16px)",
            }}
          >
            <Input size="small" maxLength={15} />
          </Form.Item>
        </Form.Item>
      </div>

      <div className="border border-solid rounded p-1 mt-2 border-triple-blue">
        <Form.Item
          className="m-0"
          label="Email"
          colon={false}
          name="email"
          rules={[
            { required: true, message: "Email field is required." },
            { type: "email", message: "Invalid email address." },
          ]}
          style={{
            width: "calc(100% - 8px)",
          }}
        >
          <Input
            size="small"
            maxLength={100}
            disabled={!!(operation && operation === "edit")}
          />
        </Form.Item>
      </div>
    </div>
  );
}
FormUserDetails.propTypes = {
  operation: PropTypes.string,
};

FormUserDetails.defaultProps = {
  operation: "",
};
export default FormUserDetails;
