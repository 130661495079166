import { useEffect, useRef, useState } from "react";

import { LockOutlined, PlusOutlined, UnlockOutlined } from "@ant-design/icons";
import { Button, Form, Input, Radio, Select, Switch, Tooltip } from "antd";
import { t } from "i18next";
import PropTypes from "prop-types";

import { usePropertyOverviewMetadata } from "../../../api/hooks/usePropertyOverview";
import usePurchaseOptions from "../../../api/hooks/usePurchaseOptions";
import { useCreateCmsOperator } from "../../../api/hooks/useUsersManagement";
import useUsersManagementGetDedicatedLicenses from "../../../api/hooks/useUsersManagementGetDedicatedLicenses";
import { groupBy } from "../../../helpers/jsLinq";
import useHandleCreateUser from "./hooks/useHandleCreateUser";
import useHandleFieldsChange from "./hooks/useHandleFieldsChange";
import UsersPermissionsList from "./UsersPermissionsList/UsersPermissionsList";
import "./scss/CreateUser.scss";

function CreateUser({
  setIsFormDirty,
  setFormReference,
  setIsCreateUserModalOpen,
  refetchUsersForManagement,
  refetchUsersLicensesCount,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [disableSaveChanges, setDisableSaveChanges] = useState(true);
  const [licensesData, setLicensesData] = useState();
  const [licenseValue, setLicenseValue] = useState();
  const [selectedProperties, setProperties] = useState([]);
  const [accessToAll, setAccessToAll] = useState(false);
  const [createUserForm] = Form.useForm();
  const initialFormValues = useRef({});
  initialFormValues.current = {
    Email: "",
    FirstName: "",
    LastName: "",
    PhoneNumber: "",
    LicenseValue: "",
    PurchaseKey: "",
    AccessToAll: false,
    PropertyIds: selectedProperties,
  };

  const options = [];
  const { data: properties } = usePropertyOverviewMetadata();
  if (properties) {
    properties.forEach((item) => {
      options.push({
        label: item.Name,
        value: item.Id,
      });
    });
  }

  const { data: dedicatedLicenses } = useUsersManagementGetDedicatedLicenses({
    enabled: true,
    refetchOnWindowsFocus: true,
    cacheTime: 0,
  });

  const purchaseOptions = [
    {
      value: "",
      label: "",
    },
  ];

  // Hooks
  const { data: purchasesResult } = usePurchaseOptions();
  // Configure the purchase plan select dropdown with values
  if (purchasesResult) {
    purchasesResult.forEach((p) =>
      purchaseOptions.push({
        value: p.UniqueId,
        label: `${p.LicensePlan.Name}: ${p.UniqueId}`,
      }),
    );
  }

  const createCmsOperatorMutationFn = useCreateCmsOperator();

  useEffect(() => {
    setFormReference(createUserForm);
  }, [createUserForm, setFormReference]);

  useEffect(() => {
    if (dedicatedLicenses) {
      const groupedData = groupBy(dedicatedLicenses.Result, "UserLevelName");
      // Removes Admin from the grouped result data.
      const { Admin, ...newGroupedData } = groupedData;
      setLicensesData(newGroupedData);
    }
  }, [dedicatedLicenses]);

  const HandleFieldsChange = (changedFields) => {
    useHandleFieldsChange(
      changedFields,
      createUserForm,
      initialFormValues,
      setDisableSaveChanges,
      setIsFormDirty,
    );
  };

  const handleOnValuesChange = (changedValues) => {
    const isFormDirty = Object.keys(changedValues).length > 0;
    setIsFormDirty(isFormDirty);
  };

  const HandleSubmit = async (formValues) => {
    setIsLoading(true);
    await useHandleCreateUser(
      formValues,
      createUserForm,
      setIsLoading,
      setIsFormDirty,
      setIsCreateUserModalOpen,
      refetchUsersForManagement,
      refetchUsersLicensesCount,
      createCmsOperatorMutationFn,
    );
  };

  // TODO kpeshterski: move this duplicate code to be used across CreateUser.jsx & EditUser.jsx
  const omittedValuesTooltip = (omittedValues) => (
    <Tooltip
      styles={{
        root: {
          pointerEvents: "none",
        },
      }}
      color="#5a5a5a"
      title={omittedValues.map(({ label }) => label).join(", ")}
    >
      + {omittedValues.length} ...
    </Tooltip>
  );

  return (
    <div className="content-wrapper overflow-y-auto create-user-form mr-3">
      <Form
        form={createUserForm}
        name="create-user-form"
        layout="horizontal"
        className="w-full"
        onFinish={HandleSubmit}
        onFieldsChange={HandleFieldsChange}
        onValuesChange={handleOnValuesChange}
        initialValues={initialFormValues.current}
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 17 }}
        labelAlign="left"
      >
        <div className="create-new-user-title">
          <span className="text-center mr-1 uppercase tracking-wider">
            {t("users.create_new_user")}
          </span>
          <PlusOutlined className="text-lg" />
        </div>
        <div className="border border-solid rounded p-1 mt-6 border-triple-grey">
          <Form.Item
            className="mb-0"
            label={t("users.full_name")}
            name="FullName"
            rules={[{ required: true, message: t("users.full_name_required") }]}
          >
            <Input size="small" maxLength={100} />
          </Form.Item>
        </div>
        <div className="border border-solid rounded p-1 mt-4 border-triple-grey">
          <Form.Item
            className="mb-0"
            label={t("users.mobile")}
            name="PhoneNumber"
            rules={[
              {
                required: true,
                message: t("users.phone_name_required"),
              },
            ]}
          >
            <Input size="small" maxLength={15} />
          </Form.Item>
        </div>
        <div className="border border-solid rounded p-1 mt-4 border-triple-grey">
          <Form.Item
            className="mb-0"
            label={t("users.email")}
            name="Email"
            rules={[
              { required: true, message: t("users.email_required") },
              { type: "email", message: t("users.invalid_email") },
            ]}
          >
            <Input size="small" maxLength={100} />
          </Form.Item>
        </div>
        <div className="border border-solid rounded p-1 mt-4 border-triple-grey">
          <Form.Item
            className="mb-0"
            name="PropertyIds"
            label={t("users.property")}
            layout="inline"
            hidden={accessToAll}
          >
            <Select
              mode="multiple"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              className="w-full"
              size="small"
              value={selectedProperties}
              onChange={setProperties}
              maxTagCount="responsive"
              options={options}
              maxTagPlaceholder={(omittedValues) =>
                omittedValuesTooltip(omittedValues)
              }
            />
          </Form.Item>
          <Form.Item
            className="mb-0"
            label={t("users.access")}
            name="AccessToAll"
          >
            <Switch
              size="small"
              checked={accessToAll}
              onChange={(checked) => {
                setAccessToAll(checked);
              }}
            />
          </Form.Item>
        </div>
        <div className="border border-solid rounded p-1 mt-4 border-triple-grey">
          <Form.Item
            className="mb-0"
            label={t("users.purchase_plan")}
            name="PurchaseKey"
            rules={[
              { required: true, message: t("users.purchase_plan_required") },
            ]}
          >
            <Select
              className="w-full"
              placeholder=""
              size="small"
              options={purchaseOptions}
            />
          </Form.Item>
        </div>
        <div className="border border-solid border-triple-blue rounded p-1 mt-4">
          <Form.Item
            className="mb-0"
            label={t("users.license")}
            name="LicenseValue"
            rules={[
              {
                required: true,
                message: t("users.license_required"),
              },
            ]}
          >
            <Radio.Group
              buttonStyle="solid"
              className="flex w-full cursor-pointer"
              size="small"
              onChange={(event) => {
                setLicenseValue({
                  value: event?.target?.value,
                  checked: event?.target?.checked,
                });
              }}
            >
              <Radio.Button
                value="Operator"
                className="text-center flex-1 mx-1 rounded license-radio-btn"
                disabled={
                  licensesData?.Operator?.length === 0 ||
                  licensesData?.Operator === undefined
                }
              >
                <span className="font-light text-triple-white uppercase tracking-widest">
                  {t("users.operator")}
                </span>
              </Radio.Button>
              {licenseValue?.value === "Operator" ? (
                <UnlockOutlined style={{ fontSize: 20, color: "#06A5D3" }} />
              ) : (
                <LockOutlined style={{ fontSize: 20 }} />
              )}
              <Radio.Button
                value="User"
                className="text-center flex-1 mx-1 rounded license-radio-btn"
                disabled={
                  licensesData?.User?.length === 0 ||
                  licensesData?.User === undefined
                }
              >
                <span className="font-light text-triple-white uppercase tracking-widest">
                  {t("users.user")}
                </span>
              </Radio.Button>
              {licenseValue?.value === "User" ? (
                <UnlockOutlined style={{ fontSize: 20, color: "#06A5D3" }} />
              ) : (
                <LockOutlined style={{ fontSize: 20 }} />
              )}
            </Radio.Group>
          </Form.Item>
        </div>

        <UsersPermissionsList
          formRef={createUserForm}
          licensesData={licensesData}
        />

        <Button
          type="primary"
          htmlType="submit"
          size="medium"
          className="custom-create-user-save-btn"
          disabled={disableSaveChanges}
          loading={isLoading}
        >
          Save
        </Button>
      </Form>
    </div>
  );
}

CreateUser.propTypes = {
  setIsFormDirty: PropTypes.func,
  setFormReference: PropTypes.func,
  setIsCreateUserModalOpen: PropTypes.func,
  refetchUsersForManagement: PropTypes.func,
  refetchUsersLicensesCount: PropTypes.func,
};

CreateUser.defaultProps = {
  setIsFormDirty: () => {},
  setFormReference: () => {},
  setIsCreateUserModalOpen: () => {},
  refetchUsersForManagement: () => {},
  refetchUsersLicensesCount: () => {},
};

export default CreateUser;
