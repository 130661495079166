/* eslint-disable react/prop-types */
import { useEffect } from "react";

import { InfoCircleOutlined } from "@ant-design/icons";
import { Tree } from "antd";
import { t } from "i18next";

import { HubIcon } from "../../../assets/icons/systems";
import useScheduleSystems from "../../../hooks/useScheduleSystems";
import EventIcon from "../../Events/EventIcon";

function DevicesTree({ className = "" }) {
  const { scheduleSystems, setScheduleSystems } = useScheduleSystems();

  const formattedData =
    scheduleSystems.selectedSystemsWithSelectedDevice?.length > 0 &&
    [...scheduleSystems.selectedSystemsWithSelectedDevice].map(
      (system, indexSystem) => {
        return {
          title: (
            <span className="flex items-center">
              <HubIcon className="mx-2" />
              {system.Name}
            </span>
          ),
          key: `0-${indexSystem}`,
          children: system?.Devices.filter((device) => {
            return (
              device?.DeviceTypePublicName !== "Remote Water Meter Reader" &&
              device?.DeviceTypePublicName !== "Repeater"
            );
          }).map((device, indexDevice) => {
            return {
              title: (
                <span
                  className="flex items-center"
                  data-deviceid={device.UniqueId}
                  data-systemname={system.Name}
                >
                  <EventIcon
                    key={device?.SerialNumber}
                    iconName={device?.DeviceTypePublicName}
                    className="mx-2"
                    defaultIcon={
                      <InfoCircleOutlined className="text-white mx-2" />
                    }
                  />
                  {device?.DeviceTypePublicName} ({device?.SerialNumber})
                </span>
              ),
              key: `0-${indexSystem}-${indexDevice}`,
            };
          }),
        };
      },
    );

  useEffect(() => {}, [scheduleSystems?.checkedDevicesKeys]);

  return (
    <div className={`schedule-tree ${className}`}>
      <h3 className="text-triple-white uppercase tracking-widest font-light mb-4">
        {t("schedule_calendar.devices")}
      </h3>

      {/* {loading && "Loading..."} */}

      {formattedData.length > 0 && (
        <Tree
          className="bg-[#303343]"
          checkable
          defaultExpandAll
          treeData={formattedData}
          checkedKeys={scheduleSystems?.checkedDevicesKeys}
          onCheck={(keys, e) => {
            const { checkedNodes } = e;
            const selectedDevicesIds = checkedNodes
              .map((node) => {
                return node?.title?.props["data-deviceid"];
              })
              .filter((el) => el !== undefined);
            const selectedSystemsNames = checkedNodes
              .map((node) => {
                return node?.title?.props["data-systemname"];
              })
              .filter((el) => el !== undefined);
            const uniqueSelectedSystemsNames = Array.from(
              new Set(selectedSystemsNames),
            );
            setScheduleSystems((prevState) => ({
              ...prevState,
              selectedDevicesUniqueIds: selectedDevicesIds,
              confirmedSystemsWithSelectedDevice: uniqueSelectedSystemsNames,
              checkedDevicesKeys: keys,
            }));
          }}
        />
      )}

      {scheduleSystems.selectedSystemsWithSelectedDevice.length === 0 && (
        <div className="flex h-full items-center justify-center text-triple-white font-light text-sm">
          <InfoCircleOutlined className="mr-2" />
          {scheduleSystems.systemsWithSelectedDevice?.length > 0
            ? t("manage_property.choose_system")
            : t("manage_property.choose_property")}
        </div>
      )}
    </div>
  );
}

export default DevicesTree;
