function MonitoringIcon() {
  return (
    <svg
      width="24"
      height="48"
      viewBox="15 5 70 80"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="blue-fill-color purple-stroke-color"
        fillRule="evenodd"
        fill="#E1E0E3"
        d="M45.4269 31.8326H37.7948C36.844 31.8326 36.0725 32.6022 36.0725 33.5504V41.3228C36.0725 42.271 36.844 43.0405 37.7948 43.0405H45.4269C46.3776 43.0405 47.1491 42.271 47.1491 41.3228V33.5504C47.1491 32.6022 46.3776 31.8326 45.4269 31.8326ZM60.6553 31.8326H53.0232C52.0725 31.8326 51.301 32.6022 51.301 33.5504V41.3228C51.301 42.271 52.0725 43.0405 53.0232 43.0405H60.6553C61.6061 43.0405 62.3776 42.271 62.3776 41.3228V33.5504C62.3776 32.6022 61.6061 31.8326 60.6553 31.8326ZM45.4269 47.3378H37.7948C36.844 47.3378 36.0725 48.1073 36.0725 49.0556V56.8298C36.0725 57.7781 36.844 58.5476 37.7948 58.5476H45.4269C46.3776 58.5476 47.1491 57.7781 47.1491 56.8298V49.0556C47.1491 48.1073 46.3776 47.3378 45.4269 47.3378ZM60.6553 47.3378H53.0232C52.0725 47.3378 51.301 48.1073 51.301 49.0556V56.8298C51.301 57.7781 52.0725 58.5476 53.0232 58.5476H60.6553C61.6061 58.5476 62.3776 57.7781 62.3776 56.8298V49.0556C62.3776 48.1073 61.6061 47.3378 60.6553 47.3378ZM76.8496 23.2268H21.6043C18.8012 23.2268 16.5074 25.5071 16.5074 28.2974V61.7141C16.5074 64.5119 18.788 66.7809 21.6005 66.7809H76.8458C79.6489 66.7809 81.9427 64.4987 81.9427 61.7103V28.2936C81.9427 25.4959 79.6621 23.2287 76.8496 23.2287V23.2268ZM78.2927 61.4244C78.2927 62.3745 77.5193 63.144 76.5629 63.144H21.8853C20.9309 63.144 20.1556 62.3745 20.1556 61.4244V28.5815C20.1556 27.6313 20.929 26.8618 21.8853 26.8618H76.5629C77.5174 26.8618 78.2927 27.6332 78.2927 28.5815V61.4244ZM65.4033 70.7828H33.045C32.0376 70.7828 31.2209 71.5956 31.2209 72.6003C31.2209 73.605 32.0376 74.4178 33.045 74.4178H65.4033C66.4106 74.4178 67.2274 73.605 67.2274 72.6003C67.2274 71.5956 66.4106 70.7828 65.4033 70.7828Z"
      />
    </svg>
  );
}

export default MonitoringIcon;
