import {
  CloseOutlined,
  ArrowLeftOutlined,
  FrownOutlined,
} from "@ant-design/icons";
import { Drawer, Descriptions, Alert, Skeleton } from "antd";
import { t } from "i18next";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import useEvent from "../../../api/hooks/useEvent";
import buildEventTitle from "../../../helpers/buildEventTitle";
import useWindowSize from "../../../hooks/useWindowSize";
import ExportCSV from "../../ExportCSV/ExportCSV";
import EventIcon from "../EventIcon";

function EventDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const window = useWindowSize();
  const { eventId } = useParams();

  const { data: event, status } = useEvent(eventId, {
    cacheTime: 0,
  });

  return (
    <Drawer
      key="eventDetailsDrawer"
      placement="right"
      open={eventId}
      width={window?.width > 576 ? "420px" : "100%"}
      headerStyle={{ borderBottom: "none" }}
      onClose={() =>
        navigate({
          pathname: "..",
          search: location.search,
        })
      }
      closeIcon={
        window?.width <= 576 ? (
          <ArrowLeftOutlined className="drawer-details-close-button" />
        ) : (
          <CloseOutlined className="drawer-details-close-button" />
        )
      }
    >
      <div className="drawer-details">
        {status === "loading" && (
          <Skeleton
            active
            paragraph={{
              rows: 10,
            }}
          />
        )}
        {status === "error" && (
          <Alert
            message={t("problems.bad_news")}
            description={t("problems.broke_something_event")}
            type="error"
            showIcon
            icon={<FrownOutlined />}
          />
        )}
        {status === "success" && (
          <>
            <h1 className="drawer-details-title">
              {t("event_details.event_details_title")}
            </h1>
            <div className="drawer-details-subtitle">
              {buildEventTitle(event?.EventTitle)}
            </div>
            <Descriptions
              bordered={false}
              className="text-16"
              colon={false}
              column={1}
              labelStyle={{
                background: "#2F3342",
                width: "50%",
              }}
            >
              <Descriptions.Item
                label={t("property_overview_page.status")}
                className="drawer-details-description-row"
              >
                <div className="drawer-details-description-item">
                  {event?.LogsInfo.State && (
                    <EventIcon
                      iconName={event?.LogsInfo.DeviceTypePublicName}
                      className={`${event?.LogsInfo.State.toLowerCase()}-event mr-1`}
                    />
                  )}
                  <span className="ml-1">
                    {t(`state.${event?.LogsInfo?.State}`)}
                  </span>
                </div>
              </Descriptions.Item>
              <Descriptions.Item
                label={t("property_overview_page.record")}
                className="drawer-details-description-row"
              >
                <span className="drawer-details-description-item">
                  {event?.LogsInfo?.RecordType
                    ? t(`record_type.${event?.LogsInfo?.RecordType}`)
                    : "-"}
                </span>
              </Descriptions.Item>
              <Descriptions.Item
                label={t("reports_layout.type")}
                className="drawer-details-description-row"
              >
                <span className="drawer-details-description-item">
                  {event?.LogsInfo?.DeviceTypePublicName
                    ? t(
                        `device_type_public_name.${event?.LogsInfo?.DeviceTypePublicName}`,
                      )
                    : "-"}
                </span>
              </Descriptions.Item>
              <Descriptions.Item
                label={t("reports_layout.owner")}
                className="drawer-details-description-row"
              >
                <span className="drawer-details-description-item">
                  {event?.LogsInfo.Owner || "-"}
                </span>
              </Descriptions.Item>
              <Descriptions.Item
                label={t("property_overview_page.system")}
                className="drawer-details-description-row"
              >
                <span className="drawer-details-description-item">
                  {event?.LogsInfo.SystemName || "-"}
                </span>
              </Descriptions.Item>
              <Descriptions.Item
                label={t("events_filter.system_id")}
                className="drawer-details-description-row"
              >
                <span className="drawer-details-description-item">
                  {event?.LogsInfo.SystemId}
                </span>
              </Descriptions.Item>
              <Descriptions.Item
                label={t("events_filter.date")}
                className="drawer-details-description-row"
              >
                <span className="drawer-details-description-item">
                  {new Date(event?.LogsInfo.DateTime).toLocaleDateString()}
                </span>
              </Descriptions.Item>
              <Descriptions.Item
                label={t("property_overview_page.time")}
                className="drawer-details-description-row"
              >
                <span className="drawer-details-description-item">
                  {new Date(event?.LogsInfo.DateTime).toLocaleTimeString()}
                </span>
              </Descriptions.Item>
              <Descriptions.Item
                label={t("floor_system_tables.location")}
                className="drawer-details-description-row"
              >
                <span className="drawer-details-description-item">
                  {event?.LogsInfo.Location || "-"}
                </span>
              </Descriptions.Item>
            </Descriptions>
          </>
        )}
        {event && (
          <ExportCSV
            // TODO: format data to get rid of the nested objects
            data={[event?.LogsInfo]}
            filename={t("event_details.event_details_title")}
            className="drawer-details-export-button"
          />
        )}
      </div>
    </Drawer>
  );
}

export default EventDetails;
