import { useQuery } from "@tanstack/react-query";

import useAxiosPrivate from "../../hooks/useAxiosPrivate";

function useDeviceByDeviceId(deviceId, options, key = "getDeviceByDeviceId") {
  const axiosPrivate = useAxiosPrivate();

  const getDeviceByDeviceId = async () => {
    const events = await axiosPrivate({
      method: "GET",
      url: `Device/${deviceId}`,
    }).then((res) => res.data);
    return events;
  };

  return useQuery([key, deviceId], () => getDeviceByDeviceId(deviceId), {
    ...options,
  });
}

export default useDeviceByDeviceId;
