import { useEffect, useState } from "react";

import { Calendar } from "antd";
import hebrew from "antd/locale/he_IL";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import "dayjs/locale/he";
import "./StatisticsCalendar.scss";
import DateConstants from "../../../constants/DateConstants";

dayjs.locale("he");

function StatisticsCalendar({ setDate, date }) {
  const { i18n } = useTranslation();
  const [calendarDate, setCalendarDate] = useState();
  const onPanelChange = (value) => {
    const daysInMonth = new Date(value.$y, value.$M + 1, 0).getDate();

    setDate({
      MM: DateConstants.months[value.$M],
      m: value.$M,
      year: value.$y,
      d: dayjs().$D >= daysInMonth ? daysInMonth : dayjs().$D,
    });
  };

  const onSelectChange = (value) => {
    setCalendarDate(value);
    setDate({
      MM: DateConstants.months[value.$M],
      m: value.$M,
      year: value.$y,
      d: value.$D,
    });
  };

  useEffect(() => {
    setCalendarDate(dayjs(new Date(date?.year, date?.m, date?.d)));
  }, [date]);

  return (
    <div className="statistics-calendar w-full">
      <Calendar
        locale={
          i18n.language === "Hebrew"
            ? {
                lang: {
                  locale: hebrew.locale,
                },
              }
            : undefined
        }
        fullscreen={false}
        mode="month"
        defaultValue={dayjs(new Date(date?.year, date?.m, date?.d))}
        value={calendarDate}
        onPanelChange={onPanelChange}
        onSelect={onSelectChange}
      />
    </div>
  );
}

StatisticsCalendar.defaultProps = {
  setDate: () => {},
  date: {},
};

StatisticsCalendar.propTypes = {
  setDate: PropTypes.func,
  date: PropTypes.object,
};

export default StatisticsCalendar;
