import { useEffect, useMemo, useState } from "react";

import { Button } from "antd";
import { t } from "i18next";
import PropTypes from "prop-types";

function EventTypeFilter({ totalStatusCount, onEventTypeChange }) {
  const [eventTypes, setEventTypes] = useState({
    Leak: true,
    Flow: false,
    Offline: false,
    Check: false,
  });

  const selectedEventTypes = useMemo(() => {
    return Object.keys(eventTypes).filter((key) => eventTypes[key]);
  }, [eventTypes]);

  useEffect(() => {
    onEventTypeChange(selectedEventTypes);
  }, [selectedEventTypes, onEventTypeChange]);

  const toggleEventType = (eventType) => {
    setEventTypes((previous) => ({
      ...previous,
      [eventType]: !previous[eventType],
    }));
  };

  const eventTypeButtons = [
    { key: "Leak", label: t("monitoring.event_type_buttons.leak") },
    { key: "Flow", label: t("monitoring.event_type_buttons.flow") },
    { key: "Offline", label: t("monitoring.event_type_buttons.offline") },
    { key: "Check", label: t("monitoring.event_type_buttons.check") },
  ];

  return (
    <div className="ml-1 event-type-filter flex">
      {eventTypeButtons.map(({ key, label }) => (
        <Button
          key={key}
          type={eventTypes[key] ? "primary" : "default"}
          className={`w-3/12 !rounded-3xl mx-1 event-type-${key}`}
          onClick={() => toggleEventType(key)}
        >
          {label} {totalStatusCount[key]}
        </Button>
      ))}
    </div>
  );
}
EventTypeFilter.propTypes = {
  totalStatusCount: PropTypes.object,
  onEventTypeChange: PropTypes.func,
};
EventTypeFilter.defaultProps = {
  totalStatusCount: {},
  onEventTypeChange: () => {},
};
export default EventTypeFilter;
